import React, {useEffect} from "react";
import OrderTabComponent from "./order-tab.component";
import OrderFilterComponent from "./order-filter.component";
import OrderListComponent from "./order-list.component";
import {getOrderListApi} from "../../api/order.api";
import {setOrderList, setOrderToolsInitialState} from "../../reducers/order.reducer";
import {useDispatch, useSelector} from "react-redux";
import {setRefreshAlert, setToolsInitialState, setToolsLoading, setToolsTotalData} from "../../reducers/tools.reducer";
import {formatDateToReadable, isUnauthorized} from '../../ui-utils/general-variable'
import ModalComponent from "../../components/modal.component";
import RefreshAlertModal from "../../components/refresh-alert-modal";

export const OrdersComponent = () => {
    const {activeTab} = useSelector(state => state.orderReducer)
    const {modalMessage, modalType} = useSelector(state => state.toolsReducer)
    const dispatch = useDispatch()
    const {
        currentPage,
        pageSize,
        searchKey,
        sort,
        orderBy,
        expedition,
        dateRange,
        reload
    } = useSelector(state => state.toolsReducer)

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setOrderToolsInitialState())
        }
    }, [])

    useEffect(() => {
        const params = {
            status: activeTab,
            search: searchKey,
            perPage: pageSize,
            orderBy: !!orderBy ? orderBy : 'id',
            orderType: sort,
            filterByExpedition: expedition?.key || null,
            startDate: dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            endDate: dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            page: currentPage,
        }
        dispatch(setToolsLoading(true))
        getOrderListApi(params)
            .then(response => {
                dispatch(setOrderList(response?.data?.result))
                dispatch(setToolsLoading(false))
                dispatch(setToolsTotalData(response?.data?.total_result))
            })
            .catch(err => {
                if(!!isUnauthorized(err?.code)){
                    dispatch(setRefreshAlert(true))
                } else if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                dispatch(setToolsLoading(false))
            })
    }, [currentPage, pageSize, searchKey, orderBy, sort, expedition, activeTab, dateRange, reload])

    return (
        <>
            <div className={"space-y-5 box-content-style py-5"}>
                <OrderTabComponent/>
                <OrderFilterComponent/>
                <OrderListComponent/>
            </div>
            <ModalComponent message={modalMessage} type={modalType}/>
            <RefreshAlertModal/>
        </>
    )
}
