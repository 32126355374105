import {exportRequest, getRequest} from "./config";

const getCustomerInfoListApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`customer-info/list`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const getCustomerInfoStatisticApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`customer-info/statistic`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const exportCustomerApi = (body) => {
    return new Promise((resolve, reject) => {
        exportRequest('customer-info/export',body)
            .then(response => resolve(response))
            .catch(err => reject(err.response?.data))
    })
}

export {
    getCustomerInfoListApi,
    getCustomerInfoStatisticApi,
    exportCustomerApi
}
