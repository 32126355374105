import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setToolsCurrentPage, setToolsPageSize} from "../reducers/tools.reducer";

const PageLimitComponent = () => {
    const dispatch = useDispatch()
    const {pageSize} = useSelector(state => state.toolsReducer)
    const [limitValue, setLimitValue] = useState(pageSize)

    const onKeyUpHandler = (event) => {
        if (event.key === 'Enter') {
            dispatch(setToolsPageSize(Number(event.target.value) || 10))
            dispatch(setToolsCurrentPage(1))
        }
    }

    const onChangeHandler = (event) => {
        setLimitValue(event.target.value)
    }

    useEffect(() => {
        setLimitValue(pageSize)
    }, [pageSize])

    return (
        <div className={"row items-center space-x-2 font-NunitoSans-SemiBold text-dark-grey 2xl:text-sm text-xs"}>
            <input className={"border rounded border-pinkish-grey shadow outline-none h-8 w-8 text-center border-pinkish-grey shadow hover:border-grey-green hover:bg-light-grey"}
                   value={limitValue}
                   onChange={onChangeHandler}
                   onKeyUp={onKeyUpHandler}/>
            <p>/ Page</p>
        </div>
    )
}

export default PageLimitComponent
