export const servicePerformanceModel = {
    percentage_card: {
        current_percentage: 0,
        past_percentage: 0,
        target_percentage: 0
    },
    average_card : {
        current_average: 0,
        past_average: 0,
        target_average: 0
    },
    point_card: {
        current_penalty: 0,
        past_penalty: 0
    },
    penalty_card: {
        current_penalty: 0,
        past_penalty: 0
    },
    chart: {
        title: '',
        data: []
    }
}
