import React, {useEffect} from "react";
import StatisticListComponent from "./statistic-list.component";
import {Link} from "react-router-dom";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {setRefreshAlert, setToolsStartDateEndDate} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {dateRangeToday, formatDateToReadable, isUnauthorized} from "../../ui-utils/general-variable";
import {getServicePerformanceListApi} from "../../api/service-performance.api";
import {
    setServicePerformanceLoading,
    setServicePerformanceStatisticList
} from "../../reducers/service-performance.reducer";
import RefreshAlertModal from "../../components/refresh-alert-modal";

export const ServicePerformanceComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)

    const getValueFromRangePickerSingle = (d) => {
        dispatch(setToolsStartDateEndDate(d))
    }

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            dispatch(setServicePerformanceLoading(true))
            let params = {
                startDate: formatDateToReadable(dateRange[0], 'YYYY-MM-DD'),
                endDate: formatDateToReadable(dateRange[1], 'YYYY-MM-DD'),
            }
            getServicePerformanceListApi(params)
                .then(response => {
                    let packingTime = {
                        ...response.packing_time,
                        name: 'Masa Pengemasan',
                        path: 'packing-time',
                        unit: 'Hari',
                        helpText: 'Rata-rata jumlah waktu yang dibutuhkan untuk mengemas pesanan'
                    }
                    let late_packing = {
                        ...response.incomplete_order,
                        name: 'Tingkat pesanan tidak terselesaikan',
                        path: 'uncomplete-orders',
                        unit: '%',
                        helpText: 'Jumlah pesanan secara akumulatif yang terlambat untuk diproses dalam satu bulan untuk dilakukan pemenuhan (Fulfillment) hingga batas waktu 2 hari (exclude) hari Sabtu, Minggu, hari libur Nasional dan disajikan dalam bentuk persentase. (dihitung setiap akhir bulan).'
                    }
                    let late_delivery = {
                        ...response.delayed_delivery,
                        name: 'Tingkat keterlambatan pengiriman pesanan',
                        path: 'delivery-delay',
                        unit: '%',
                        helpText: 'Jumlah pesanan secara akumulatif yang dikirim melebihi batas waktu yang telah ditentukan yaitu 2 hari (exclude) hari Sabtu, Minggu dan hari libur Nasional dalam kurun waktu satu bulan dan disajikan dalam bentuk persentase. (dihitung setiap akhir bulan)'
                    }
                    let statisticList = [late_packing, late_delivery, packingTime]
                    dispatch(setServicePerformanceStatisticList(statisticList))
                    dispatch(setServicePerformanceLoading(false))
                })
                .catch(err => {
                    if (!!isUnauthorized(err?.code)) {
                        dispatch(setRefreshAlert(true))
                    } else if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    dispatch(setServicePerformanceLoading(false))
                })
        }

    }, [dateRange])

    return (
        <>
            <div className={"space-y-5 box-content-style py-5"}>
                <div className={"p-5"}>
                    <div className={"row justify-center"}>
                        <DateRangePickerComponent onChange={getValueFromRangePickerSingle}
                                                  slug={'service-performance'}/>
                    </div>
                    <Link to={"setting-target"}>
                        <button
                            className={"border rounded text-sm text-dark-grey-three font-NunitoSans-Bold px-3 h-9 hover:border-dark-grey hover:bg-gray-100"}>Setting
                            Target
                        </button>
                    </Link>
                </div>
                <StatisticListComponent/>
            </div>
            <RefreshAlertModal/>
        </>
    )
}
