import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const NotificationOnHeader = ({ notificationButtonHandler }) => {
    const {notificationList} = useSelector(state => state.notificationReducer);

    return (
        <div className={"col items-center h-fit w-[23rem] bg-white pr-3 pl-4 py-4 font-NunitoSans-Bold rounded-md shadow"}>
            <p className={"text-sm text-green-sage"}>Notifikasi Dashboard</p>
            <div className={"w-full h-80 overflow-y-scroll scroll-notification my-2"}>
                {notificationList.map((list, index) => (
                    <div key={index} className={"mr-2.5"}>
                        <Link to={`/order/order-list/order-detail/${list.order_id}`} onClick={notificationButtonHandler}>
                            <div className={"w-full h-fit min-h-[6.9rem] bg-light-green mb-2 text-dark-grey rounded-lg border border-grey-green py-1 px-3 hover:bg-light-grey-five"}>
                                <p className={"text-11 text-end py-1"}>{list.date}</p>
                                <p className={"text-sm font-NunitoSans-SemiBold px-1 pb-2"}>{list.body}</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <Link to={"home/notification"} onClick={notificationButtonHandler}>
                <p className={"text-xs text-dark-green py-1"}>Lihat Semua</p>
            </Link>
        </div>
    )
}

export default NotificationOnHeader