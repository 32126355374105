import {createSlice} from '@reduxjs/toolkit'
import {servicePerformanceModel} from "../models/service-performance.model";

export const servicePerformanceSlice = createSlice({
    name: 'service-performance',
    initialState: {
        selectedRowKeys: [],
        statisticList: [],
        loading: false,
        incompleteOrderList: [],
        incompleteOrderStatistic: {...servicePerformanceModel},
        delayedDeliveryList: [],
        delayedDeliveryStatistic: {...servicePerformanceModel},
        packingTimeStatistic: {...servicePerformanceModel},
        orderList: [{
            orderId: 'AVS-16508',
            name: 'Lalu Hadyan Akbar',
            deliveryTimeMax: "17-10-2022 12:00",
            deliveryDate: "17-10-2022",
            created_at: '17-10-2022',
            daysLate: 10,
            cancelledReason: 'cancelled',
            paymentTime: "17-10-2022 12:00",
            grandTotal: 1000000
        }]
    },
    reducers: {
        setServicePerformanceSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload
        },
        setServicePerformanceOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setServicePerformanceStatisticList: (state, action) => {
            state.statisticList = action.payload
        },
        setServicePerformanceLoading: (state, action) => {
            state.loading = action.payload
        },
        setServicePerformanceIncompleteOrderList: (state, action) => {
            state.incompleteOrderList = action.payload
        },
        setServicePerformanceDelayedDeliveryList: (state, action) => {
            state.delayedDeliveryList = action.payload
        },
        setServicePerformanceDelayedDeliveryStatistic: (state, action) => {
            state.delayedDeliveryStatistic = action.payload
        },
        setServicePerformanceIncompleteOrderStatistic: (state, action) => {
            state.incompleteOrderStatistic = action.payload
        },
        setServicePerformancePackingTime: (state, action) => {
            state.packingTimeStatistic = action.payload
        },
        setServicePerformanceInitialState: (state, action) => {
            state.selectedRowKeys = []
        },
    },
})

export const {
    setServicePerformanceSelectedRowKeys,
    setServicePerformanceOrderList,
    setServicePerformanceStatisticList,
    setServicePerformanceLoading,
    setServicePerformanceIncompleteOrderList,
    setServicePerformanceDelayedDeliveryList,
    setServicePerformanceDelayedDeliveryStatistic,
    setServicePerformanceIncompleteOrderStatistic,
    setServicePerformanceInitialState,
    setServicePerformancePackingTime
} = servicePerformanceSlice.actions
const servicePerformanceReducer = servicePerformanceSlice.reducer

export default servicePerformanceReducer
