import React, {useState} from "react";
import ArrowDownSvg from "../images/svg/arrow-down-green.svg";

const TableExpandableComponent = (props) => {
    const {content} = props
    const [show, setShow] = useState(false)

    const onClickHandler = () => {
        setShow(!show)
    }

    return (
        <div className={"w-full"}>
            <div className={"row items-center space-x-1 font-NunitoSans-Bold justify-end mb-3 cursor-pointer"} onClick={onClickHandler}>
                <p className={"text-green-grey"}>Detail</p>
                <div className={show ? 'animate-rotateArrowUp' : 'animate-rotateArrowDown'}>
                    <img src={ArrowDownSvg} className={"h-4"}/>
                </div>
            </div>
            <div className={`${show ? 'block' : 'hidden'}`}>
                {content}
            </div>
        </div>
    )
}

export default TableExpandableComponent
