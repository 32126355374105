import React from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import ChartComponent from "../../components/chart.component";
import {useSelector} from "react-redux";

const DeliveryDelayChartComponent = () => {
    const {delayedDeliveryStatistic} = useSelector(state => state.servicePerformanceReducer)
    const chart = delayedDeliveryStatistic.chart

    return (
        <div className={"border rounded-md p-5"}>
            <div className={"row items-center justify-between mb-5"}>
                <div/>
                <p className={"font-Belleza-Regular text-lg"}>{chart.title}</p>
                <ToolTipComponent/>
            </div>
            <div className={"w-full h-96"}>
                <ChartComponent type={'line'} data={chart.data} tooltip={'Tingkat Keterlambatan'}/>
            </div>
        </div>
    )
}

export default DeliveryDelayChartComponent
