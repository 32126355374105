import React, {useEffect} from "react";
import TabComponent from "./tab.component";
import FilterComponent from "./filter.component";
import OrderListComponent from "./order-list.component";
import {formatDateToReadable, isUnauthorized} from "../../ui-utils/general-variable";
import {setRefreshAlert, setToolsInitialState, setToolsLoading, setToolsTotalData} from "../../reducers/tools.reducer";
import {getOrderListApi} from "../../api/order.api";
import {useDispatch, useSelector} from "react-redux";
import {setManifestInitialState, setManifestOrderList} from "../../reducers/manifest.reducer";
import PrintManifestModal from "./print-manifest.modal";
import ModalComponent from "../../components/modal.component";
import ScanBarcodeModal from "./scan-barcode.modal";
import RefreshAlertModal from "../../components/refresh-alert-modal";

export const ManifestComponent = () => {
    const dispatch = useDispatch();
    const {manifestReducer, toolsReducer} = useSelector(state => state)
    const {currentPage, pageSize, searchKey, sort, orderBy, expedition, dateRange, modalMessage, modalType} = toolsReducer
    const {activeTab, reload} = manifestReducer

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setManifestInitialState())
        }
    }, [])

    useEffect(() => {
        const params = {
            status: activeTab,
            search: searchKey,
            perPage: pageSize,
            orderBy: !!orderBy ? orderBy : 'id',
            orderType: sort,
            filterByExpedition: expedition?.key || null,
            startDate: dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            endDate: dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            page: currentPage,
        }
        dispatch(setToolsLoading(true))
        getOrderListApi(params)
            .then(response => {
                const result = response?.data?.result
                dispatch(setManifestOrderList(result))
                dispatch(setToolsLoading(false))
                dispatch(setToolsTotalData(response?.data?.total_result))
            })
            .catch(err => {
                if(!!isUnauthorized(err?.code)){
                    dispatch(setRefreshAlert(true))
                } else if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                dispatch(setToolsLoading(false))
            })
    }, [activeTab, dateRange, currentPage, searchKey, pageSize, orderBy, sort, expedition, reload])

    return (
        <>
            <div className={"space-y-5 box-content-style py-5"}>
                <TabComponent/>
                <FilterComponent/>
                <OrderListComponent/>
            </div>
            <PrintManifestModal />
            <ScanBarcodeModal />
            <ModalComponent message={modalMessage} type={modalType}/>
            <RefreshAlertModal/>
        </>
    )
}
