export const ON_HOLD = 'on-hold'
export const PENDING = 'pending-payment'
export const PROCESSING = 'processing'
export const PACKING = 'packing'
export const DELIVERY = 'on-delivery'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'

// export const orderListModel = [{
//     created_at: null,
//     customer_address: null,
//     customer_billing: null,
//     customer_email: null,
//     customer_name: null,
//     customer_phone: null,
//     id: null,
//     invoice_number: null,
//     notes: null,
//     payment_method: null,
//     print_status: {
//         label: false,
//         surat_jalan: false
//     },
//     processing_time: [],
//     product: {
//         data: [{
//             id: null,
//             image: null,
//             meta_data: [],
//             name: null,
//             price: null,
//             product_id: null,
//             quantity: null,
//             sku: null,
//             subtotal: null,
//             subtotal_tax: null,
//             tax_class: null,
//             taxes: {
//                 subtotal: {},
//                 total: {}
//             },
//             total: null,
//             total_tax: null,
//             variation_id: null
//         }],
//         total: null
//     },
//     status: null,
//     status_time: {},
//     total_price: null,
//     total_price_text: null,
//     updated_at: null
// }]

// export const orderDetailModel = {
//     id: null,
//     invoice_number: null,
//     customer_name: null,
//     payment_method: null,
//     notes_customer: null,
//     phone: null,
//     order_expire: null,
//     billing_address: null,
//     coupon: [],
//     created_at: null,
//     ekspedisi: {
//         destination: null,
//         image: null,
//         name: null,
//         origin: null,
//         price: null,
//     },
//     history_order: {
//         data: []
//     },
//     processing_time: {
//         status: null,
//         time: null
//     },
//     product: {
//         data: [],
//         discount: null,
//         ppn: null,
//         subtotal_price: null,
//         total_price: null
//     },
//     resi: null,
//     shipping_address: null,
//     shipping_label: {
//         date: null,
//         qty: null,
//         shipper_address: null,
//         shipper_name: null,
//         shipper_phone: null,
//     },
//     status: null,
//     status_time: {
//         cancelled: null,
//         completed: null,
//         expired: null,
//         expired_alt: null,
//         ['on-hold']: null,
//         packing: null,
//         pending: null,
//         processing: null,
//     },
//     total_price_text: null,
//     tracking: {
//         data: [],
//         msg: null,
//         status: null
//     },
//     updated_at: null
// }
