import React, {useRef, useState} from "react";
import {generatePublicTokenApi, loginApi} from "../../api/auth.api";
import {Link} from "react-router-dom";
import InputComponent from "./input.component";
import ModalComponent from "../../components/modal.component";
import {
    setToolsCaptcha,
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {checkFieldValidate, publicKey, googleRecaptchaSiteKey} from "../../ui-utils/general-variable";
import avoMO from "../../images/png-jpg/avo-mo.png";
import loginBubble from "../../images/svg/login-bubble.svg";
import LoadingSvg from "../../images/svg/loading-trans.svg";
import bcrypt from "bcryptjs-react";
import ReCAPTCHA from "react-google-recaptcha";
import {Verification} from "./verification.component";

export const AuthComponent = () => {
    const recaptchaRef = useRef()
    const [errorCaptcha, setErrorCaptcha] = useState('')
    const {modalMessage, modalType, captcha} = useSelector(state => state.toolsReducer)
    const [processLoading, setProcessLoading] = useState(false)
    const dispatch = useDispatch()
    const [fieldList, setFieldList] = useState([
        {
            label: 'Username',
            key: 'email',
            type: 'email'
        },
        {
            label: 'Password',
            key: 'password',
            type: 'password'
        }
    ])

    const showErrorModal = (message) => {
        setProcessLoading(false)
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType("error"))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            dispatch(setToolsModal(false))
        }, 1000)
    }

    const setCaptcha = (value) => {
        dispatch(setToolsCaptcha(value))
    }

    const loginHandler = (event) => {
        event.preventDefault()
        const fieldItemsCopy = [...fieldList]
        const elements = event.target
        const body = {
            email: null,
            password: null,
            recaptcha_token: captcha
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements, body) && !!captcha
        if (!isValid) {
            setFieldList(fieldItemsCopy)
            !!captcha ? setErrorCaptcha('') : setErrorCaptcha('Invalid Captcha')
        } else {
            setErrorCaptcha('')
            setProcessLoading(true)

            let token = bcrypt.hashSync(publicKey, 10);
            let bodyRequest = {
                grant: token
            }
            generatePublicTokenApi(bodyRequest)
                .then(result => {
                    loginApi(body, result?.access_token)
                        .then(response => {
                            setProcessLoading(false)
                            localStorage.setItem('AVOSKIN_OPS_SESSID_TOKEN', response?.sessid_token)
                            dispatch(setToolsModalVisibility(true))
                        })
                        .catch(err => {
                            if (err?.code === '503-0000') {
                                window.location.href = '/under-maintenance'
                            } else {
                                showErrorModal(err?.message[0]?.msg || err?.message || "Terjadi Kesalahan")
                            }
                            setCaptcha('')
                            recaptchaRef?.current?.reset()
                        })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    return (
        <>
            <div className={"col space-y-8 items-center justify-center pb-14 w-full relative"}>
                <Link to={"/"}>
                    <img src={avoMO} alt="avoMO" className={"w-48"}/>
                </Link>
                <div
                    className={"w-[480px] border rounded-xl border-grey-green p-20 col items-center space-y-10 text-xs bg-white shadow-xl"}>
                    <p className={"text-green-grey font-Belleza-Regular text-4xl"}>Log In</p>
                    <form onSubmit={loginHandler} className={"col items-center w-full space-y-10"}>
                        <div className={"space-y-5 w-full font-NunitoSans-SemiBold"}>
                            {fieldList.map((field, index) => (
                                <InputComponent field={field} key={index}/>
                            ))}
                        </div>
                        <Link className={"font-NunitoSans-Bold text-green-grey cursor-pointer"}
                              to={'/forgot-password'}>Lupa Password</Link>
                        <ReCAPTCHA ref={recaptchaRef} sitekey={googleRecaptchaSiteKey} onChange={(e) => setCaptcha(e)}
                                   onExpired={() => setCaptcha('')}/>
                        {errorCaptcha && <p className={"font-semibold text-red-600 italic"}>{errorCaptcha}</p>}
                        <button
                            type={"submit"} disabled={processLoading}
                            className={"bg-green-grey text-white font-NunitoSans-Bold w-1/2 h-8 rounded-full hover:bg-green-sage"}>
                            {processLoading ? <img src={LoadingSvg} className={"w-7 mx-auto"}/> : 'Masuk'}
                        </button>
                    </form>
                </div>
                <img src={loginBubble} alt="loginBubble" className={"w-5/12 absolute left-0 bottom-0"}/>
                <img src={loginBubble} alt="loginBubble" className={"w-5/12 absolute right-0 bottom-0 -scale-x-100"}/>
            </div>
            <ModalComponent message={modalMessage} type={modalType}/>
            <Verification recaptchaRef={recaptchaRef}/>
        </>
    )
}
