import axios from "axios";
import {authToken, refreshToken} from "../ui-utils/general-variable";

export const axiosDefault = axios.create()

axiosDefault.defaults.baseURL = process.env.REACT_APP_BASE_URL

axiosDefault.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

if (!!authToken) {
    axiosDefault.defaults.headers['Authorization'] = `Bearer ${authToken}`;
}

export const publicPostRequest = (URL, body, publicToken) => {
    axiosDefault.defaults.headers['Authorization'] = `Bearer ${publicToken}`;
    return axiosDefault.post(`/${URL}`, body, {})
}

export const postRequest = (URL, body, contentType) => {
    switch (contentType) {
        case 'form':
            axiosDefault.defaults.headers['Content-Type'] = `multipart/form-data`;
            break;
    }
    return axiosDefault.post(`/${URL}`, body, {})
}

export const loginRequest = (URL, body, token) => {
    axiosDefault.defaults.headers['Authorization'] = `Bearer ${token}`;
    return axiosDefault.post(`/${URL}`, body, {})
}

export const logoutAndRefreshRequest = (URL, body) => {
    axiosDefault.defaults.headers['Authorization'] = `Bearer ${refreshToken}`;
    return axiosDefault.post(`/${URL}`, body, {})
}

export const getRequest = (URL, params) => {
    return axiosDefault.get(`/${URL}`, {params})
}

export const exportRequest = (URL, body) => {
    return axiosDefault.post(`/${URL}`, body, {responseType: 'blob'})
}


