import {createSlice} from '@reduxjs/toolkit'

export const requestAwbSlice = createSlice({
    name: 'request-awb',
    initialState: {
        selectedRowKeys: [],
        selectedRows: [],
        orderList: [],
        previewPrintLabelList: [],
        activeTab: 'request-awb',
        reload: false,
        modalType: '',
        modalMessage: ''
    },
    reducers: {
        setRequestAwbSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload
        },
        setRequestAwbOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setRequestAwbActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setRequestAwbReload: (state, action) => {
            state.reload = action.payload
        },
        setRequestAwbSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        },
        setRequestAwbPreviewPrintLabelList: (state, action) => {
            state.previewPrintLabelList = action.payload
        },
        setRequestAwbModalType: (state, action) => {
            state.modalType = action.payload
        },
        setRequestAwbModalMessage: (state, action) => {
            state.modalMessage = action.payload
        },
        setRequestAwbInitialState: (state, action) => {
            state.selectedRowKeys = []
            state.selectedRows = []
        }
    },
})

export const {
    setRequestAwbSelectedRowKeys,
    setRequestAwbOrderList,
    setRequestAwbActiveTab,
    setRequestAwbReload,
    setRequestAwbSelectedRows,
    setRequestAwbPreviewPrintLabelList,
    setRequestAwbModalType,
    setRequestAwbModalMessage,
    setRequestAwbInitialState
} = requestAwbSlice.actions
const requestAwbReducer = requestAwbSlice.reducer

export default requestAwbReducer