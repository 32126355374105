import {Link} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import PaginationComponent from "../../components/pagination.component";

export const NotificationComponent = () => {
    const {notificationList} = useSelector(state => state.notificationReducer);

    return (
        <div className={"col items-center p-10 box-content-style"}>
            <p className={"font-Belleza-Regular font-semibold text-2xl text-dark-grey"}>Semua List Notifikasi</p>
            <div className={"w-full px-4 pt-4"}>
                {notificationList.map((list, index) => (
                    <Link key={index} to={`/order/order-list/order-detail/${list.order_id}`}
                          className={"row flex-wrap text-sm font-NunitoSans-SemiBold bg-light-green items-center rounded-lg w-full px-2 my-4"}>
                        <div className={"w-1/6"}>
                            <p className={"italic text-green-grey p-4"}>{list.date}</p>
                        </div>
                        <div className={"w-5/6"}>
                            <p className={"text-dark-grey p-4 pl-40"}>{list.body}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <PaginationComponent/>
        </div>
    )
}