import UnderMaintenanceImage from "../../images/png-jpg/under-maintenance.png";
import {useEffect, useState} from "react";
import {pingServerApi} from "../../api/under-maintenance.api";

export const UnderMaintenanceComponent = () => {
    const [errorData, setErrorData] = useState({})

    useEffect(() => {
        pingServerApi().then(() => {
            window.location.href = '/'
        }).catch(err => {
            setErrorData(err?.data?.data)
        })
    }, [])

    return (
        <div className={"col w-screen h-screen items-center justify-center space-y-4"}>
            <img src={errorData?.image_url ?? UnderMaintenanceImage} alt="under-maintenance-image" className={"w-1/2 h-1/2"}/>
            <p className={"font-NunitoSans-Bold text-cornflower-blue text-lg"}>{errorData?.title ?? 'Under Maintenance'}</p>
            <div className={"text-cornflower-blue"} dangerouslySetInnerHTML={{__html: errorData?.description ?? 'Will be Back Soon!'}} />
            <button className={"w-60 py-2 bg-grey-green to-green-sage text-white rounded-lg"} onClick={() => window.location.reload()}>Refresh Page</button>
        </div>
    )
}