import React, {useEffect} from "react";
import ChartComponent from "../../components/chart.component";
import {dateRangeToday, formatDateToReadable} from "../../ui-utils/general-variable";
import {getCustomerInfoStatisticApi} from "../../api/customer-info.api";
import {useDispatch, useSelector} from "react-redux";
import {setCustomerGenderDataChart} from "../../reducers/customer-info.reducer";
import ToolTipComponent from "../../components/tool-tip.component";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";

const GenderChartComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {genderDataChart} = useSelector(state => state.customerInfoReducer)
    const genderList = [
        {
            label: 'Perempuan',
            key: 'female',
            dotClassName: 'bg-light-pink border-powder-pink',
            textColor: 'text-dark-fuchsia'
        }, {
            label: 'Laki - Laki',
            key: 'male',
            dotClassName: 'bg-lightblue border-cornflower-blue',
            textColor: 'text-dusk-blue'
        }, {
            label: 'Tidak Disebutkan',
            key: 'not_defined',
            dotClassName: 'bg-ice-blue border-lightblue-two',
            textColor: 'text-bluish'
        }
    ]

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            let params = {
                scope: 'gender',
                startDate: formatDateToReadable(dateRange[0], 'YYYY-MM-DD'),
                endDate: formatDateToReadable(dateRange[1], 'YYYY-MM-DD')
            }
            getCustomerInfoStatisticApi(params)
                .then(result => {
                    dispatch(setCustomerGenderDataChart(result))
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    console.log(err)
                })
        }
    }, [dateRange])

    return (
        <div className={"border rounded-md p-2"}>
            <div className={"row justify-end"}>
                <ToolTipComponent/>
            </div>
            <p className={"font-Belleza-Regular text-center text-brownish-grey text-base"}>{genderDataChart.title}</p>
            <p className={"text-dark-sage-six text-center font-Belleza-Regular"}>{genderDataChart.detail?.total_user} pembeli</p>
            <div className={"row justify-center w-full h-72"}>
                <ChartComponent type={'pie'} data={genderDataChart.data} width={200} color={['#a80b62', '#2c61a2', '#2094b4']}/>
            </div>
            <div className={"col space-y-2"}>
                {
                    genderList.map((item, index) => (
                        <div className={`row items-center justify-between ${item.textColor}`}
                             key={index.toString()}>
                            <div className={"row items-center space-x-2"}>
                                <div className={`h-2 w-2 rounded-full ${item.dotClassName} border`}/>
                                <p>{item.label}</p>
                            </div>
                            <p>({genderDataChart.detail[item.key]} User)</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default GenderChartComponent
