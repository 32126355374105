import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getOrderDetailLogApi} from "../../api/order.api";
import {useParams} from "react-router-dom";
import {setOrderDetailLogs} from "../../reducers/order.reducer";
import {convertDateToCustomFormat} from "../../ui-utils/general-variable";

const HistoryOrderComponent = () => {
    const {orderDetailLogs} = useSelector(state => state.orderReducer)
    const dispatch = useDispatch()
    const {id} = useParams()

    useEffect(() => {
        getOrderDetailLogApi(id).then(response => {
            const result = response?.data.logs
            dispatch(setOrderDetailLogs(result))
        }).catch(err => {
            if (err?.code === '503-0000') {
                localStorage.clear()
                window.location.href = '/under-maintenance'
            }
            console.log(err)
        })
    }, [])

    return (
        <div className={"col box-content-style"}>
            <div className={"row justify-center items-center p-3"}>
                <p className={"font-NunitoSans-Bold text-dark-sage-four"}>Riwayat Pemesanan</p>
            </div>
            <hr/>
            <div className={"py-4 px-6 mb-5 max-h-[45rem] overflow-y-scroll"}>
                {orderDetailLogs.length > 0 ? orderDetailLogs.map((log, index) => (
                    <div key={index} className={"row items-start space-x-5 mb-2"}>
                        <div className={"col space-y-2 items-center"}>
                            <div className={`border-2 p-1 rounded-full bg-white ${index === 0 ? 'border-dark-sage-five' : 'border-white'}`}>
                                <div className={`p-[5px] rounded-full ${index === 0 ? 'bg-dark-sage-five' : 'bg-light-grey-three'}`}></div>
                            </div>
                            {orderDetailLogs.length - 1 !== index && (
                                <div className={`w-[3px] h-20 ${index === 0 ? 'bg-grey-green-two' : 'bg-light-grey-three'}`}></div>
                            )}
                        </div>
                        <div className={"col space-y-1 text-brownish-grey"}>
                            <div className={"col space-y-1"}>
                                <p className={"max-h-16 overflow-scroll"}>{log.text}</p>
                                <p>{convertDateToCustomFormat(log.created_at, 'DD / MM / YYYY HH:mm')}</p>
                            </div>
                        </div>
                    </div>
                )) : <p className={"font-NunitoSans-SemiBold"}>History order not found</p> }
            </div>
        </div>
    )
}

export default HistoryOrderComponent
