import React, {useEffect} from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import ChartComponent from "../../components/chart.component";
import {dateRangeToday, formatDateToReadable} from "../../ui-utils/general-variable";
import {getCustomerInfoStatisticApi} from "../../api/customer-info.api";
import {useDispatch, useSelector} from "react-redux";
import {setCustomerBuyerAgeDataChart} from "../../reducers/customer-info.reducer";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";

const BuyerAgeChartComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {buyerAgeDataChart} = useSelector(state => state.customerInfoReducer)

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            let params = {
                scope: 'buyer-age',
                startDate: formatDateToReadable(dateRange[0], 'YYYY-MM-DD'),
                endDate: formatDateToReadable(dateRange[1], 'YYYY-MM-DD')
            }
            getCustomerInfoStatisticApi(params)
                .then(result => {
                    dispatch(setCustomerBuyerAgeDataChart(result))
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    console.log(err)
                })
        }
    }, [dateRange])
  return (
      <div className={"border rounded-md p-2 col-span-2"}>
          <div className={"row justify-end"}>
              <ToolTipComponent/>
          </div>
          <p className={"font-Belleza-Regular text-center text-brownish-grey text-base"}>{buyerAgeDataChart?.title}</p>
          <p className={"text-dark-sage-six text-center font-Belleza-Regular"}>{buyerAgeDataChart?.detail.total_user} pembeli</p>
          <div className={"w-full h-96"}>
              <ChartComponent type={'bar'} data={buyerAgeDataChart?.data} tooltip={'Tingkat Keterlambatan'} width={470}/>
          </div>
      </div>
  )
}

export default BuyerAgeChartComponent
