import React, {useRef} from "react";
import ModalCardComponent from "./modal-card.component";
import AvoskinLogo from "../images/png-jpg/avoskin-logo-black.jpg"
import {useDispatch, useSelector} from "react-redux";
import PrintLabelSvg from "../images/svg/Group 431.svg";
import ReactToPrint from "react-to-print";
import './PrintA6.css';
import Barcode from "react-barcode";
import moment from "moment";
import {printLabelBulkApi} from "../api/order.api";
import {
    setRequestAwbReload,
    setRequestAwbSelectedRowKeys,
    setRequestAwbSelectedRows
} from "../reducers/request-awb.reducer";
import {
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility
} from "../reducers/tools.reducer";
import {convertDateToCustomFormat, getExpeditionLogo} from "../ui-utils/general-variable";
import {useParams} from "react-router-dom";
import CloseIcon from "../images/svg/x.svg";

const PrintLabelModal = () => {
    const dispatch = useDispatch()
    const componentRef = useRef()
    const {id} = useParams()
    const {previewPrintLabel} = useSelector(state => state.orderReducer)
    const {previewPrintLabelList, reload, selectedRowKeys} = useSelector(state => state.requestAwbReducer)
    const {modalVisibility} = useSelector(state => state.toolsReducer)
    const printLabelList = id ? previewPrintLabel : previewPrintLabelList

    const printLabelHandler = () => {
        let requestBody = {
            order_ids: selectedRowKeys
        }
        printLabelBulkApi(requestBody)
            .then(response => {
                sendAlert('success')
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                sendAlert('error')
            })
    }

    const sendAlert = (type) => {
        if (type === 'success') {
            dispatch(setToolsModalType('success'))
            dispatch(setToolsModalMessage(`Berhasil memindah order`))
        } else {
            dispatch(setToolsModalType('error'))
            dispatch(setToolsModalMessage(`Gagal Memindah Order`))
        }

        closeModal()
        dispatch(setRequestAwbSelectedRowKeys([]))
        dispatch(setRequestAwbSelectedRows([]))
        dispatch(setRequestAwbReload(!reload))
        dispatch(setToolsModal(true))
    }

    const closeModal = () => {
        dispatch(setToolsModalVisibility(false))
    }

    const getHeight = (length) => {
        switch (true){
            case length > 10:
                return 'h-[300mm]'
            case length > 5:
                return 'h-[150mm]'
            default:
                return 'h-[140mm]'
        }
    }

    return (
        <ModalCardComponent
            visibility={modalVisibility}
            content={
                <div className={"col bg-white rounded-md relative max-h-[150mm]  overflow-y-scroll p-5"}>
                    <img src={CloseIcon} alt="closeButton"
                         className={`w-5 absolute right-4 top-2 cursor-pointer`}
                         onClick={closeModal}/>
                    <div className={"text-black text-10 font-NunitoSans-Bold"} ref={componentRef}>
                        {
                            printLabelList.map((item, index) => (
                                <div key={index}
                                     className={`size-a6 px-2 w-[105mm] ${getHeight(item.products.length)}`}>
                                    <div className={"row justify-between items-center w-full px-5"}>
                                        <img src={AvoskinLogo} className={"h-12"}/>
                                        {item.awb && <Barcode value={item.awb}
                                                              textPosition={"bottom"}
                                                              textMargin={5}
                                                              height={30}
                                                              width={1.3}
                                                              fontSize={13}/>}
                                        <img src={getExpeditionLogo(item.shipping_method)}
                                             className={"h-7 w-20 object-contain"}/>
                                    </div>
                                    <div className="col divide-y divide-black w-full">
                                        <div className={"print-label-grid"}>
                                            <div
                                                className={"row items-center space-x-2"}>
                                                <p>Jenis Layanan</p>
                                                <p>{item.shipping_method}</p>
                                            </div>
                                            <div className={"row items-center space-x-2"}>
                                                <p>Tanggal Order</p>
                                                <p>{convertDateToCustomFormat(item.order_date, 'DD / MM / YYYY HH:mm')}</p>
                                            </div>
                                        </div>
                                        <div className={"grid grid-cols-2 divide-x divide-black"}>
                                            <div className={"col justify-center items-center"}>
                                                <Barcode value={String(item.id)}
                                                         textPosition={"bottom"}
                                                         textMargin={5}
                                                         height={30}
                                                         width={1.3}
                                                         fontSize={13}/>
                                            </div>
                                            <div className={"col divide-y divide-black"}>
                                                <div className={"row items-center space-x-5 pl-2 py-1"}>
                                                    <p className={"font-NunitoSans-Bold"}>Asuransi</p>
                                                    <p>-</p>
                                                </div>
                                                <div className={"row items-center space-x-5 pl-2  py-1"}>
                                                    <p>Berat</p>
                                                    <p>{item.weight_total} Kg</p>
                                                </div>
                                                <div className={"row items-center space-x-5 pl-2 pt-1"}>
                                                    <p>Quantity</p>
                                                    <p>{item.total_quantity} pcs</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"print-label-grid"}>
                                            <div>
                                                <p>Nama Penerima</p>
                                                <p className={"capitalize"}>{item.customer_name}</p>
                                            </div>
                                            <div>
                                                <p>Nama Pengirim</p>
                                                <p>Avoskin</p>
                                            </div>
                                        </div>
                                        <div className={"print-label-grid"}>
                                            <div>
                                                <p>Alamat Penerima</p>
                                                <p className={"text-left"}>{item.customer_address}</p>
                                            </div>
                                            <div>
                                                <p>Alamat Pengirim</p>
                                                <p className={"text-left"}>Jln Kebon Agung No 101 RT 04 RW 08 Ngemplak
                                                    Sendangadi Mlati Sleman Daerah Istimewa Yogyakarta 55285</p>
                                            </div>
                                        </div>
                                        <div className={"print-label-grid"}>
                                            <div>
                                                <p>Nomor Telpon Penerima</p>
                                                <p className={"capitalize"}>{item.customer_phone}</p>
                                            </div>
                                            <div>
                                                <p>Nomor Telpon Pengirim</p>
                                                <p>081914102014</p>
                                            </div>
                                        </div>
                                        <div className={"py-1 pl-2"}>
                                            <p>Catatan</p>
                                            <p>{item.shipping_notes}</p>
                                        </div>
                                        <div className={"pr-5 py-1 pl-2"}>
                                            <p>Detail Barang</p>
                                            {
                                                item.products.map((i, index) => (
                                                    <div key={index}
                                                         className={"row items-center justify-between w-full"}>
                                                        <p>{i.name}</p>
                                                        <p>{i.quantity}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={"w-full row items-center justify-between pt-10"}>
                        {!id && <button
                            onClick={printLabelHandler}
                            className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                            <img src={PrintLabelSvg} className={"w-9"}/>
                            <p>Pindah Surat Jalan</p>
                        </button>}
                        <ReactToPrint
                            pageStyle={`
                                @media print {
                                  .page-break {
                                    margin-top: 1rem;
                                    display: block;
                                    page-break-before: true;
                                    page-break-after: true;
                                  }
                                }
                                @page {
                                  size: 105mm 150mm;
                                  margin: 20mm;
                                }
                            `}
                            documentTitle={`Avoskin - ${moment().format('DD-MM-YYYY HH-mm')}`}
                            trigger={() => (
                                <button
                                    className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                                    <img src={PrintLabelSvg} className={"w-9"}/>
                                    <p>Print Label</p>
                                </button>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                </div>
            }
        />
    )
}

export default PrintLabelModal
