import React from "react";
import SearchComponent from "../../components/search.component";
import SelectComponent from "../../components/select.component";
import FilterExpeditionComponent from "../../components/filter-expedition.component";
import ClearComponent from "../../components/clear.component";
import {useDispatch} from "react-redux";
import {setToolsOrderBy, setToolsSort} from "../../reducers/tools.reducer";

const FilterComponent = () => {
    const dispatch = useDispatch()
    const sortByList = [
        {
            label: 'Nama User A-Z',
            key: 'name_asc'
        }, {
            label: 'Nama User Z-A',
            key: 'name_desc'
        }, {
            label: 'Waktu Proses Terdekat',
            key: 'processing-time_asc'
        }, {
            label: 'Waktu Proses Terlama',
            key: 'processing-time_desc'
        }, {
            label: 'Jumlah Biaya Pesanan Terbesar',
            key: 'cost_desc'
        }, {
            label: 'Jumlah Biaya Pesanan Terkecil',
            key: 'cost_asc'
        }
    ]

    const onChangeSelectByName = (sortItem) => {
        let text = sortItem.split('_')
        dispatch(setToolsOrderBy(text[0]))
        dispatch(setToolsSort(text[1]))
    }

    return (
        <div className={"row items-center space-x-5"}>
            <SearchComponent/>
            <SelectComponent dataList={sortByList} onChange={onChangeSelectByName}/>
            <FilterExpeditionComponent slug={'delivery-delay'}/>
            <ClearComponent slug={'delivery-delay'}/>
        </div>
    )
}

export default FilterComponent
