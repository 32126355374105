import {combineReducers, configureStore} from "@reduxjs/toolkit";
import userReducer from "./user.reducer";
import notificationReducer from "./notification.reducer";
import toolsReducer from "./tools.reducer";
import orderReducer from "./order.reducer";
import requestAwbReducer from "./request-awb.reducer";
import manifestReducer from "./manifest.reducer";
import servicePerformanceReducer from "./service-performance.reducer";
import productPerformanceReducer from "./product-performance.reducer";
import customerInfoReducer from "./customer-info.reducer";
import settingReducer from "./setting.reducer";

const rootReducer = combineReducers({
    userReducer,
    notificationReducer,
    toolsReducer,
    orderReducer,
    requestAwbReducer,
    manifestReducer,
    servicePerformanceReducer,
    customerInfoReducer,
    productPerformanceReducer,
    settingReducer
})

const store = configureStore({reducer: rootReducer})

export default store
