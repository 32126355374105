import ModalCardComponent from "../../components/modal-card.component";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsCaptcha, setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility
} from "../../reducers/tools.reducer";
import LoadingSvg from "../../images/svg/loading-trans.svg";
import CloseIcon from "../../images/svg/x.svg";
import {InputCode} from "./input-code.component";
import {verifyLogin} from "../../api/auth.api";

export const Verification = ({recaptchaRef}) => {
    const dispatch = useDispatch()
    const [processLoading, setProcessLoading] = useState(false)
    const sessid_token = localStorage.getItem('AVOSKIN_OPS_SESSID_TOKEN')
    const [errorOTP, setErrorOTP] = useState('')
    const [OTP, setOTP] = useState('')
    const otpLength = 4
    const {modalVisibility} = useSelector(state => state.toolsReducer)

    const closeModalHandler = () => {
        dispatch(setToolsCaptcha(''))
        recaptchaRef?.current?.reset()
        dispatch(setToolsModalVisibility(false))
    }

    const showErrorModal = (message, type) => {
        setProcessLoading(false)
        dispatch(setToolsModalVisibility(false))
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType("error"))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            dispatch(setToolsModal(false))
            if(type === 'invalidOTP') dispatch(setToolsModalVisibility(true))
        }, 1000)
    }

    const verificationHandler = () => {
        if (OTP.length < otpLength) {
            setErrorOTP('Please Complete Input OTP')
        } else {
            setErrorOTP('')
            setProcessLoading(true)
            const body = {
                sessid_token,
                otp_code: Number(OTP)
            }
            verifyLogin(body).then(response => {
                setProcessLoading(false)
                localStorage.removeItem("AVOSKIN_OPS_SESSID_TOKEN")
                localStorage.setItem('AVOSKIN_OPS_TOKEN', response?.access_token)
                localStorage.setItem('AVOSKIN_OPS_REFRESH_TOKEN', response?.refresh_token)
                window.location.reload()
            }).catch(err => {
                const errorCode = err?.response?.data?.code
                switch (errorCode) {
                    case '401-B001':
                        showErrorModal('Invalid OTP, mohon masukkan OTP yang benar', 'invalidOTP')
                        break;
                    case '401-B003':
                    case '401-B004':
                    case '401-B006':
                        dispatch(setToolsModalVisibility(false))
                        showErrorModal('Session OTP sudah habis, silahkan login ulang')
                        break;
                    case '503-0000':
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                        break;
                }
                dispatch(setToolsCaptcha(''))
                recaptchaRef?.current?.reset()
            })
        }
    }

    useEffect(() => {
        setErrorOTP('')
        setOTP('')
    }, [modalVisibility])

    return (
        <>
            <ModalCardComponent
                visibility={modalVisibility}
                onClose={closeModalHandler}
                content={
                    <div className={"relative"}>
                        <div className={"col justify-center w-80 h-auto p-5 bg-white rounded-xl"}>
                            <div className={"col space-y-6 justify-center items-center"}>
                                <p className={'text-sm font-NunitoSans-SemiBold mb-4'}>Input OTP code from your email</p>
                                <InputCode cols={otpLength} value={OTP} onChange={(e) => setOTP(e)}/>
                                {errorOTP && <p className={"font-NunitoSans-SemiBold text-red-600 italic"}>{errorOTP}</p>}
                                <button type={"button"} onClick={verificationHandler}
                                        className={"row justify-center px-16 py-2 bg-green-grey font-NunitoSans-SemiBold text-white rounded-lg cursor-pointer"}>
                                    {processLoading ? <img src={LoadingSvg} className={"w-7 mx-auto"}/> : 'Submit'}
                                </button>
                            </div>
                        </div>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </>
    )
}