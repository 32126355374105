import React from "react";
import TableComponent from "../../components/table.component";
import {useDispatch, useSelector} from "react-redux";
import {setOrderSelectedRowKeys} from "../../reducers/order.reducer";
import UserSvg from "../../images/svg/Group 413.svg"
import ClockSvg from "../../images/svg/Group 444.svg"
import ArrowRightSvg from "../../images/svg/Group 454.svg"
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat, isAfterDateTimeNow,
    statusOrderCountDown,
    statusRenderClassName
} from "../../ui-utils/general-variable";
import TotalDataComponent from "../../components/total-data.component";
import {Link} from "react-router-dom";
import CountdownComponent from "../../components/countdown.component";
import LoadingSvg from "../../images/svg/loading-trans.svg";

const OrderListComponent = () => {
    const dispatch = useDispatch()
    const {selectedRowKeys, orderList} = useSelector(state => state.orderReducer)
    const {loading} = useSelector(state => state.toolsReducer)

    const columns = [
        {
            type: 'checkbox',
        }, {
            name: 'Produk',
            width: 100,
            classNameHeader: 'pl-3',
            render: (record) => (
                <div className={"font-NunitoSans-Regular space-y-2 my-2 border-r border-gray-100 px-3"}>
                    <div className={"row items-center space-x-2"}>
                        <div className={"w-10 row justify-center"}>
                            <img src={UserSvg} className={"w-7"}/>
                        </div>
                        <Link to={`/order/order-list/order-detail/${record.id}`}>
                            <p className={"text-green-grey font-NunitoSans-SemiBold w-20"}>{record?.order_code}</p>
                        </Link>
                        <p className={"text-dark-grey font-NunitoSans-Bold w-24 truncate capitalize"}>{record.customer_name}</p>
                    </div>
                    <div className={"row items-center space-x-2 flex-1"}>
                        <img src={record.image_url || null} className={"w-10 h-10 object-contain"}/>
                        <div
                            className={"flex-1 row items-center justify-between font-NunitoSans-SemiBold text-dark-grey"}>
                            <p className={"capitalize w-10/12"}>{record.product_name}</p>
                            <p className={"font-NunitoSans-Bold"}>x{record.order_quantity}</p>
                        </div>
                    </div>
                    <p className={"text-green-grey ml-12"}>{record.product_count > 1 && `dan ${record.product_count - 1} produk lainnya`}</p>
                </div>
            )
        }, {
            name: 'Jumlah',
            dataIndex: 'grand_total',
            className: 'text-center',
            classNameHeader: 'text-center',
            render: (grand_total) => <p
                className={"font-NunitoSans-Bold text-center text-green-grey px-2"}>{doConvertNumberToRupiahFormat(grand_total)}</p>
        }, {
            name: 'Alamat',
            dataIndex: 'shipping_full_address',
            classNameHeader: 'pl-2',
            render: (shipping_full_address) => <p className={"text-dark-grey px-2 w-52"}>{shipping_full_address}</p>
        },
        {
            name: 'Status',
            dataIndex: 'status',
            width: 100,
            className: 'text-center',
            classNameHeader: 'text-center',
            render: (status) => <p
                className={`${statusRenderClassName(status).className} text-center rounded-full py-1 capitalize`}>{statusRenderClassName(status).text}</p>
        }, {
            name: 'Hitung Mundur',
            dataIndex: 'status',
            className: 'text-center',
            classNameHeader: 'pl-6',
            render: (status, record) => (
                <div className={"space-y-1 px-6"}>
                    {
                        (status === 'processing' && isAfterDateTimeNow(record.expired_process_order)) ?
                            <CountdownComponent expired={record.expired_process_order}/> :
                            <p className={"font-NunitoSans-Bold text-brownish-red"}>{statusOrderCountDown(status, record).orderInformation}</p>
                    }
                    <div className={"text-dark-grey"}>
                        <p>{statusOrderCountDown(status, record).orderMessage}</p>
                        <p className={"font-NunitoSans-Bold"}>{statusOrderCountDown(status, record).completedTime}</p>
                        <p className={"font-NunitoSans-Bold"}>{statusOrderCountDown(status, record).processingExpiredTime}</p>
                    </div>
                </div>
            )
        }, {
            name: '',
            dataIndex: 'created_at',
            render: (created_at, record) => (
                <div className={"col space-y-10 items-end"}>
                    <div className={"row items-center space-x-2"}>
                        <img src={ClockSvg} className={"w-5"}/>
                        <p className={"text-brownish-grey"}>{convertDateToCustomFormat(created_at, 'DD / MM / YYYY HH:mm')}</p>
                    </div>
                    <Link to={`/order/order-list/order-detail/${record.id}`}
                          className={"row items-center space-x-1 font-NunitoSans-Bold"}>
                        <p className={"text-green-grey"}>Selengkapnya</p>
                        <img src={ArrowRightSvg} className={"h-4"}/>
                    </Link>
                </div>
            )
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setOrderSelectedRowKeys(array))
    }

    return (
        <>
            {loading ?
                <div className={"row justify-center mt-5 h-52"}>
                    <img src={LoadingSvg} className={"w-12"}/>
                </div> :
                <div>
                    <TotalDataComponent/>
                    <TableComponent
                        columns={columns}
                        tableClassName={"font-NunitoSans-Regular"}
                        headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
                        selectedRowKeys={selectedRowKeys}
                        onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                        dataSource={orderList}
                        pagination={true}
                        // loading={loading}
                    />
                    <div className={"w-full row justify-center"}>
                        <TotalDataComponent type={"show-background"}/>
                    </div>
                </div>
            }
        </>
    )
}

export default OrderListComponent
