import {createSlice} from '@reduxjs/toolkit'

export const manifestSlice = createSlice({
    name: 'manifest',
    initialState: {
        selectedRowKeys: [],
        selectedRows: [],
        activeTab: 'manifest-packing',
        orderList: [],
        reload: false,
        scanBarcodeModalVisibility: false
    },
    reducers: {
        setManifestSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload
        },
        setManifestActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setManifestOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setManifestReload: (state, action) => {
            state.reload = action.payload
        },
        setManifestSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        },
        setManifestScanBarcodeModalVisibility: (state, action) => {
            state.scanBarcodeModalVisibility = action.payload
        },
        setManifestInitialState: (state, action) => {
            state.selectedRowKeys = []
            state.selectedRows = []
        }
    },
})

export const {
    setManifestSelectedRowKeys,
    setManifestActiveTab,
    setManifestOrderList,
    setManifestReload,
    setManifestSelectedRows,
    setManifestScanBarcodeModalVisibility,
    setManifestInitialState
} = manifestSlice.actions
const manifestReducer = manifestSlice.reducer

export default manifestReducer
