import {useSelector} from "react-redux";
import {doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";

const PaymentInformationComponent = () => {
    const {transaction} = useSelector(state => state.orderReducer.orderDetail)
    const reduce_payment = [
        {
            label: 'Loyalty Point',
            data: transaction?.loyalty_point
        }, {
            label: 'Membership Discount',
            data: transaction?.membership_discount
        }, {
            label: 'Birthday Discount',
            data: transaction?.birthday_discount
        }
    ]

    return (
        <div className={"col text-dark-sage-two font-NunitoSans-Bold w-full pb-8"}>
            <div className={"col space-y-3 mb-3"}>
                <div className={"row justify-between px-12 mb-2"}>
                    <p>SUBTOTAL</p>
                    <p>{doConvertNumberToRupiahFormat(transaction.subtotal)}</p>
                </div>
                <div className={"row justify-between px-12 mb-2"}>
                    <p>Ongkos Kirim</p>
                    <p>{doConvertNumberToRupiahFormat(transaction.shipping_total)}</p>
                </div>
            </div>
            <div className={'px-12'}>
                <p className={"text-base text-brownish-grey mb-2"}>Voucher yang diterapkan</p>
                <div className={"row justify-between items-center mb-3"}>
                    <div className={"col space-y-2"}>
                        {transaction.vouchers.length > 0 ?
                            <>
                                {transaction.vouchers.map((coupon, index) => (
                                    <p key={index} className={"text-green-grey font-NunitoSans-Regular px-4 py-1 bg-beige-three w-fit border border-green-grey mx-2"}>
                                        {coupon}
                                    </p>
                                ))}
                            </>
                            : <p className={"font-NunitoSans-SemiBold text-brownish-grey mx-2"}>Tidak ada</p>}
                    </div>
                    <div className={"col justify-end items-center font-NunitoSans-SemiBold"}>
                        <p className={"pb-1 text-red-600"}>{doConvertNumberToRupiahFormat(transaction.discount)}</p>
                    </div>
                </div>
            </div>
            {
                reduce_payment.map((item, index) => (
                    <div key={index} className={"row justify-between px-12 mb-5"}>
                        <p>{item.label}</p>
                        <p className={"pb-1 text-red-600"}>{doConvertNumberToRupiahFormat(item.data ?? 0)}</p>
                    </div>
                ))
            }
            <div className={"row justify-between px-12 mb-5"}>
                <p>PPn</p>
                <p>{doConvertNumberToRupiahFormat(transaction.tax_total)}</p>
            </div>
            <div className={"row justify-between items-center bg-light-grey-five py-2 w-full rounded-md"}>
                <p className={"text-base px-12"}>TOTAL</p>
                <p className={"text-lg px-10"}>{doConvertNumberToRupiahFormat(transaction.grand_total)}</p>
            </div>
        </div>
    )
}

export default PaymentInformationComponent
