import {createSlice} from '@reduxjs/toolkit'

export const toolsSlice = createSlice({
    name: 'tools',
    initialState: {
        currentPage: 1,
        pageSize: 10,
        totalData: 0,
        searchKey: '',
        sort: 'desc',
        orderBy: null,
        status: {},
        filter: null,
        expedition: {
            id: 'all',
            label: 'All Expedition',
            key: ''
        },
        loading: false,
        modal: false,
        reload: false,
        dateRange: [null, null],
        modalVisibility: false,
        modalType: '',
        modalMessage: '',
        loadingExport: false,
        captcha: '',
        refreshAlert: false
    },
    reducers: {
        setToolsCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setToolsPageSize: (state, action) => {
            state.pageSize = action.payload
        },
        setToolsTotalData: (state, action) => {
            state.totalData = action.payload
        },
        setToolsSearchKey: (state, action) => {
            state.searchKey = action.payload
        },
        setToolsSort: (state, action) => {
            state.sort = action.payload
        },
        setToolsStatus: (state, action) => {
            state.status = action.payload
        },
        setToolsExpedition: (state, action) => {
            state.expedition = action.payload
        },
        setToolsFilter: (state, action) => {
            state.filter = action.payload
        },
        setToolsLoading: (state, action) => {
            state.loading = action.payload
        },
        setToolsStartDateEndDate: (state, action) => {
            state.dateRange = action.payload
        },
        setToolsOrderBy: (state, action) => {
            state.orderBy = action.payload
        },
        setToolsModal: (state, action) => {
            state.modal = action.payload
        },
        setToolsReload: (state, action) => {
            state.reload = action.payload
        },
        setToolsModalVisibility: (state, action) => {
            state.modalVisibility = action.payload
        },
        setToolsModalType: (state, action) => {
            state.modalType = action.payload
        },
        setToolsModalMessage: (state, action) => {
            state.modalMessage = action.payload
        },
        setToolsLoadingExport: (state, action) => {
            state.loadingExport = action.payload
        },
        setToolsCaptcha: (state, action) => {
            state.captcha = action.payload
        },
        setToolsInitialState: (state) => {
            state.currentPage = 1
            state.pageSize = 10
            state.searchKey = ''
            state.sort = 'desc'
            state.orderBy = null
            state.status = {}
            state.filter = null
            state.expedition = {}
            state.dateRange = [null, null]
        },
        setRefreshAlert: (state, action) => {
            state.refreshAlert = action.payload
        }
    },
})

export const {
    setToolsCurrentPage,
    setToolsPageSize,
    setToolsTotalData,
    setToolsSearchKey,
    setToolsSort,
    setToolsStatus,
    setToolsExpedition,
    setToolsLoading,
    setToolsStartDateEndDate,
    setToolsInitialState,
    setToolsModal,
    setToolsReload,
    setToolsModalVisibility,
    setToolsModalType,
    setToolsModalMessage,
    setToolsOrderBy,
    setToolsCaptcha,
    setRefreshAlert
}
    = toolsSlice.actions
const toolsReducer = toolsSlice.reducer

export default toolsReducer
