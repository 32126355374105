import UserProfileSvg from "../../images/svg/Group 413.svg";
import InputProfileInfoComponent from "./input-profile-info.component";
import React, {useState} from "react";
import ChangePasswordModalComponent from "./change-password-modal.component";
import {checkFieldValidate, convertDateToCustomFormat, getLocalStorage} from "../../ui-utils/general-variable";
import {useDispatch, useSelector} from "react-redux";
import {updateProfileApi} from "../../api/user.api";
import {
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility
} from "../../reducers/tools.reducer";
import ModalComponent from "../../components/modal.component";
import CalendarDateSinglePickerComponent from "../../components/calendar-date-single-picker.component";
import LoadingSvg from "../../images/svg/loading-trans.svg";
import {EMAIL, GENDER, NAME} from "../../models/user.model";
import {setUserDetail} from "../../reducers/user.reducer";

export const ProfileInfoComponent = () => {
    const dispatch = useDispatch()
    const [processUpdateProfile, setProcessUpdateProfile] = useState(false)
    const {modalMessage, modalType} = useSelector(state => state.toolsReducer)
    const {userDetail} = useSelector(state => state.userReducer)
    const [birthDate, setBirthDate] = useState(userDetail?.date_of_birth)

    const params = {
        full_name: null,
        email: null,
        gender: null,
        date_of_birth: null
    }

    const [fieldList, setFieldList] = useState([
        {
            label: 'Full Name',
            key: 'full_name',
            type: 'text',
            defaultValue: getLocalStorage(NAME)
        },
        {
            label: 'Email',
            key: 'email',
            type: 'email',
            defaultValue: getLocalStorage(EMAIL)
        },
        {
            label: 'Gender',
            key: 'gender',
            type: 'radio',
            defaultValue: Number(getLocalStorage(GENDER)),
            listRadio: [
                {
                    label: 'Male',
                    key: 'male',
                    inputClassName: 'peer/male',
                    labelClassName: 'peer-checked/male:text-green-sage peer-checked/male:font-NunitoSans-SemiBold',
                    value: 'male',
                },
                {
                    label: 'Female',
                    key: 'female',
                    inputClassName: 'peer/female',
                    labelClassName: 'peer-checked/female:text-green-sage peer-checked/female:font-NunitoSans-SemiBold',
                    value: 'female',
                }
            ]
        },
    ])

    const openModal = (event) => {
        event.preventDefault();
        dispatch(setToolsModalVisibility(true))
    }

    const showMessageModal = (message, type, response) => {
        dispatch(setUserDetail(response?.data))
        setProcessUpdateProfile(false)
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType(type))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            dispatch(setToolsModal(false))
        }, 1000)
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const fieldItemsCopy = [...fieldList]
        const elements = event.target
        params.date_of_birth = birthDate
        const isValid = checkFieldValidate(fieldItemsCopy, elements, params)

        if (!isValid) {
            setFieldList(fieldItemsCopy)
        } else {
            setProcessUpdateProfile(true)
            updateProfileApi(params).then(response => {
                showMessageModal('Update profile berhasil', "success", response)
            }).catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                showMessageModal("Terjadi kesalahan!", "error")
            })
        }
    }

    const getValueFromCalendarDateBirth = (value) => {
        const convertDate = convertDateToCustomFormat(value, 'YYYY-MM-DD')
        setBirthDate(convertDate)
    }

    return (
        <>
            <div className={"col items-center font-NunitoSans-Regular text-brownish-grey text-sm"}>
                <div className={"col space-y-5 w-1/2 p-7 box-content-style"}>
                    <div className={"row items-center justify-between mr-3"}>
                        <div className={"row items-center space-x-3"}>
                            <img src={userDetail?.image_url ?? UserProfileSvg} alt="UserProfileSvg" className={"w-20 rounded-full"}/>
                            <div>
                                <p className={"font-NunitoSans-Bold text-green-grey text-lg"}>{userDetail?.full_name}</p>
                                <p className={"font-NunitoSans-SemiBold"}>Member
                                    Since {convertDateToCustomFormat(userDetail?.joined_at, 'D MMM YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={onSubmitHandler}>
                        <div className={"col space-y-5 mx-3 relative"}>
                            {fieldList.map((field, index) => (
                                <div key={index}>
                                    <InputProfileInfoComponent field={field} openModal={openModal} data={userDetail}/>
                                </div>
                            ))}
                            <div className={"col space-y-3 w-10/12"}>
                                <p className={"font-NunitoSans-Bold"}>Date of birth</p>
                                <CalendarDateSinglePickerComponent currentDate={userDetail?.date_of_birth} onChange={getValueFromCalendarDateBirth}/>
                            </div>
                            <button type={"submit"} disabled={processUpdateProfile}
                                    className={"font-NunitoSans-Bold w-fit rounded-full py-2 px-20 bg-green-grey text-white border border-dark-sage-four hover:bg-green-sage"}>
                                { processUpdateProfile ? <img src={LoadingSvg} className={"w-7"}/> : 'Save' }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ChangePasswordModalComponent />
            <ModalComponent message={modalMessage} type={modalType}/>
        </>
    )
}
