import trashSvg from "../../images/svg/trash.svg";
import penSvg from "../../images/svg/pen.svg";
import React from "react";
import SelectComponent from "../../components/select.component";
import {useDispatch, useSelector} from "react-redux";
import {setToolsSort} from "../../reducers/tools.reducer";
import ToolTipComponent from "../../components/tool-tip.component";
import {setSettingCurrentYear, setSettingModalDetail} from "../../reducers/setting.reducer";
import {convertDateToCustomFormat} from "../../ui-utils/general-variable";

const DayOffListComponent = () => {
    const dispatch = useDispatch()
    const {currentYear, settingDetail} = useSelector(state => state.settingReducer)
    const {holidays_list} = settingDetail
    const textToolTip = "Tanggal yang akan di lewatkan untuk hitungan mundur waktu pemerosesan pesanan."

    const sortByName = [
        {
            label: 'Terbaru',
            key: 'asc'
        }, {
            label: 'Terlama',
            key: 'desc'
        }
    ]
    const filterByYear = [
        {
            label: '2023',
            key: 2023
        }, {
            label: '2022',
            key: 2022
        }, {
            label: '2021',
            key: 2021
        }, {
            label: '2020',
            key: 2020
        }
    ]

    const openModal = (type, holidayDetail) => {
        dispatch(setSettingModalDetail({visibility: true, type, holidayDetail}))
    }

    const onChangeSelectByName = (sortItem) => {
        dispatch(setToolsSort(sortItem))
    }

    const onChangeFilterByYear = (filterItem) => {
        dispatch(setSettingCurrentYear(filterItem))
    }

    return (
        <>
            <div className={"col border space-y-8 border-white-four pl-6 pr-12 py-6 rounded-md relative"}>
                <div className={"row justify-between items-center"}>
                    <p className={"font-Belleza-Regular text-2xl"}>Order Processing Stop Time in {currentYear}</p>
                    <div className={"row space-x-3"}>
                        <SelectComponent dataList={sortByName} onChange={onChangeSelectByName}/>
                        <SelectComponent type={"filter"} dataList={filterByYear} onChange={onChangeFilterByYear}/>
                    </div>
                </div>
                <div className={"col space-y-4 max-h-[46rem] overflow-scroll"}>
                    {holidays_list.map((list, index) => (
                        <div key={index}
                             className={"row w-full flex-wrap items-center text-base p-4 border border-white-three rounded-lg"}>
                            <div className={"row items-center w-1/3"}>
                                <p className={"font-NunitoSans-SemiBold italic text-grey-green"}>{convertDateToCustomFormat(list.date, 'D MMM YYYY')}</p>
                            </div>
                            <div className={"row items-center justify-between w-2/3"}>
                                <p className={"text-dark-grey pr-2 w-5/6 capitalize"}>{list.note}</p>
                                <div className={"row justify-end space-x-4 w-1/6"}>
                                    <button onClick={() => openModal('delete', list)}>
                                        <img src={trashSvg} alt="trashSvg" className={"w-5"}/>
                                    </button>
                                    <button onClick={() => openModal('edit', list)}>
                                        <img src={penSvg} alt="penSvg" className={"w-5"}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={"absolute right-2 -top-6"}>
                    <ToolTipComponent text={textToolTip} right={true}/>
                </div>
            </div>
        </>
    )
}

export default DayOffListComponent;
