import React from "react";
import TableComponent from "../../components/table.component";
import UserSvg from "../../images/svg/Group 413.svg";
import {useSelector} from "react-redux";
import {doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";

const OrderListComponent = () => {
    const {incompleteOrderList} = useSelector(state => state.servicePerformanceReducer)
    const {loading} = useSelector(state => state.toolsReducer)
    const columns = [{
        name: 'Pesanan',
        dataIndex: 'invoice_number',
        classNameHeader: 'px-5',
        render: (invoice_number, record) => (
            <div className={"row items-center space-x-3 h-20 font-NunitoSans-SemiBold px-5"}>
                <p className={"text-green-grey"}>{invoice_number}</p>
                <img src={UserSvg} className={"w-7"}/>
                <p className={"text-brownish-grey"}>{record.user_fullname}</p>
            </div>
        )
    }, {
        name: 'waktu pemesanan',
        dataIndex: 'paid_at',
        className: "text-brownish-grey font-NunitoSans-SemiBold"
    }, {
        name: 'Alasan Pembatalan',
        dataIndex: 'cancel_reason',
        className: "text-brownish-grey font-NunitoSans-SemiBold"
    }, {
        name: 'Total Pembayaran',
        dataIndex: 'grand_total',
        className: "text-brownish-grey font-NunitoSans-SemiBold text-center",
        render: (grand_total) => doConvertNumberToRupiahFormat(grand_total)
    }]

    return (
        <TableComponent
            columns={columns}
            tableClassName={"font-NunitoSans-Regular"}
            headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
            dataSource={incompleteOrderList}
            pagination={true}
            loading={loading}
        />
    )
}

export default OrderListComponent
