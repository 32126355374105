import {getRequest, postRequest} from "./config";

const updateMaximumOrderProcessingApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`setting/update-processing`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const getSettingDetailApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`setting/detail`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const addHolidayApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`setting/holiday/add`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const updateHolidayApi = (id,requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`setting/holiday/update/${id}`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const deleteHolidayApi = (id) => {
    return new Promise((resolve, reject) => {
        postRequest(`setting/holiday/delete/${id}`)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const getHolidayNoteByDateApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`setting/todays-event`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

export {
    updateMaximumOrderProcessingApi,
    getSettingDetailApi,
    addHolidayApi,
    updateHolidayApi,
    getHolidayNoteByDateApi,
    deleteHolidayApi
}
