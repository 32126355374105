import {useSelector} from "react-redux";
import React from "react";

export default function RefreshAlertModal() {
    const {refreshAlert} = useSelector(state => state.toolsReducer)

    const closeModal = async () => {
        window.location.reload()
    }

    return (
        <div
            className={`row justify-center items-center w-screen h-screen fixed inset-0 z-20 p-0 ${refreshAlert ? 'flex' : 'hidden'}`}>
            <div className={"absolute bg-dark-grey-three/[.65] inset-0"}/>
            <div
                className={"col items-center justify-center bg-white text-center w-1/4 h-1/4 rounded-xl p-10 relative"}>
                <div className={"col space-y-4"}>
                    <p className={"text-xl text-grey-green font-NunitoSans-Bold"}>Terjadi kesalahan. Mohon refresh
                        halaman.</p>
                </div>
                <button
                    onClick={closeModal}
                    className={"items-center mt-10 bg-mid-blue w-32 h-8 text-white text-lg border rounded-lg font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                    Ok
                </button>
            </div>
        </div>
    )
}
