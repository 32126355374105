import React, {useEffect} from "react";
import ExportComponent from "../../components/export.component";
import DeliveryDelayStatisticComponent from "./delivery-delay-statistic.component";
import FilterComponent from "./filter.component";
import TotalDataComponent from "../../components/total-data.component";
import OrderListComponent from "./order-list.component";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsInitialState,
    setToolsLoading,
    setToolsStartDateEndDate,
    setToolsTotalData
} from "../../reducers/tools.reducer";
import {dateRangeToday, formatDateToReadable, generateExportFileName} from "../../ui-utils/general-variable";
import {exportDeliveryDelayApi, getDelayedDeliveryListApi} from "../../api/service-performance.api";
import {
    setServicePerformanceDelayedDeliveryList,
    setServicePerformanceInitialState
} from "../../reducers/service-performance.reducer";

export const DeliveryDelayComponent = () => {
    const dispatch = useDispatch()
    const {
        dateRange,
        currentPage,
        pageSize,
        searchKey,
        orderBy,
        sort,
        expedition
    } = useSelector(state => state.toolsReducer)

    const getValueFromRangePickerSingle = (d) => {
        dispatch(setToolsStartDateEndDate(d))
    }

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setServicePerformanceInitialState())
        }
    }, [])

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            const params = {
                page: currentPage,
                perPage: pageSize,
                orderBy: !!orderBy ? orderBy : 'id',
                orderType: sort,
                filterByExpedition: expedition?.key || null,
                search: searchKey,
                startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
                endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD"),
            }
            dispatch(setToolsLoading(true))
            getDelayedDeliveryListApi(params)
                .then(response => {
                    const result = response?.result
                    dispatch(setServicePerformanceDelayedDeliveryList(result))
                    dispatch(setToolsLoading(false))
                    dispatch(setToolsTotalData(response.total_result))
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    dispatch(setToolsLoading(false))
                    console.log(err)
                })
        }
    }, [currentPage, expedition, dateRange, searchKey, orderBy, sort])

    const exportHandler = () => {
        let requestBody = {
            "order_by": !!orderBy ? orderBy : 'id',
            "order_type": sort,
            "start_date": dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            "end_date": dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            "filter_by_expedition": expedition?.key || null,
            search: searchKey
        }

        exportDeliveryDelayApi(requestBody)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${generateExportFileName('Keterlambatan Pengiriman')}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }

    return (
        <div className={"space-y-5 box-content-style py-5"}>
            <div className={"p-5 space-y-5"}>
                <div className={"row items-center justify-between"}>
                    <div/>
                    <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
                    <ExportComponent exportHandler={exportHandler}/>
                </div>
                <DeliveryDelayStatisticComponent/>
                <FilterComponent/>
            </div>
            <div>
                <TotalDataComponent/>
                <OrderListComponent/>
            </div>
        </div>
    )
}
