import React, {useEffect, useState} from "react";
import SearchIcon from "../images/svg/Group 448.svg"
import {useDispatch, useSelector} from "react-redux";
import {setToolsCurrentPage, setToolsSearchKey} from "../reducers/tools.reducer";

const SearchComponent = (props) => {
    const {placeholder} = props
    const dispatch = useDispatch()
    const {searchKey} = useSelector(state => state.toolsReducer)
    const [searchValue, setSearchValue] = useState(searchKey)

    const doSearch = (value) => {
        dispatch(setToolsSearchKey(value))
        dispatch(setToolsCurrentPage(1))
    }

    const onKeyupHandler = (event) => {
        if(event.key === 'Enter'){
            doSearch(searchValue)
        }
    }

    const onSearch = () => {
        doSearch(searchValue)
    }

    const onChangeHandler = (event) => {
        setSearchValue(event.target.value)
    }

    useEffect(() => {
        setSearchValue(searchKey)
    }, [searchKey])

    return (
        <div className={"row space-x-2 h-8"}>
            <div
                className={`row items-center justify-between bg-light-grey px-2 rounded-full border border-grey-green capitalize shadow-lg h-8 w-32`}>
                <img className={"h-4"} src={SearchIcon} alt={'search'}/>
                <input
                    name={"searchKey"}
                    placeholder={!!placeholder ? placeholder : 'Cari Pesanan'}
                    className={"border-0 outline-0 bg-light-grey px-1 placeholder:italic text-green-sage w-full placeholder:text-grey-green"}
                    value={searchValue}
                    onChange={onChangeHandler}
                    onKeyUp={onKeyupHandler}
                />
            </div>
            <div
                className={"col justify-center rounded-lg text-xs text-white px-2 bg-grey-green hover:bg-green-sage font-semibold cursor-pointer"}
                onClick={onSearch}>
                Search
            </div>
        </div>
    )
}

export default SearchComponent
