import checkSvg from "../images/svg/check-circle.svg";
import xCircleSvg from "../images/svg/x-circle.svg";
import warningSvg from "../images/svg/warning.svg";
import {useDispatch, useSelector} from "react-redux";
import {setToolsModal} from "../reducers/tools.reducer";

const ModalComponent = (props) => {
    const {message, type} = props;
    const {modal} = useSelector(state => state.toolsReducer)
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(setToolsModal(false));
    }

    const getImageByType = () => {
     switch (type) {
         case 'success':
             return checkSvg
         case 'error':
             return xCircleSvg
         case 'warn':
             return warningSvg
     }
    }

    return (
        <div className={`row justify-center items-center w-screen h-screen fixed inset-0 z-20 p-0 ${modal ? 'flex' : 'hidden'}`}>
            <div onClick={closeModal} className={"absolute bg-dark-grey-three/[.65] inset-0"} />
            <div className={"col items-center justify-center bg-white text-center w-1/3 h-1/3 rounded-xl p-10 relative"}>
                <div className={"col space-y-4"}>
                    <img src={getImageByType()} alt={"checkSvg"} className={"animate-bounce w-24 self-center"}/>
                    <p className={"text-xl text-grey-green font-NunitoSans-Bold"}>{message}</p>
                </div>
            </div>
        </div>
    )
}

export default ModalComponent
