import React, {useRef, useState} from 'react'
import InputChangePasswordModalComponent from "./input-change-password-modal.component";
import {checkFieldValidate} from "../../ui-utils/general-variable";
import {updateProfileApi} from "../../api/user.api";
import {
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import LoadingSvg from "../../images/svg/loading-trans.svg";
import ModalCardComponent from "../../components/modal-card.component";

const ChangePasswordModalComponent = () => {
    const dispatch = useDispatch()
    const {modalVisibility} = useSelector(state => state.toolsReducer)
    const [processChangePassword, setProcessChangePassword] = useState(false)
    const oldPassword = useRef(null)
    const newPassword = useRef(null)
    const confirmNewPassword = useRef(null)
    const [fieldList, setFieldList] = useState([
        {
            label: 'Old Password',
            key: 'current_password',
            ref: oldPassword,
            showEyeIcon: false
        },
        {
            label: 'New Password',
            key: 'password',
            ref: newPassword,
            showEyeIcon: false
        },
        {
            label: 'Confirm New Password',
            key: 'password_confirmation',
            ref: confirmNewPassword,
            showEyeIcon: false
        },
    ])

    const closeModal = () => {
        fieldList.map(field => {
            delete field.errorText
            field.ref.current.value = null
            field.ref.current.type = 'password'
            field.showEyeIcon = false
            return field
        })

        dispatch(setToolsModalVisibility(false))
    }

    const showPasswordHandler = (event, key) => {
        event.preventDefault()
        const fieldItemsCopy = [...fieldList];

        fieldItemsCopy.forEach((field) => {
            if (field.key === key) {
                field.showEyeIcon = !field.showEyeIcon;
                field.showEyeIcon ? field.ref.current.type = 'text' : field.ref.current.type = 'password';
            }
        })

        setFieldList(fieldItemsCopy)
    }

    const showMessageModal = (message, type) => {
        setProcessChangePassword(false)
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType(type))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            dispatch(setToolsModal(false))
        }, 1000)
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const fieldItemsCopy = [...fieldList]
        const elements = event.target
        const isConfirmPassword = elements['password'].value === elements['password_confirmation'].value
        const params = {
            current_password: null,
            password: null,
            password_confirmation: null
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements, params, isConfirmPassword)

        if (!isValid) {
            setFieldList(fieldItemsCopy)
        } else {
            let requestBody = {
                old_password:elements['current_password'].value,
                new_password: elements['password'].value
            }

            setProcessChangePassword(true)
            updateProfileApi(requestBody).then(response => {
                localStorage.clear()
                showMessageModal('Password berhasil diubah', 'success')
                window.location.reload()
            }).catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    closeModal()
                    showMessageModal("Terjadi kesalahan!", "error")
                    console.log(err)
                }
            })
        }
    }

    return (
        <ModalCardComponent
            visibility={modalVisibility}
            onClose={closeModal}
            content={
                <div
                    className={`col items-center font-NunitoSans-Regular text-brownish-grey relative w-screen h-screen`}>
                    <div className={"col space-y-5 bg-white w-[35%] h-fit rounded-lg p-5 mt-16"}>
                        <div className={"row justify-end"}>
                            <button type="button" onClick={closeModal}
                                    className={"text-32 text-light-grey-four hover:text-green-grey"}>
                                x
                            </button>
                        </div>
                        <p className={"font-Belleza-Regular font-semibold text-2xl text-center"}>Change Password</p>
                        <form onSubmit={onSubmitHandler}>
                            <div className={"col items-center space-y-5 px-8 pb-3"}>
                                {fieldList.map((field, index) => (
                                    <InputChangePasswordModalComponent key={index} field={field}
                                                                       showPasswordHandler={showPasswordHandler}/>
                                ))}
                                <button type={"submit"} disabled={processChangePassword}
                                        className={"font-NunitoSans-Bold rounded-md py-2.5 px-6 bg-light-green-five text-white hover:bg-green-sage"}>
                                    {processChangePassword ? <img src={LoadingSvg} className={"w-7"}/> : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }/>
    )
}

export default ChangePasswordModalComponent
