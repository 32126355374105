import React, {useEffect} from "react";
import TableComponent from "../../components/table.component";
import {doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";
import {useDispatch, useSelector} from "react-redux";
import {setToolsTotalData} from "../../reducers/tools.reducer";

const CustomerListComponent = () => {
    const dispatch = useDispatch()
    const {customerList} = useSelector(state => state.customerInfoReducer)
    const {loading} = useSelector(state => state.toolsReducer)
    const columns = [
        {
            name: 'Kota/Kab',
            dataIndex: 'shipping_city',
            classNameHeader: 'pl-5',
            className: 'h-20 pl-5 text-brownish-grey'
        }, {
            name: 'Jumlah Pembeli',
            dataIndex: 'customer_count',
            classNameHeader: 'text-center',
            className: 'text-center text-brownish-grey'
        }, {
            name: 'Jml. Pesanan',
            dataIndex: 'order_count',
            classNameHeader: 'text-center',
            className: 'text-center text-brownish-grey'
        }, {
            name: 'Produk Terjual',
            dataIndex: 'product_sold_count',
            classNameHeader: 'text-center',
            className: 'text-center text-brownish-grey'
        }, {
            name: 'Total Pesanan',
            dataIndex: 'grand_total',
            classNameHeader: 'text-center',
            render: (totalOrder) => <p className={"text-center text-brownish-grey"}>{doConvertNumberToRupiahFormat(totalOrder)}</p>
        }, {
            name: 'Nilai Pesanan',
            dataIndex: 'percentage',
            classNameHeader: 'text-center',
            render: (percentage) => <p className={"text-center text-brownish-grey"}>{percentage} %</p>
        },
    ]

    useEffect(() => {
        dispatch(setToolsTotalData(customerList.length))
    }, [])

  return (
      <TableComponent
          columns={columns}
          tableClassName={"font-NunitoSans-Regular"}
          headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
          dataSource={customerList}
          pagination={true}
          loading={loading}
      />
  )
}

export default CustomerListComponent
