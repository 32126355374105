import PrintSvg from "../../images/svg/Group 430.svg";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import CopyToClipboardComponent from "../../components/copyToClipboard.component";
import {
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility
} from "../../reducers/tools.reducer";
import {getExpeditionLogo} from "../../ui-utils/general-variable";
import {previewPrintLabelListApi} from "../../api/order.api";
import {setOrderPreviewPrintLabel} from "../../reducers/order.reducer";
import {useParams} from "react-router-dom";

const OrderDetailCourierComponent = () => {
    const {orderDetail} = useSelector(state => state.orderReducer)
    const dispatch = useDispatch()
    const dataEkspedisi = orderDetail.shipping
    const {id} = useParams()

    const showErrorModal = (message) => {
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType("error"))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            dispatch(setToolsModal(false))
        }, 1000)
    }

    const generateLabel = () => {
        const body = {
            order_id: [id]
        }
        previewPrintLabelListApi(body).then(response => {
            dispatch(setOrderPreviewPrintLabel(response))
            dispatch(setToolsModalVisibility(true))
        }).catch(err => {
            const result = err?.data
            showErrorModal(result?.message || "Terjadi Kesalahan")
        })
    }

    return (
        <div className={"col w-4/12 px-4"}>
            <div className={"col space-y-2 mb-6"}>
                <p className={"font-NunitoSans-Bold"}>Informasi Jasa Kirim</p>
                <div className={"row space-x-3"}>
                    <img src={getExpeditionLogo(dataEkspedisi.method)} alt={dataEkspedisi.method}
                         className={"w-fit h-9 pt-1.5"}/>
                    <div className={"col space-y-1"}>
                        <p>{dataEkspedisi.awb}</p>
                        <p>{dataEkspedisi.method}</p>
                        <p>{dataEkspedisi.origin_destination}</p>
                    </div>
                </div>
            </div>
            <div className={"col space-y-2 font-NunitoSans-SemiBold"}>
                <p>No. Resi</p>
                {!!dataEkspedisi.awb ?
                    <>
                        <p className={"text-lg"}>{dataEkspedisi.awb}</p>
                        <div className={"row"}>
                            <CopyToClipboardComponent text={dataEkspedisi.awb} message={"Resi berhasil di copy"}/>
                            <button className={"row items-center pl-5"} onClick={generateLabel}>
                                <img src={PrintSvg} alt="PrintSvg" className={"w-8"}/>
                                <p className={"text-green-grey"}>Cetak Resi</p>
                            </button>
                        </div>
                    </>
                    : <p className={"font-NunitoSans-SemiBold"}>Resi Not Found</p>}
            </div>
        </div>
    )
}

export default OrderDetailCourierComponent