import ToolTipComponent from "../../components/tool-tip.component";
import React from "react";
import {useSelector} from "react-redux";

const TotalPenaltyPointObtainedTodayCardComponent = () => {
    const {delayedDeliveryStatistic} = useSelector(state => state.servicePerformanceReducer)
    const pointCard = delayedDeliveryStatistic.point_card

    return (
        <div className={"border rounded-md p-5 shadow-lg space-y-3"}>
            <div className={"row items-center justify-between"}>
                <p className={"text-lg font-Belleza-Regular text-brownish-grey"}>Jumlah poin penalti
                    yang didapatkan hari ini</p>
                <ToolTipComponent/>
            </div>
            <p className={"font-Belleza-Regular text-4xl text-dark-sage-two"}>{pointCard.current_penalty}</p>
            <p className={"text-green-grey font-NunitoSans-SemiBold"}>{pointCard.past_penalty}</p>
        </div>
    )
}

export default TotalPenaltyPointObtainedTodayCardComponent