import React, {useEffect, useState} from "react";
import InputComponent from "./input.component";
import {checkFieldValidate} from "../../ui-utils/general-variable";
import {getTargetApi, settingTargetApi} from "../../api/service-performance.api";
import ModalComponent from "../../components/modal.component";
import {setToolsModal} from "../../reducers/tools.reducer";
import {useDispatch} from "react-redux";

export const SettingTargetComponent = () => {
    const dispatch = useDispatch();
    const formData = {
        incomplete_order_target: null,
        packing_time_target: null,
        delayed_delivery_target: null
    }
    const [targetData, setTargetData] = useState(formData)
    const [fieldList, setFieldList] = useState([
        {
            label: 'Tingkat pesanan tidak terselesaikan',
            key: 'incomplete_order_target',
            type: 'number',
            unit: '%'
        },
        {
            label: 'Tingkat keterlambatan pengiriman pesanan',
            key: 'delayed_delivery_target',
            type: 'number',
            unit: '%'
        },
        {
            label: 'Masa pengemasan',
            key: 'packing_time_target',
            type: 'number',
            unit: 'Hari'
        }
    ])

    useEffect(() => {
        getTargetApi()
            .then(response => {
                setTargetData(response)
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }, [])

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const fieldItemsCopy = [...fieldList]
        const elements = event.target
        const isValid = checkFieldValidate(fieldItemsCopy, elements, formData)

        if (!isValid) {
            setFieldList(fieldItemsCopy)
        } else {
            settingTargetApi(formData)
                .then(response => {
                    dispatch(setToolsModal(true));
                    setTimeout(() => {
                        dispatch(setToolsModal(false))
                        window.location.reload()
                    }, 1000)
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    console.log(err)
                })
        }
    }

    return (
        <div className={"col font-NunitoSans-Regular text-brownish-grey text-sm box-content-style"}>
            <form onSubmit={onSubmitHandler}>
                <div className={"col justify-center p-5"}>
                    {fieldList.map((field, index) => (
                        <InputComponent field={field} key={index} data={targetData[field.key]}/>
                    ))}
                    <button type={"submit"}
                            className={"font-NunitoSans-Bold w-fit rounded-lg py-2 px-7 bg-green-grey text-white border border-dark-sage-four hover:bg-green-sage"}>
                        Simpan
                    </button>
                </div>
            </form>
            <ModalComponent message={'Berhasil!'} type={'success'}/>
        </div>
    )
}
