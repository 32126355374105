import React from "react";
import SearchComponent from "../../components/search.component";
import SelectComponent from "../../components/select.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsCurrentPage,
    setToolsOrderBy,
    setToolsSort,
    setToolsStartDateEndDate
} from "../../reducers/tools.reducer";
import FilterExpeditionComponent from "../../components/filter-expedition.component";
import PageLimitComponent from "../../components/page-limit.component";
import ExportComponent from "../../components/export.component";
import ChangeOrderStateComponent from "../../components/change-order-state.component";
import ClearComponent from "../../components/clear.component";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {formatDateToReadable, generateExportFileName} from "../../ui-utils/general-variable";
import {exportOrderApi} from "../../api/order.api";

const OrderFilterComponent = () => {
    const dispatch = useDispatch()
    const {activeTab, selectedRowKeys} = useSelector(state => state.orderReducer)
    const {
        searchKey,
        sort,
        orderBy,
        expedition,
        dateRange
    } = useSelector(state => state.toolsReducer)
    const sortByName = [
        {
            label: 'Nama User A-Z',
            key: 'customer-name_asc'
        }, {
            label: 'Nama User Z-A',
            key: 'customer-name_desc'
        }
    ]

    const onChangeSelectByName = (sortItem) => {
        let text = sortItem.split('_')
        dispatch(setToolsOrderBy(text[0]))
        dispatch(setToolsSort(text[1]))
    }

    const getValueFromRangePickerSingle = (d) => {
        dispatch(setToolsStartDateEndDate(d))
        dispatch(setToolsCurrentPage(1))
    }

    const exportHandler = () => {
        let requestBody = {
            status: activeTab,
            "order_by": !!orderBy ? orderBy : 'id',
            "order_type": sort,
            "start_date": dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            "end_date": dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            "filter_by_expedition": expedition?.key || null,
            search: searchKey,
            order_ids: selectedRowKeys
        }

        exportOrderApi(requestBody)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${generateExportFileName(`Order-${activeTab}`)}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }

    return (
        <div className={"row items-center justify-between"}>
            <div className={"row items-center space-x-5 px-5"}>
                <SearchComponent/>
                <div className={"row items-center space-x-2"}>
                    <SelectComponent dataList={sortByName} onChange={onChangeSelectByName}/>
                    <FilterExpeditionComponent slug={'order-list'}/>
                </div>
                <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
                <div className={"row items-center space-x-2"}>
                    <PageLimitComponent/>
                    <ExportComponent exportHandler={exportHandler}/>
                    <ClearComponent slug={'order'}/>
                </div>
            </div>
            <ChangeOrderStateComponent/>
        </div>
    )
}

export default OrderFilterComponent
