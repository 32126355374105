import React, {useEffect} from "react";
import ProductPerformanceStatisticComponent from "./product-performance-statistic.component";
import ExportComponent from "../../components/export.component";
import ProductPerformanceChartComponent from "./product-performance-chart.component";
import FilterComponent from "./filter.component";
import ProductListComponent from "./product-list.component";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {
    setRefreshAlert,
    setToolsInitialState,
    setToolsLoading,
    setToolsStartDateEndDate,
    setToolsTotalData
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {
    dateRangeToday,
    formatDateToReadable,
    generateExportFileName,
    isUnauthorized
} from "../../ui-utils/general-variable";
import {
    setProductPerformanceInitialState,
    setProductPerformanceProductList
} from "../../reducers/product-performance.reducer";
import {exportProductPerformanceApi, getProductListApi} from "../../api/product-performance.api";
import RefreshAlertModal from "../../components/refresh-alert-modal";

export const ProductPerformanceComponent = () => {
    const dispatch = useDispatch()
    const {
        dateRange,
        currentPage,
        pageSize,
        searchKey,
        orderBy,
        sort
    } = useSelector(state => state.toolsReducer)
    const {categorySelected, selectedRowKeys} = useSelector(state => state.productPerformanceReducer)
    const getValueFromRangePickerSingle = (d) => {
        dispatch(setToolsStartDateEndDate(d))
    }

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setProductPerformanceInitialState())
            dispatch(setToolsStartDateEndDate([null, null]))
        }
    }, [])

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            const params = {
                page: currentPage,
                perPage: pageSize,
                orderBy: !!orderBy ? orderBy : 'id',
                orderType: sort,
                filterByCategory: categorySelected?.id || null,
                search: searchKey,
                startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
                endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
            }
            dispatch(setToolsLoading(true))
            getProductListApi(params)
                .then(response => {
                    const result = response?.result
                    dispatch(setProductPerformanceProductList(result))
                    dispatch(setToolsLoading(false))
                    dispatch(setToolsTotalData(response.total_result))
                })
                .catch(err => {
                    if (!!isUnauthorized(err?.code)) {
                        dispatch(setRefreshAlert(true))
                    } else if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    dispatch(setToolsLoading(false))
                })
        }
    }, [currentPage, pageSize, dateRange, searchKey, orderBy, sort, categorySelected])

    const exportHandler = () => {
        let requestBody = {
            "product_ids": selectedRowKeys,
            "order_by": !!orderBy ? orderBy : 'id',
            "order_type": sort,
            "start_date": dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            "end_date": dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            "filter_by_category": '',
            search: searchKey
        }

        exportProductPerformanceApi(requestBody)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${generateExportFileName('Product Performance')}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                if (!!isUnauthorized(err?.code)) {
                    dispatch(setRefreshAlert(true))
                } else if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
            })
    }

    return (
        <>
            <div className={"space-y-5 box-content-style py-5"}>
                <div className={"space-y-5 px-5"}>
                    <div className={"row items-center justify-between"}>
                        <div/>
                        <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
                        <ExportComponent exportHandler={exportHandler}/>
                    </div>
                    <ProductPerformanceStatisticComponent/>
                    <ProductPerformanceChartComponent/>
                    <FilterComponent/>
                </div>
                <ProductListComponent/>
            </div>
            <RefreshAlertModal/>
        </>
    )
}
