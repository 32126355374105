import React, {useEffect} from "react";
import CustomerInfoStatisticComponent from "./customer-info-statistic.component";
import FilterComponent from "./filter.component";
import TotalDataComponent from "../../components/total-data.component";
import CustomerListComponent from "./customer-list.component";
import {getCustomerInfoListApi} from "../../api/customer-info.api";
import {useDispatch, useSelector} from "react-redux";
import {dateRangeToday, formatDateToReadable, isUnauthorized} from "../../ui-utils/general-variable";
import {
    setRefreshAlert,
    setToolsInitialState,
    setToolsLoading,
    setToolsStartDateEndDate,
    setToolsTotalData
} from "../../reducers/tools.reducer";
import {setCustomerList} from "../../reducers/customer-info.reducer";
import RefreshAlertModal from "../../components/refresh-alert-modal";

export const CustomerInfoComponent = () => {
    const dispatch = useDispatch()
    const {
        currentPage,
        pageSize,
        dateRange,
        orderBy,
        sort,
        expedition,
        searchKey
    } = useSelector(state => state.toolsReducer)

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
        }
    }, [])

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            let params = {
                page: currentPage,
                perPage: pageSize,
                orderBy: !!orderBy ? orderBy : 'city',
                orderType: sort,
                filterByExpedition: expedition?.key || null,
                search: searchKey,
                startDate: formatDateToReadable(dateRange[0], 'YYYY-MM-DD'),
                endDate: formatDateToReadable(dateRange[1], 'YYYY-MM-DD')
            }
            dispatch(setToolsLoading(true))
            getCustomerInfoListApi(params)
                .then(response => {
                    dispatch(setToolsTotalData(response.total_result))
                    dispatch(setToolsLoading(false))
                    dispatch(setCustomerList(response.result))
                })
                .catch(err => {
                    if(!!isUnauthorized(err?.code)){
                        dispatch(setRefreshAlert(true))
                    } else if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    dispatch(setToolsLoading(false))
                })
        }
    }, [dateRange, orderBy, sort, currentPage, pageSize, expedition, searchKey])

    return (
        <>
            <div className={"space-y-5 box-content-style py-5"}>
                <div className={"p-3 space-y-5"}>
                    <CustomerInfoStatisticComponent/>
                    <FilterComponent/>
                </div>
                <div>
                    <TotalDataComponent/>
                    <CustomerListComponent/>
                </div>
            </div>
            <RefreshAlertModal/>
        </>
    )
}
