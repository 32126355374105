import React, {useRef} from "react";
import ModalCardComponent from "../../components/modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import AvoskinLogo from "../../images/png-jpg/avoskin.png"
import TableComponent from "../../components/table.component";
import UserSvg from "../../images/svg/Group 413.svg";
import PrintLabelSvg from "../../images/svg/Group 431.svg";
import ReactToPrint from "react-to-print";
import moment from "moment/moment";
import {moveToDeliveryApi} from "../../api/order.api";
import {
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility
} from "../../reducers/tools.reducer";
import {setManifestReload, setManifestSelectedRowKeys, setManifestSelectedRows} from "../../reducers/manifest.reducer";
import {convertDateToCustomFormat, getExpeditionLogo} from "../../ui-utils/general-variable";

const PrintManifestModal = () => {
    const dispatch = useDispatch()
    const componentRef = useRef();
    const {selectedRows, selectedRowKeys, reload} = useSelector(state => state.manifestReducer)
    const {modalVisibility} = useSelector(state => state.toolsReducer)
    const columns = [
        {
            name: 'Kode Pesanan',
            dataIndex: 'id',
            classNameHeader: 'pl-5',
            className: 'pl-5 h-12',
            render: (id) => <p>AVS-{id}</p>
        }, {
            name: 'Tanggal Pesanan',
            dataIndex: 'created_at',
            render: (created_at) => <p>{convertDateToCustomFormat(created_at, 'DD MMMM YYYY')}</p>
        },
        {
            name: 'Pelanggan',
            className: 'capitalize',
            dataIndex: 'customer_name',
            render: (customer_name) => (
                <div className={"row items-center space-x-3"}>
                    <img src={UserSvg} className={"w-7"}/>
                    <p>{customer_name}</p>
                </div>
            )
        }, {
            name: 'Ekspedisi',
            render: (record) => (
                <div className={"row items-center space-x-3"}>
                    <img src={getExpeditionLogo(record.shipping_service)} className={"h-5 w-16 object-contain"}/>
                    <p>{record.shipping_service}</p>
                </div>
            )
        }, {
            name: 'Kode Ekspedisi',
            dataIndex: 'awb',
        },
    ]

    const moveToDeliveryHandler = () => {
        let requestBody = {
            order_ids: selectedRowKeys
        }
        moveToDeliveryApi(requestBody)
            .then(response => {
                sendAlert('success')
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                sendAlert('error')
                console.log(err)
            })
    }

    const sendAlert = (type) => {
        dispatch(setToolsModalType(type))
        if (type === 'success') {
            dispatch(setToolsModalMessage(`Berhasil Memindah Order ke Surat Jalan`))
        } else {
            dispatch(setToolsModalMessage(`Gagal Memindah Order ke Surat Jalan`))
        }

        dispatch(setManifestSelectedRows([]))
        dispatch(setManifestSelectedRowKeys([]))
        dispatch(setManifestReload(!reload))
        closeModal()
        dispatch(setToolsModal(true))
    }

    const closeModal = () => {
        dispatch(setToolsModalVisibility(false))
    }

    return (
        <ModalCardComponent
            visibility={modalVisibility}
            onClose={closeModal}
            content={
                <div className={"col bg-white rounded-md relative min-h-[500px] w-2/3 p-5 overflow-y-scroll"}>
                    <div className={"row justify-end"}>
                        <button type="button" onClick={closeModal}
                                className={"text-32 text-light-grey-four hover:text-green-grey"}>
                            x
                        </button>
                    </div>
                    <div className={"text-brownish-grey text-13 font-NunitoSans-Bold p-5"} ref={componentRef}>
                        <div className={"col items-center justify-center mb-5"}>
                            <img src={AvoskinLogo} className={"h-14"}/>
                            <p className={"text-lg tracking-[10px]"}>MANIFEST</p>
                        </div>
                        <div className={"col space-y-2"}>
                            <div className={"row items-center space-x-3"}>
                                <p className={"w-28"}>ID Manifest</p>
                                <p>:</p>
                                <p>MNF - {convertDateToCustomFormat(moment(), 'HHmmss')}</p>
                            </div>
                            <div className={"row items-center space-x-3"}>
                                <p className={"w-28"}>Tanggal Manifest</p>
                                <p>:</p>
                                <p>{convertDateToCustomFormat(moment(), 'DD MMMM YYYY')}</p>
                            </div>
                        </div>
                        <div className={"mt-10"}>
                            <TableComponent
                                columns={columns}
                                headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
                                dataSource={selectedRows}
                            />
                        </div>
                        <div className={"grid grid-cols-2 mt-10"}>
                            <div className={"col items-center"}>
                                <p className={"h-32"}>Kurir</p>
                                <p>(.............................................)</p>
                            </div>
                            <div className={"col items-center"}>
                                <p className={"h-32"}>Admin</p>
                                <p>(.............................................)</p>
                            </div>
                        </div>
                    </div>
                    <div className={"w-full row items-center justify-between pt-10"}>
                        <button
                            onClick={moveToDeliveryHandler}
                            className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                            <img src={PrintLabelSvg} className={"w-9"}/>
                            <p>Pindah ke Delivery</p>
                        </button>
                        <ReactToPrint
                            documentTitle={`Avoskin Manifest - ${moment().format('DD-MM-YYYY HH-mm')}`}
                            trigger={() => (
                                <button
                                    className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                                    <img src={PrintLabelSvg} className={"w-9"}/>
                                    <p>Print Manifest</p>
                                </button>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                </div>
            }
        />
    )
}

export default PrintManifestModal
