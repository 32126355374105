import React, {useEffect} from "react";
import PercentageDeliveryDelayOrderCardComponent from "./percentage-delivery-delay-order-card.component";
import TotalPenaltyPointObtainedTodayCardComponent from "./total-penalty-point-obtained-today-card.component";
import DeliveryDelayChartComponent from "./delivery-delay-chart.component";
import {useDispatch, useSelector} from "react-redux";
import {getDelayedDeliveryStatisticApi} from "../../api/service-performance.api";
import {dateRangeToday, formatDateToReadable} from "../../ui-utils/general-variable";
import {setServicePerformanceDelayedDeliveryStatistic} from "../../reducers/service-performance.reducer";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";

const DeliveryDelayStatisticComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            const params = {
                startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
                endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
            }
            getDelayedDeliveryStatisticApi(params)
                .then(response => {
                    dispatch(setServicePerformanceDelayedDeliveryStatistic(response))
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    console.log(err)
                })
        }
    }, [dateRange])
    return (
        <>
            <div className={"grid grid-cols-2 gap-5"}>
                <PercentageDeliveryDelayOrderCardComponent/>
                <TotalPenaltyPointObtainedTodayCardComponent/>
            </div>
            <DeliveryDelayChartComponent/>
        </>
    )
}

export default DeliveryDelayStatisticComponent
