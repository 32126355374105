import TotalDataComponent from '../../components/total-data.component'
import TableComponent from "../../components/table.component"
import DragFileSvg from "../../images/svg/Group 416.svg"
import DownloadSvg from "../../images/svg/Group 419.svg"
import EmptyDataResiSvg from "../../images/svg/Group 417.svg"
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import * as xlsx from "xlsx";
import {importResiApi} from "../../api/order.api";
import ModalComponent from "../../components/modal.component";
import {setToolsModal, setToolsModalMessage, setToolsModalType, setToolsTotalData} from "../../reducers/tools.reducer";

export const ImportResiComponent = () => {
    const dispatch = useDispatch()
    const {modalMessage, modalType} = useSelector(state => state.toolsReducer);
    const [isDrag, setIsDrag] = useState(false);
    const [prevFile, setPrevFile] = useState(false);
    const [extractedFile, setExtractedFile] = useState([])
    const [showFileName, setShowFileName] = useState('');

    const columns = [
        {
            name: 'No.',
            classNameHeader: 'pl-5',
            render: (record, index) => <p className={"p-5"}>{index + 1}</p>
        }, {
            name: 'Nomor Pesanan',
            dataIndex: 'Order ID',
        }, {
            name: 'Nomor Resi',
            dataIndex: 'Resi',
        },
    ]

    const emptyComponent = () => (
        <div className={"col items-center text-dark-sage-two"}>
            <img src={EmptyDataResiSvg} alt="EmptyDataResiSvg" className={"w-72"}/>
            <p>Belum ada data resi yang di impor</p>
        </div>
    )

    const checkFile = (event) => {
        const validExtension = [".xlsx", ".xls"];
        const fileName = event.dataTransfer?.files[0]?.name || event.target.files[0].name;
        const fileSource = event.dataTransfer?.files[0] || event.target.files[0]

        let fileExtension = fileName.substring(fileName.lastIndexOf('.'));

        if (validExtension.indexOf(fileExtension) < 0 && !!fileExtension) {
            alert(`Invalid file selected, valid files are of ${validExtension.toString()} types`);
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, {type: "array"});
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                dispatch(setToolsTotalData(json.length))
                setExtractedFile(json)
            };
            reader.readAsArrayBuffer(fileSource);
            setPrevFile(fileSource);
            setShowFileName(fileName);
        }

        setIsDrag(false);
    }

    const dragAndDropHandler = (event, key) => {
        event.preventDefault();
        switch (key) {
            case 'drop':
                checkFile(event);
                break;
            case 'dragOver':
                setIsDrag(true);
                break;
            case 'dragLeave':
                setIsDrag(false);
                break;
        }
    }

    const onSubmitHandler = () => {
        const form = new FormData();
        form.append('file', prevFile)
        if (!prevFile) {
            return showMessageModal('Pilih File Dulu', 'warn')
        } else {
            importResiHandler(form)
        }
    }

    const importResiHandler = (form) => {
        importResiApi(form)
            .then(response => {
                console.log(response)
                showMessageModal('Import Resi Berhasil', 'success')
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }

    const showMessageModal = (message, type) => {
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType(type))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            if (type === 'success') {
                window.location.reload()
            } else {
                dispatch(setToolsModal(false))
            }
        }, 2000)
    }

    useEffect(() => {
        dispatch(setToolsTotalData(0))
    }, [])

    return (
        <div
            className={"col font-NunitoSans-Regular text-sm px-4 py-5 box-content-style"}>
            <TotalDataComponent/>
            <TableComponent
                columns={columns}
                tableClassName={"font-NunitoSans-Regular"}
                headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
                dataSource={extractedFile}
                emptyComponent={emptyComponent}
            />
            <div className={"col items-center text-dark-sage-two my-10"}>
                <div className={"row flex-wrap w-5/6 bg-light-grey-six p-5 rounded-lg"}>
                    <div className={"row w-5/6 items-center pb-8"}>
                        <div className={"inline-flex relative"}>
                            <label htmlFor="chooseFile"
                                   className={"absolute -top-1 font-NunitoSans-Bold bg-light-grey-six py-1.5 px-5 rounded-lg border border-dark-sage-four hover:bg-green-sage hover:text-white cursor-pointer"}>
                                Pilih File
                            </label>
                            <input name={"chooseFile"} id={"chooseFile"} type={"file"} className={'invisible'}
                                   onChange={checkFile} />
                            <div
                                className={"absolute left-28 top-1 w-40 truncate"}>{showFileName}</div>
                        </div>
                    </div>
                    <div className={"row w-1/6 justify-end items-center pb-8"}>
                        <button type={"submit"} onClick={onSubmitHandler}
                                className={"font-NunitoSans-Bold rounded-lg py-1.5 px-7 bg-green-grey text-white border border-dark-sage-four hover:bg-green-sage"}>
                            Impor Resi
                        </button>
                    </div>
                    <div className={"row w-5/6 justify-center items-center space-x-8"}
                         onDrop={(event) => dragAndDropHandler(event, 'drop')}
                         onDragOver={(event) => dragAndDropHandler(event, 'dragOver')}
                         onDragLeave={(event) => dragAndDropHandler(event, 'dragLeave')}>
                        <label htmlFor="dragFile"
                               className={`inline-flex items-center w-8/12 space-x-4 justify-center ${isDrag && "animate-bounce"}`}>
                            <img src={DragFileSvg} alt="DragFileSvg" className={"w-24"}/>
                            <p className={"italic"}>Ambil file dan tarik ke sini</p>
                        </label>
                    </div>
                    <div className={"row w-1/6 justify-end items-center"}>
                        <Link to={"/files/import-resi-sample.xlsx"} target={"_blank"} type={"button"}
                              className={"inline-flex items-center space-x-1 px-1.5 py-1 border border-transparent hover:border hover:border-dark-sage-four hover:rounded-lg"}
                              download>
                            <img src={DownloadSvg} alt="DownloadSvg" className={"w-8"}/>
                            <p className={"pr-2"}>Unduh File</p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={"row justify-center pt-7"}>
                <TotalDataComponent type={"show-background"}/>
            </div>
            <ModalComponent message={modalMessage} type={modalType}/>
        </div>
    )
}
