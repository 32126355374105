import React, {useEffect} from "react";
import SidebarComponent from "./sidebar.component";
import HeaderComponent from "./header.component";
import {useLocation} from "react-router-dom";
import {authToken, isStaticPage, publicKey, refreshToken} from "../ui-utils/general-variable";
import {refreshTokenApi} from "../api/auth.api";
import bcrypt from 'bcryptjs-react';
import {getProfileDetailApi} from "../api/user.api";
import {useDispatch} from "react-redux";
import {setUserDetail} from "../reducers/user.reducer";

const MainDashboardComponent = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const pathRaw = location.pathname.split('/')
    const {content} = props
    const token = authToken

    useEffect(() => {
        if(!!authToken && !!refreshToken){
            getProfileDetail()
        }
    }, [])

    useEffect(() => {
        if (isStaticPage(location.pathname)) {
            getProfileDetailApi().then().catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
            })
        }
    }, [location.pathname])

    const getProfileDetail = () => {
        getProfileDetailApi()
            .then(response => {
                dispatch(setUserDetail(response))
            })
            .catch(err => {
                if (err.code.includes('401')) {
                    console.log('kesini 401')
                    refreshTokenHandler()
                }
            })
    }

    const refreshTokenHandler = () => {
        let publicKeyHash = bcrypt.hashSync(publicKey, 10);
        let body = {
            grant: publicKeyHash
        }
        refreshTokenApi(body)
            .then(response => {
                localStorage.setItem('AVOSKIN_OPS_TOKEN', response?.access_token)
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
                localStorage.clear()
                window.location.reload()
            })
    }

    const hideSidebar = () => {
        switch (pathRaw[1]) {
            case 'home':
                return true
            case 'order':
                return pathRaw[3] === 'order-detail'
            default:
                return false
        }
    }

    return (
        <div className={`row w-screen h-screen ${pathRaw[1] !== 'under-maintenance' && 'bg-gradient-to-b from-light-grey to-light-green-two'}`}>
            {
                !!token ?
                    <>
                        {!hideSidebar() && <SidebarComponent/>}
                        <div className={"flex-1 max-h-screen overflow-y-auto"}>
                            <div
                                className={`col ${!hideSidebar() ? 'ml-[250px] pr-[20px]' : 'mx-20'} `}>
                                <div
                                    className={"w-full flex-1 flex-row items-center sticky top-0 bg-light-grey z-20"}>
                                    <HeaderComponent/>
                                </div>
                                <div className={"flex-1 w-full h-full mb-5 text-xs 2xl:text-sm"}>
                                    {content}
                                </div>
                            </div>
                        </div>
                    </> :
                    <>
                        {content}
                    </>
            }
        </div>
    )
}

export default MainDashboardComponent
