import eyeIcons from "../../images/svg/eye.svg";
import closedEyeIcons from "../../images/svg/closed-eye.svg";
import React from "react";

const InputChangePasswordModalComponent = (props) => {
    const {field, showPasswordHandler} = props

    return (
        <div className={"col space-y-3 w-full relative"}>
            <label htmlFor={field.key} className={"font-bold"}>{field.label}</label>
            <input id={field.key} type="password" ref={field.ref}
                   className={"border border-light-green-six rounded-sm w-full py-2.5 px-3 focus:outline-light-blue focus:shadow-outline"} required/>
            <button className={"text-xl absolute top-6 right-2"}
                    onClick={(event) => showPasswordHandler(event, field.key)}>
                {field.showEyeIcon ? <img src={eyeIcons} alt="eyeIcons"/> :
                    <img src={closedEyeIcons} alt="closedEyeIcons"/>}
            </button>
            <p className={"font-NunitoSans-SemiBold text-red-600"}>{field.errorText}</p>
        </div>
    )
}

export default InputChangePasswordModalComponent