import React, {useEffect} from "react";
import SearchComponent from "../../components/search.component";
import SelectComponent from "../../components/select.component";
import FilterExpeditionComponent from "../../components/filter-expedition.component";
import ClearComponent from "../../components/clear.component";
import ExportComponent from "../../components/export.component";
import {useDispatch, useSelector} from "react-redux";
import {setToolsOrderBy, setToolsSort, setToolsStartDateEndDate} from "../../reducers/tools.reducer";
import PageLimitComponent from "../../components/page-limit.component";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import moment from "moment/moment";
import {formatDateToReadable, generateExportFileName} from "../../ui-utils/general-variable";
import {exportOrderApi} from "../../api/order.api";
import {exportCustomerApi} from "../../api/customer-info.api";

const FilterComponent = () => {
    const dispatch = useDispatch()
    const {
        searchKey,
        sort,
        orderBy,
        expedition,
        dateRange
    } = useSelector(state => state.toolsReducer)
    const sortByList = [
        {
            label: 'Nama Kota A-Z',
            key: 'city_asc'
        }, {
            label: 'Nama Kota Z-A',
            key: 'city_desc'
        }, {
            label: 'Jumlah Pembeli Terbesar',
            key: 'buyer_asc'
        }, {
            label: 'Jumlah Pembeli Terkecil',
            key: 'buyer_desc'
        }, {
            label: 'Jumlah Biaya Terbesar',
            key: 'cost_desc'
        }, {
            label: 'Jumlah Biaya Terkecil',
            key: 'cost_asc'
        }
    ]

    useEffect(() => {
        const dateRangeToday = [moment().unix(), moment().unix()]
        if(!dateRange[0]){
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        }
    }, [])

    const onChangeSelectByName = (sortItem) => {
        let text = sortItem.split('_')
        dispatch(setToolsOrderBy(text[0]))
        dispatch(setToolsSort(text[1]))
    }

    const getValueFromRangePickerSingle = (d) => {
        dispatch(setToolsStartDateEndDate(d))
    }

    const exportHandler = () => {
        let requestBody = {
            "order_by": !!orderBy ? orderBy : 'id',
            "order_type": sort,
            "start_date": dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            "end_date": dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            "filter_by_expedition": expedition?.key || null,
            search: searchKey
        }

        exportCustomerApi(requestBody)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${generateExportFileName('Customer Info')}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }

    return (
        <div className={"row items-center justify-between"}>
            <div className={"row items-center space-x-3"}>
                <SearchComponent placeholder={'Cari Kota/Kab'}/>
                <SelectComponent dataList={sortByList} onChange={onChangeSelectByName}/>
                <FilterExpeditionComponent slug={'customer-info'}/>
                <PageLimitComponent />
                <ClearComponent/>
                <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
            </div>
            <ExportComponent exportHandler={exportHandler}/>
        </div>
    )
}

export default FilterComponent
