import React from "react";

const InputComponent = ({ field, data }) => {
    return (
        <div className={"col space-y-3 mb-5"}>
            <label htmlFor={field.key} className={"font-NunitoSans-Bold"}>{field.label}</label>
            <div className={"row items-center"}>
                <input id={field.key} type={field.type} defaultValue={data} className={"border border-green-grey rounded-md w-fit py-2 px-3 focus:outline-green-sage focus:shadow-outline"} required/>
                <p className={"row justify-center w-12 py-2 -ml-1.5 rounded-r-md text-white bg-green-grey border border-green-sage"}>
                    {field.unit}
                </p>
            </div>
            <p className={"font-NunitoSans-SemiBold text-red-600"}>{field.errorText}</p>
        </div>
    )
}

export default InputComponent
