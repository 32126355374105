import React from "react";
import TableComponent from "../../components/table.component";
import NotaSvg from "../../images/svg/Group 400.svg"
import ClockSvg from "../../images/svg/Group 401.svg"
import PackingSvg from "../../images/svg/Group 402.svg"
import ArrowRightSvg from "../../images/svg/Group 454.svg";
import {Link} from "react-router-dom";
import ToolTipComponent from "../../components/tool-tip.component";
import {useSelector} from "react-redux";

const StatisticListComponent = () => {
    const {statisticList, loading} = useSelector(state => state.servicePerformanceReducer)

    const columns = [
        {
            name: 'Statistik',
            dataIndex: 'name',
            classNameHeader: 'px-5',
            render: (name, record) =>
                <div className={"row items-center space-x-3 h-20"}>
                    <img src={defineImage(name)} className={"w-10"}/>
                    <p className={"font-NunitoSans-Bold text-dark-sage-two"}>{name}</p>
                    <ToolTipComponent text={record?.helpText}/>
                </div>
        }, {
            name: 'Toko Saya',
            dataIndex: 'current_period',
            classNameHeader: 'text-center',
            render: (current_period, record) => <p className={"text-center"}>{`${current_period} ${record?.unit}`}</p>
        }, {
            name: 'Target',
            dataIndex: 'target',
            classNameHeader: 'text-center',
            className: "text-center",
            render: (target, record) => <p className={"text-center"}>{`< ${target} ${record?.unit}`}</p>
        }, {
            name: 'Periode Sebelumnya',
            dataIndex: 'last_period',
            classNameHeader: 'text-center',
            render: (last_period, record) => <p className={"text-center"}>{`${last_period} ${record?.unit}`}</p>
        }, {
            name: 'Penalti',
            dataIndex: 'penalty',
            render: (now) => <p>{now} Poin Penalti</p>
        }, {
            name: '',
            dataIndex: 'path',
            render: (path) => (
                <Link to={path}
                      className={"row items-center space-x-1 font-NunitoSans-Bold"}>
                    <p className={"text-dark-sage-two"}>Selengkapnya</p>
                    <img src={ArrowRightSvg} className={"h-4"}/>
                </Link>
            )
        },
    ]

    const defineImage = (text) => {
        switch (text) {
            case 'Tingkat pesanan tidak terselesaikan':
                return PackingSvg
            case 'Tingkat keterlambatan pengiriman pesanan':
                return ClockSvg
            case 'Masa Pengemasan':
                return NotaSvg
        }

    }

    return (
        <TableComponent
            columns={columns}
            tableClassName={"font-NunitoSans-Bold text-brownish-grey"}
            headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
            dataSource={statisticList}
            loading={loading}
        />
    )
}

export default StatisticListComponent
