import {useSelector} from "react-redux";

const TotalDataComponent = ({type}) => {
    const {totalData} = useSelector(state => state.toolsReducer)

    const showBackground = () => {
        switch (type) {
            case 'show-background':
                return true
            default:
                return false
        }
    }

    return (
        <div className={`row w-80 italic px-4 ${showBackground() ? 'bg-gradient-to-r from-white via-white-four to-white justify-center py-2' : 'pb-4'}`}>
            <p className={"text-brownish-grey"}>
                Total <span className={"font-NunitoSans-Bold"}>{totalData} Data</span> Pesanan
            </p>
        </div>
    )
}

export default TotalDataComponent
