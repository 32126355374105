import React from "react";
import {useDispatch} from "react-redux";
import {setToolsInitialState} from "../reducers/tools.reducer";
import {setOrderToolsInitialState} from "../reducers/order.reducer";
import {setManifestInitialState} from "../reducers/manifest.reducer";
import {setRequestAwbInitialState} from "../reducers/request-awb.reducer";
import {setServicePerformanceInitialState} from "../reducers/service-performance.reducer";
import {setProductPerformanceInitialState} from "../reducers/product-performance.reducer";

const ClearComponent = ({slug}) => {
    const dispatch = useDispatch()

    const clearButtonHandler = () => {
        dispatch(setToolsInitialState())
        clearBySlug()
    }

    const clearBySlug = () => {
        switch (slug) {
            case 'order':
                return dispatch(setOrderToolsInitialState())
            case 'manifest':
                return dispatch(setManifestInitialState())
            case 'request-awb':
                return dispatch(setRequestAwbInitialState())
            case 'delivery-delay':
                return dispatch(setServicePerformanceInitialState())
            case 'product-performance':
                return dispatch(setProductPerformanceInitialState())
        }
    }

    return (
        <div
            onClick={clearButtonHandler}
            className={"h-8 2xl:px-5 px-2 text-xs 2xl:text-sm border border-pinkish-grey font-NunitoSans-SemiBold col justify-center text-dark-grey rounded shadow cursor-pointer"}>
            Clear
        </div>
    )
}

export default ClearComponent
