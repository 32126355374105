import UserProfileSvg from "../../images/svg/Group 413.svg";
import ClockSvg from "../../images/svg/Group 444.svg";
import React from "react";
import {useSelector} from "react-redux";
import {convertDateToCustomFormat, statusRenderClassName} from "../../ui-utils/general-variable";

const OrderDetailHeaderComponent = () => {
    const {orderDetail} = useSelector(state => state.orderReducer)

    return (
        <div className={"row justify-between items-center pl-4 pr-10 py-2"}>
            <div className={"row items-center space-x-3"}>
                <img src={UserProfileSvg} alt="UserProfileSvg" className={"w-7"}/>
                <p className={"font-NunitoSans-Bold capitalize"}>{orderDetail.user_fullname}</p>
                <p className={`${statusRenderClassName(orderDetail.order.status).className} px-5 rounded-full`}>{statusRenderClassName(orderDetail.order.status).text}</p>
            </div>
            <div className={"row items-center space-x-2"}>
                <img src={ClockSvg} alt="ClockSvg" className={"w-5"}/>
                <p>{convertDateToCustomFormat(orderDetail.order.created_at, 'DD / MM / YYYY HH:mm')}</p>
            </div>
        </div>
    )
}

export default OrderDetailHeaderComponent