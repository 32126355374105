import React, {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import FilterSvg from "../images/svg/Group 437.svg";
import {useDispatch, useSelector} from "react-redux";
import {setToolsCurrentPage, setToolsExpedition, setToolsSort} from "../reducers/tools.reducer";
import JntLogo from "../images/png-jpg/jnt.png"
import JneLogo from "../images/png-jpg/jne.png"
import RpxLogo from "../images/png-jpg/rpx.png"
import PosLogo from "../images/png-jpg/pos.png"
import SicepatLogo from "../images/png-jpg/sicepat.png"
import ArrowDown from "../images/svg/Group 451.svg";

const FilterExpeditionComponent = (props) => {
    const {slug} = props
    const dispatch = useDispatch()
    const {expedition} = useSelector(state => state.toolsReducer)
    const dataList = [
        {
            id: 'all',
            label: 'All Expedition',
            key: '',
            // image: JntLogo
        },
        {
            id: 'jnt',
            label: 'J&T Express',
            key: 'jnt',
            image: JntLogo
        }, {
            id: 'jne',
            label: 'JNE Express',
            key: 'jne',
            image: JneLogo
        }, {
            label: 'Sicepat Express',
            id: 'sicepat',
            key: 'sicepat',
            image: SicepatLogo
        }, {
            label: 'RPX One Stop Logistics',
            key: 'rpx',
            id: 'rpx',
            image: RpxLogo
        }, {
            label: 'POS Indonesia',
            key: 'pos',
            id: 'pos',
            image: PosLogo
        }
    ]

    const isExpeditionFilter = () => {
        switch (slug) {
            case 'import-resi':
            case 'order-list':
            case 'request-awb':
            case 'surat-jalan':
            case 'request-awb':
            case 'uncomplete-order':
            case 'delivery-delay':
            case 'customer-info':
                return true
            default:
                return false

        }
    }

    const onChange = (item) => {
        if (isExpeditionFilter()) {
            dispatch(setToolsExpedition(item))
            dispatch(setToolsCurrentPage(1))
        } else {
            dispatch(setToolsSort(item.key))
        }
    }

    return (
        <Menu as="div" className="relative inline-block h-8">
            {
                slug !== 'import-resi' ?
                    <Menu.Button
                        className="inline-flex w-full justify-center rounded-md border border-pinkish-grey shadow hover:border-grey-green hover:bg-light-grey px-1">
                        <img src={FilterSvg} className={"w-6 2xl:w-8 h-8"}/>
                    </Menu.Button>
                    :
                    <Menu.Button
                        className="row items-center justify-between w-full  border border-grey-green shadow hover:border-grey-green bg-white hover:bg-light-grey h-8 rounded">
                        <p className={"italic text-grey-green w-40 text-left pl-3  capitalize"}>{expedition?.label || 'Pilihan kurir...'}</p>
                        <div className={"h-7 col justify-center p-1 bg-light-grey-two"}>
                            <img src={ArrowDown} className={"w-5"}/>
                        </div>
                    </Menu.Button>
            }
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute -left-24 mt-2 w-72 origin-top-right divide-y text-sm divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 pb-5">
                    <p className={"text-center text-dark-grey font-NunitoSans-SemiBold py-1"}>Filter Ekspedisi</p>
                    <div className={"space-y-2 h-40 overflow-y-auto pt-2"}>
                        {
                            dataList.map((item, index) => (
                                <Menu.Item key={index}>
                                    <div className={"px-3"}>
                                        <div
                                            className={`row items-center hover:bg-light-grey-three space-x-3 rounded p-2 text-dark-grey ${item.id === expedition.id && 'border border-dark-grey-four'}`}>
                                            {item?.image &&
                                            <img src={item.image} className={"w-14 bg-white p-1 rounded"}/>}
                                            <button
                                                onClick={() => onChange(item)}
                                                className={`flex w-full items-center p-2 text-xs font-NunitoSans-SemiBold`}
                                            >{item.label}
                                            </button>
                                        </div>
                                    </div>
                                </Menu.Item>
                            ))
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default FilterExpeditionComponent
