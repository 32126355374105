import InputMaximumOrderProcessingComponent from "./input-maximum-order-processing.component";
import React, {useEffect, useState} from "react";
import {checkFieldValidate} from "../../ui-utils/general-variable";
import ToolTipComponent from "../../components/tool-tip.component";
import {updateMaximumOrderProcessingApi} from "../../api/setting.api";
import {useDispatch, useSelector} from "react-redux";
import {setToolsModal, setToolsModalMessage, setToolsModalType} from "../../reducers/tools.reducer";

const MaximumOrderProcessingComponent = () => {
    const dispatch = useDispatch()
    const [errorFieldText, setErrorFieldText] = useState('')
    const {max_order_processing} = useSelector(state => state.settingReducer.settingDetail)
    const [formData, setFormData] = useState({days: null, hours: null, minutes: null})
    const textToolTip = "Waktu maksimal pemerosesan pesanan yang harus di lakukan. waktu akan terus berjalan kecuali di " +
        "hari sabtu minggu dan juga hari yang di pilih untuk libur pemerosesan pesanan."
    const fieldList = [
        {
            label: 'Days',
            key: 'days',
            type: 'number'
        },
        {
            label: 'Hours',
            key: 'hours',
            type: 'number'
        },
        {
            label: 'Minutes',
            key: 'minutes',
            type: 'number'
        }
    ]

    useEffect(() => {
        let days = Math.floor(max_order_processing / 1440)
        let hours = Math.floor((max_order_processing % 1440) / 60);
        let minutes = max_order_processing % 60;
        setFormData({days, hours,minutes})
    }, [max_order_processing])

    const onSubmitHandler = (event) => {
        event.preventDefault();
        const fieldItemsCopy = [...fieldList]
        const elements = event.target
        const isValid = checkFieldValidate(fieldItemsCopy, elements, formData)

        if (!isValid) {
            setErrorFieldText("Input not valid")
        } else {
            let daysToMinute = Number(formData.days) * 1440
            let hourToMinute = Number(formData.hours) * 60
            let value = daysToMinute + hourToMinute + Number(formData.minutes)
            updateMaximumOrderProcessingApi({value})
                .then(response => {
                    dispatch(setToolsModalMessage('Berhasil mengubah maximum order processing'))
                    dispatch(setToolsModalType('success'))
                    dispatch(setToolsModal(true))
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    console.log(err)
                })
        }
    }

    return (
        <div className={"col border space-y-8 border-white-four p-6 rounded-md relative"}>
            <p className={"font-Belleza-Regular text-2xl"}>Maximum Order Processing</p>
            <div className={"absolute right-2 -top-6"}>
                <ToolTipComponent text={textToolTip} right={true}/>
            </div>
            <form onSubmit={onSubmitHandler}>
                <div className={"row justify-between w-full"}>
                    <div className={"row w-[65%] flex-wrap justify-between"}>
                        {fieldList.map((field, index) => (
                            <InputMaximumOrderProcessingComponent key={index} field={field} data={formData}/>
                        ))}
                    </div>
                    <div className={"col justify-end font-NunitoSans-SemiBold"}>
                        <button type={"submit"}
                                className={"font-NunitoSans-SemiBold rounded-lg py-2 px-7 bg-dark-sage-four text-white border border-dark-sage-four hover:bg-dark-sage-two"}>Update
                        </button>
                    </div>
                </div>
                <p className={"font-NunitoSans-SemiBold text-red-600 pt-3"}>{errorFieldText}</p>
            </form>
        </div>
    )
}

export default MaximumOrderProcessingComponent
