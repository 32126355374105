import React from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import ChartComponent from "../../components/chart.component";
import {useSelector} from "react-redux";

const UncompleteOrderChartComponent = () => {
    const {chart} = useSelector(state => state.servicePerformanceReducer.incompleteOrderStatistic)

    return (
        <div className={"border rounded-md p-5 h-96 w-full"}>
            <div className={"row items-center justify-between"}>
                <div/>
                <p className={"font-Belleza-Regular text-lg"}>{chart?.title}</p>
                <ToolTipComponent right={true}
                                  text={'Persentase pesanan yang tidak di proses pada periode tertentu'}/>
            </div>
            <div className={"w-full h-80"}>
                <ChartComponent type={'line'} data={chart.data} tooltip={"Tingkat Tidak Terselesaikan"} width={600}/>
            </div>
        </div>
    )
}

export default UncompleteOrderChartComponent
