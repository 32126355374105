const InputMaximumOrderProcessingComponent = ({ field, data }) => {
    return (
        <div className={"col space-y-2 w-[30%]"}>
            <label htmlFor={field.key}
                   className={"font-NunitoSans-Bold text-center text-base text-grey-green"}>{field.label}</label>
            <input id={field.key} type={field.type} defaultValue={data[field.key]}
                   className={"border border-white-five text-sm rounded-md italic py-2 font text-center text-grey-green focus:outline focus:outline-grey-green"} required/>
        </div>
    )
}

export default InputMaximumOrderProcessingComponent;
