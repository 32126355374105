import React, {useState} from "react";
import SearchComponent from "../../components/search.component";
import SelectComponent from "../../components/select.component";
import FilterExpeditionComponent from "../../components/filter-expedition.component";
import PageLimitComponent from "../../components/page-limit.component";
import ExportComponent from "../../components/export.component";
import ClearComponent from "../../components/clear.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsCurrentPage,
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility,
    setToolsOrderBy,
    setToolsSort,
    setToolsStartDateEndDate
} from "../../reducers/tools.reducer";
import PrintLabelSvg from "../../images/svg/Group 431.svg";
import {changeBulkOrderStateApi, exportOrderApi, moveToPrintManifestApi} from "../../api/order.api";
import {
    setManifestReload,
    setManifestScanBarcodeModalVisibility,
    setManifestSelectedRowKeys,
    setManifestSelectedRows
} from "../../reducers/manifest.reducer";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {formatDateToReadable, generateExportFileName} from "../../ui-utils/general-variable";

const FilterComponent = () => {
    const dispatch = useDispatch()
    const [disabledButton, setDisabledButton] = useState(false)
    const {activeTab, selectedRowKeys, reload} = useSelector(state => state.manifestReducer)
    const {
        searchKey,
        sort,
        orderBy,
        expedition,
        dateRange
    } = useSelector(state => state.toolsReducer)
    const sortByName = [
        {
            label: 'Nama User A-Z',
            key: 'customer-name_asc'
        }, {
            label: 'Nama User Z-A',
            key: 'customer-name_desc'
        }
    ]

    const onChangeSelectByName = (sortItem) => {
        let text = sortItem.split('_')
        dispatch(setToolsOrderBy(text[0]))
        dispatch(setToolsSort(text[1]))
    }

    const sendAlert = (type, slug) => {
        dispatch(setToolsModalType(type))
        if (type === 'success') {
            dispatch(setToolsModalMessage(slug === 'no-awb' ? 'Berhasil memindah order ke delivery' : 'Pesanan berhasil dipindahkan ke Tab Print Surat Jalan'))
        } else {
            dispatch(setToolsModalMessage(slug === 'no-awb' ? 'Gagal memindah order ke delivery' : 'Pesanan gagal dipindahkan ke Tab Print Surat Jalan'))
        }

        dispatch(setManifestSelectedRowKeys([]))
        dispatch(setManifestSelectedRows([]))
        dispatch(setManifestReload(!reload))
        dispatch(setToolsModal(true))
        setDisabledButton(false)
    }

    const moveToPrintManifest = () => {
        setDisabledButton(true)

        let requestBody = {
            order_ids: selectedRowKeys
        }
        moveToPrintManifestApi(requestBody)
            .then(response => {
                sendAlert('success', 'delivery')
            })
            .catch(err => {
                if (err?.data?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    sendAlert('error', 'delivery')
                    console.log('Error moveToPrintManifestApi', err)
                }
            })
    }

    const changeOrderState = () => {
        setDisabledButton(true)
        let requestBody = {
            order_ids: selectedRowKeys,
            status: 'on-delivery'
        }
        changeBulkOrderStateApi(requestBody)
            .then(response => {
                sendAlert('success', 'no-awb')
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    sendAlert('error', 'no-awb')
                    console.log('Error changeBulkOrderStateApi', err)
                }
            })
    }

    const printManifest = () => {
        dispatch(setToolsModalVisibility(true))
    }

    const scanBarcodeHandler = () => {
        dispatch(setManifestScanBarcodeModalVisibility(true))
    }

    const constructRightButton = () => {
        switch (activeTab) {
            case 'manifest-packing':
                return (
                    <div className={"row items-center space-x-3"}>
                        <button
                            onClick={moveToPrintManifest}
                            disabled={selectedRowKeys.length === 0 || disabledButton}
                            className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                            <img src={PrintLabelSvg} className={"w-9"}/>
                            <p>Pindah ke Surat Jalan</p>
                        </button>
                        <button
                            onClick={scanBarcodeHandler}
                            className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                            <img src={PrintLabelSvg} className={"w-9"}/>
                            <p>Scan Barcode</p>
                        </button>
                    </div>
                )
            case 'print-manifest':
                return (
                    <button
                        onClick={printManifest}
                        disabled={selectedRowKeys.length === 0}
                        className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                        <img src={PrintLabelSvg} className={"w-9"}/>
                        <p>Print Surat Jalan</p>
                    </button>
                )
            case 'without-awb':
                return (
                    <button
                        onClick={changeOrderState}
                        disabled={selectedRowKeys.length === 0}
                        className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                        <img src={PrintLabelSvg} className={"w-9"}/>
                        <p>Pindah ke Delivery</p>
                    </button>
                )
        }
    }

    const getValueFromRangePickerSingle = (d) => {
        dispatch(setToolsStartDateEndDate(d))
        dispatch(setToolsCurrentPage(1))
    }

    const exportHandler = () => {
        let requestBody = {
            status: activeTab,
            order_by: !!orderBy ? orderBy : 'id',
            order_type: sort,
            start_date: dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            end_date: dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            filter_by_expedition: expedition?.key || null,
            search: searchKey,
            order_ids: selectedRowKeys
        }

        exportOrderApi(requestBody)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${generateExportFileName('Order')}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }

    return (
        <div className={"row items-center justify-between px-5"}>
            <div className={"row items-center space-x-2"}>
                <SearchComponent/>
                <div className={"row items-center space-x-2"}>
                    <SelectComponent dataList={sortByName} onChange={onChangeSelectByName}/>
                    <FilterExpeditionComponent slug={'surat-jalan'}/>
                    <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
                </div>
                <div className={"row items-center space-x-2"}>
                    <PageLimitComponent/>
                    <ExportComponent exportHandler={exportHandler}/>
                    <ClearComponent slug={'manifest'}/>
                </div>
            </div>
            {constructRightButton()}
        </div>
    )
}

export default FilterComponent
