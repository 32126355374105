import {Route, Routes} from "react-router-dom";
import Auth from "../pages/auth";
import Orders from "../pages/orders";
import OrderDetail from "../pages/order-detail";
import {PrivateRoute} from "./private-route";
import Notification from "../pages/notification";
import ForgotPassword from "../pages/forgot-password";
import ImportResi from "../pages/import-resi";
import RequestAwb from "../pages/request-awb";
import SettingTarget from "../pages/setting-target";
import Manifest from "../pages/manifest";
import ProfileInfo from "../pages/profile-info";
import ServicePerformance from "../pages/service-performance";
import UncompleteOrders from "../pages/uncomplete-orders";
import DeliveryDelay from "../pages/delivery-delay";
import PackingTime from "../pages/packing-time";
import ProductPerformance from "../pages/product-performance";
import Settings from "../pages/settings";
import CustomerInfo from "../pages/customer-info";
import {authToken} from "../ui-utils/general-variable";
import UnderMaintenance from "../pages/under-maintenance";

export default function RoutePage(){
    const token = authToken
    const routeList = [
        {
            path: 'order',
            component: <Orders/>
        },
        {
            path: 'order/order-list',
            component: <Orders/>
        },
        {
            path: 'order/order-list/order-detail/:id',
            component: <OrderDetail/>
        },
        {
            path: 'order/request-awb/order-detail/:id',
            component: <OrderDetail/>
        },
        {
            path: 'order/request-awb',
            component: <RequestAwb/>
        },
        {
            path: 'order/manifest',
            component: <Manifest/>
        },
        {
            path: 'order/import-resi',
            component: <ImportResi/>
        },
        {
            path: 'home/notification',
            component: <Notification/>
        },
        {
            path: 'home/profile-info',
            component: <ProfileInfo/>
        },
        {
            path: 'home/settings',
            component: <Settings/>
        },
        {
            path: 'service',
            component: <ServicePerformance/>
        },
        {
            path: 'service/setting-target',
            component: <SettingTarget/>
        },
        {
            path: 'service/uncomplete-orders',
            component: <UncompleteOrders/>
        },
        {
            path: 'service/delivery-delay',
            component: <DeliveryDelay/>
        },
        {
            path: 'service/packing-time',
            component: <PackingTime/>
        },
        {
            path: 'service/uncomplete-orders/order-detail/:id',
            component: <OrderDetail/>
        },
        {
            path: 'product',
            component: <ProductPerformance/>
        },
        {
            path: 'customer',
            component: <CustomerInfo/>
        },
    ]

    return(
        <Routes>
            <Route path="/" element={token ? <Orders /> : <Auth />}/>
            <Route key={'/forgot-password'} path={'/forgot-password'} element={<ForgotPassword />}/>
            <Route path={'/under-maintenance'} element={<UnderMaintenance />}/>
            {
                routeList.map((item, index) => (
                    <Route key={index.toString()} path={item.path}
                           element={<PrivateRoute children={item.component} />}/>
                ))
            }
        </Routes>
    )
}
