import React, {useEffect} from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import {useDispatch, useSelector} from "react-redux";
import {getIncompleteStatisticApi} from "../../api/service-performance.api";
import {dateRangeToday, formatDateToReadable} from "../../ui-utils/general-variable";
import {setServicePerformanceIncompleteOrderStatistic} from "../../reducers/service-performance.reducer";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";

const UncompleteOrderStatisticComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {percentage_card, point_card} = useSelector(state => state.servicePerformanceReducer.incompleteOrderStatistic)

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            let params = {
                startDate: formatDateToReadable(dateRange[0], 'YYYY-MM-DD'),
                endDate: formatDateToReadable(dateRange[1], 'YYYY-MM-DD'),
            }
            getIncompleteStatisticApi(params)
                .then(result => {
                    dispatch(setServicePerformanceIncompleteOrderStatistic(result))
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    console.log(err)
                })
        }
    }, [dateRange])

    return (
        <div className={"grid grid-cols-2 gap-5"}>
            <div className={"border rounded-md p-5 shadow-lg space-y-3"}>
                <div className={"row items-center justify-between"}>
                    <p className={"text-lg font-Belleza-Regular text-brownish-grey"}>Persentase pengiriman order yang tidak dilakukan</p>
                    <ToolTipComponent text={'Persentase jumlah pengiriman order yang tidak dilakukan diambil dari jumlah order yang tidak di proses pada hari tersebut dibagi total order pada hari tersebut di kali 100%'}/>
                </div>
                <div className={"row items-center space-x-10"}>
                    <p className={"font-Belleza-Regular text-4xl text-dark-sage-two"}>{percentage_card?.current_percentage} %</p>
                    <div className={"px-3 bg-light-grey border border-light-green-seven text-dark-sage-four py-1 rounded"}>
                        {`Target < ${percentage_card?.target_percentage} %`}
                    </div>
                </div>
                <p className={"text-green-grey font-NunitoSans-SemiBold"}>Kemarin {percentage_card?.past_percentage}% </p>
            </div>
            <div className={"border rounded-md p-5 shadow-lg space-y-3"}>
                <div className={"row items-center justify-between"}>
                    <p className={"text-lg font-Belleza-Regular text-brownish-grey"}>Jumlah poin penalti
                        yang didapatkan hari ini</p>
                    <ToolTipComponent right={true} text={'Jumlah poin pinalti didapatkan dari pesanan yang tidak di proses. Untuk satu order yang tidak di proses dikenakan 1 poin pinalti'}/>
                </div>
                <p className={"font-Belleza-Regular text-4xl text-dark-sage-two"}>{point_card?.current_penalty}</p>
                <p className={"text-green-grey font-NunitoSans-SemiBold"}>Kemarin {point_card?.past_penalty}</p>
            </div>
        </div>
    )
}

export default UncompleteOrderStatisticComponent
