import React, {Fragment, useState} from "react";
import {Menu, Transition} from "@headlessui/react";
import ArrowDown from "../images/svg/Group 451.svg";
import {CANCELLED, COMPLETED, DELIVERY, ON_HOLD, PACKING, PENDING, PROCESSING} from "../models/order.model";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsReload,
    setToolsStatus
} from "../reducers/tools.reducer";
import {changeBulkOrderStateApi, changeOrderStateApi} from "../api/order.api";
import {setOrderSelectedRowKeys} from "../reducers/order.reducer"
import LoadingSvg from "../images/svg/loading-trans.svg";
import {useParams} from "react-router-dom";

const ChangeOrderStateComponent = () => {
    const [processChangeState, setProcessChangeState] = useState(false)
    const dispatch = useDispatch()
    const {status, reload} = useSelector(state => state.toolsReducer)
    const {selectedRowKeys} = useSelector(state => state.orderReducer)
    const {id} = useParams();

    const dataList = [
        {
            label: "Menunggu Pembayaran",
            statusLabel: 'On Hold',
            key: ON_HOLD,
            className: 'bg-white-three text-dark-grey'
        }, {
            label: "Konfirmasi Ketersediaan",
            statusLabel: 'Pending',
            key: PENDING,
            className: 'bg-beige text-caramel',
        }, {
            label: "Sedang Diproses",
            statusLabel: 'Processing',
            key: PROCESSING,
            className: 'bg-light-sage text-dark-sage-two',
        }, {
            label: "Sedang Dikemas",
            statusLabel: 'Packing',
            key: PACKING,
            className: 'bg-light-sage text-dark-sage-two',
        }, {
            label: "Dalam Ekspedisi",
            statusLabel: 'On Delivery',
            key: DELIVERY,
            className: 'bg-light-sage text-dark-sage-two',
        }, {
            label: "Selesai",
            statusLabel: 'completed',
            key: COMPLETED,
            className: 'bg-duck-egg-blue text-mid-blue',
        }, {
            label: "Cancelled",
            statusLabel: 'cancelled',
            key: CANCELLED,
            className: 'bg-beige-two text-brownish-red',
        }
    ]

    const onChange = (item) => {
        dispatch(setToolsStatus(item))
    }

    const showModal = (type) => {
        setProcessChangeState(false)
        if (type === 'success') {
            dispatch(setToolsModalType("success"))
            dispatch(setToolsModalMessage(`Berhasil mengubah status order`))
        } else {
            dispatch(setToolsModalType("error"))
            dispatch(setToolsModalMessage(`Gagal mengubah status order`))
        }
        dispatch(setToolsModal(true))
    }

    const changeStateButtonHandler = () => {
        setProcessChangeState(true);
        if(!!id){
            changeOrderState()
        } else {
            changeOrderStateBulk()
        }
    }

    const changeOrderState = () => {
        let requestBody = {
            status: status?.key
        }

        changeOrderStateApi(id, requestBody)
            .then(response => {
                dispatch(setToolsReload(!reload))
                showModal('success')
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    console.log(err)
                    showModal('error')
                }
            })
    }

    const changeOrderStateBulk = () => {
        let requestBody = {
            order_ids: selectedRowKeys,
            status: status?.key
        }

        changeBulkOrderStateApi(requestBody)
            .then(response => {
                dispatch(setOrderSelectedRowKeys([]))
                dispatch(setToolsReload(!reload))
                showModal('success')
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    console.log(err)
                    showModal('error')
                }
            })
    }

    const disabledButton = () => {
        if (selectedRowKeys.length > 0) {
            return !status?.key
        } else {
            return !status?.key || !id
        }
    }

    return (
        <>
            <div className={"row items-center space-x-5 pr-5 2xl:text-sm text-xs"}>
                <Menu as="div" className="relative inline-block h-8">
                    <Menu.Button
                        className="row items-center justify-between w-full  border border-grey-green shadow hover:border-grey-green hover:bg-light-grey h-8 rounded">
                        <p className={"italic text-grey-green w-40 text-left pl-3  capitalize"}>{status?.label ? status.statusLabel : 'Pilihan status...'}</p>
                        <div className={"h-7 col justify-center p-1 bg-light-grey-two"}>
                            <img src={ArrowDown} className={"w-5"}/>
                        </div>
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="absolute -left-16 mt-4 w-80 origin-top-right text-sm divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <p className={"text-center text-dark-grey font-NunitoSans-Bold py-2"}>Status Pesanan</p>
                            <div className={"space-y-3 mx-2 border-t py-4"}>
                                {
                                    dataList.map((item, index) => (
                                        <Menu.Item key={index}>
                                            <div
                                                onClick={() => onChange(item)}
                                                className={"px-3 space-x-5 hover:border  text-sm font-NunitoSans-Regular cursor-pointer hover:rounded"}>
                                                <div className={"w-full hover:border-pinkish-grey row items-center"}>
                                                    <div
                                                        className={`w-40 h-6 col justify-center items-center rounded-full capitalize ${item.className}`}>{item.statusLabel}</div>
                                                    <button
                                                        className={`flex w-full items-center p-2 text-dark-grey`}
                                                    >{item.label}
                                                    </button>
                                                </div>
                                            </div>
                                        </Menu.Item>
                                    ))
                                }
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
                <button
                    onClick={changeStateButtonHandler}
                    className={"bg-dark-sage-four text-white font-NunitoSans-Bold h-8 px-5 rounded-md shadow-md"}
                    disabled={disabledButton() || processChangeState}>{processChangeState ?
                    <img src={LoadingSvg} className={"w-7"}/> : 'Ubah'}
                </button>
            </div>
        </>
    )
}

export default ChangeOrderStateComponent
