import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setOrderActiveTab, setOrderSelectedRowKeys} from "../../reducers/order.reducer";
import {setToolsCurrentPage, setToolsOrderBy, setToolsSearchKey, setToolsSort} from "../../reducers/tools.reducer";

const OrderTabComponent = () => {
    const {activeTab} = useSelector(state => state.orderReducer)
    const dispatch = useDispatch()

    const tabList = [
        {
            label: 'Semua',
            key: 'all'
        }, {
            label: 'Pending Payment',
            key: 'pending'
        }, {
            label: 'Belum Dibayar',
            key: 'on-hold'
        }, {
            label: 'Perlu Diproses',
            key: 'processing'
        }, {
            label: 'Terlambat',
            key: 'late'
        }, {
            label: 'Dikemas',
            key: 'packing'
        }, {
            label: 'Dikirim',
            key: 'on-delivery'
        }, {
            label: 'Selesai',
            key: 'completed'
        }, {
            label: 'Dibatalkan',
            key: 'cancelled'
        }
    ]

    const onChangeTab = (key) => {
        dispatch(setOrderActiveTab(key))
        dispatch(setToolsCurrentPage(1))
        dispatch(setToolsSearchKey(''))
        dispatch(setToolsSort('desc'))
        dispatch(setToolsOrderBy('id'))
        dispatch(setOrderSelectedRowKeys([]))
    }

    return (
        <div className={"w-full"}>
            <div className={"row items-center space-x-5 text-dark-grey font-NunitoSans-Regular px-7 border-b border-light-grey-four"}>
                {
                    tabList.map((item, index) => (
                        <div key={index} onClick={() => onChangeTab(item.key)} className={`pb-4 ${activeTab === item.key && 'border-b-2  border-b-green-grey'} cursor-pointer`}>
                            <p className={`${activeTab === item.key && 'text-green-grey font-NunitoSans-Bold'}`}>{item.label}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default OrderTabComponent
