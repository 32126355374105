import React, {useEffect} from "react";
import SearchComponent from "../../components/search.component";
import SelectComponent from "../../components/select.component";
import ClearComponent from "../../components/clear.component";
import {useDispatch, useSelector} from "react-redux";
import {setRefreshAlert, setToolsOrderBy, setToolsSort} from "../../reducers/tools.reducer";
import PageLimitComponent from "../../components/page-limit.component";
import {getCategoryList} from "../../api/product-performance.api";
import {
    setProductPerformanceCategoryList,
    setProductPerformanceCategorySelected
} from "../../reducers/product-performance.reducer";
import {isUnauthorized} from "../../ui-utils/general-variable";

const FilterComponent = () => {
    const dispatch = useDispatch()
    const {categoryList} = useSelector(state => state.productPerformanceReducer)
    const sortByList = [
        {
            label: 'Jumlah Product Dipesan Terkecil',
            key: 'order-count_asc'
        }, {
            label: 'Jumlah Product Dipesan Terbesar',
            key: 'order-count_desc'
        }
    ]

    const onChangeSelectByName = (sortItem) => {
        let text = sortItem.split('_')
        dispatch(setToolsOrderBy(text[0]))
        dispatch(setToolsSort(text[1]))
    }

    const onChangeSelectByCategory = (sortItem) => {
        dispatch(setProductPerformanceCategorySelected(sortItem))
    }

    useEffect(() => {
        getCategoryList().then(response => {
            dispatch(setProductPerformanceCategoryList(response))
        }).catch(err => {
            if (!!isUnauthorized(err?.code)) {
                dispatch(setRefreshAlert(true))
            } else if (err?.code === '503-0000') {
                localStorage.clear()
                window.location.href = '/under-maintenance'
            }
        })
    }, [])

    return (
        <div className={"row items-center space-x-5"}>
            <SearchComponent placeholder={'Cari Produk'}/>
            <SelectComponent dataList={sortByList} onChange={onChangeSelectByName}/>
            <SelectComponent type={"filter"} dataList={categoryList} onChange={onChangeSelectByCategory}/>
            <PageLimitComponent/>
            <ClearComponent slug={'product-performance'}/>
        </div>
    )
}

export default FilterComponent
