import ChangeOrderStateComponent from "../../components/change-order-state.component"
import TrackingOrderComponent from "./tracking-order.component"
import PaymentInformationComponent from "./payment-information.component";
import HistoryOrderComponent from "./history-order.component";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {getOrderDetailApi} from "../../api/order.api";
import {setToolsLoading, setToolsModal, setToolsModalMessage, setToolsModalType} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import LoadingSvg from "../../images/svg/loading-trans.svg";
import {setOrderDetail} from "../../reducers/order.reducer";
import OrderDetailHeaderComponent from "./order-detail-header.component";
import OrderDetailCustomerComponent from "./order-detail-customer.component";
import OrderDetailCourierComponent from "./order-detail-courier.component";
import ProductListComponent from "./product-list.component";
import ModalComponent from "../../components/modal.component";
import PrintLabelModal from "../../components/print-label.modal";

export const OrderDetailComponent = () => {
    const {loading, reload, modalMessage, modalType} = useSelector(state => state.toolsReducer)
    const {orderDetail} = useSelector(state => state.orderReducer)
    const dispatch = useDispatch()
    const {id} = useParams()

    const showErrorModal = (message) => {
        dispatch(setToolsLoading(false))
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType("error"))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            dispatch(setToolsModal(false))
        }, 1000)
    }

    useEffect(() => {
        dispatch(setToolsLoading(true))
        getOrderDetailApi(id).then(response => {
            const result = response?.data
            dispatch(setOrderDetail(result))
            dispatch(setToolsLoading(false))
        }).catch(err => {
            if (err?.code === '503-0000') {
                localStorage.clear()
                window.location.href = '/under-maintenance'
            } else {
                showErrorModal(err?.message || "Terjadi Kesalahan")
            }
        })
    }, [reload])

    return (
        <>
            {loading ?
                <div className={"row justify-center w-screen h-screen fixed inset-0 mt-5"}>
                    <img src={LoadingSvg} className={"w-12"}/>
                </div> : (
                    <>
                        {!!orderDetail?.id &&
                            <div className={"row space-x-5 text-sm font-NunitoSans-Regular text-brownish-grey"}>
                                <div className={"col w-3/4 h-fit box-content-style"}>
                                    <OrderDetailHeaderComponent/>
                                    <hr/>
                                    <div className={"row flex-wrap space-y-3 py-3 pl-12 pr-5"}>
                                        <div className={"row w-full justify-end space-x-3 py-1"}>
                                            <ChangeOrderStateComponent/>
                                        </div>
                                        <OrderDetailCustomerComponent/>
                                        <OrderDetailCourierComponent/>
                                        <div className={"w-5/12 pr-5"}>
                                            <TrackingOrderComponent/>
                                        </div>
                                    </div>
                                    <hr className={"mx-10 my-3"}/>
                                    <div className={"col space-y-5 items-center text-brownish-grey px-14 py-2"}>
                                        <ProductListComponent/>
                                        <hr className={"w-full"}/>
                                        <PaymentInformationComponent/>
                                    </div>
                                </div>
                                <div className={"w-1/4"}>
                                    <HistoryOrderComponent/>
                                </div>
                            </div>}
                    </>
                )
            }
            <ModalComponent message={modalMessage} type={modalType}/>
            <PrintLabelModal/>
        </>
    )
}
