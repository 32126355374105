import React from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import {useSelector} from "react-redux";

const PackingTimeStatisticComponent = () => {
    const {average_card, penalty_card} = useSelector(state => state.servicePerformanceReducer.packingTimeStatistic)
    return (
        <div className={"grid grid-cols-2 gap-5"}>
            <div className={"border rounded-md p-5 shadow-lg space-y-3"}>
                <div className={"row items-center justify-between"}>
                    <p className={"text-lg font-Belleza-Regular text-brownish-grey"}>Total rata-rata pengemasan hari
                        ini</p>
                    <ToolTipComponent/>
                </div>
                <div className={"row items-center space-x-10"}>
                    <p className={"font-Belleza-Regular text-4xl text-dark-sage-two"}>{average_card?.current_average} Hari</p>
                    <div
                        className={"px-3 bg-light-grey border border-light-green-seven text-dark-sage-four py-1 rounded"}>
                        {`Target < ${average_card?.target_average} Hari`}
                    </div>
                </div>
                <p className={"text-green-grey font-NunitoSans-SemiBold"}>Kemarin {average_card?.past_average} Hari </p>
            </div>
            <div className={"border rounded-md p-5 shadow-lg space-y-3"}>
                <div className={"row items-center justify-between"}>
                    <p className={"text-lg font-Belleza-Regular text-brownish-grey"}>Jumlah poin penalti
                        yang didapatkan hari ini</p>
                    <ToolTipComponent/>
                </div>
                <p className={"font-Belleza-Regular text-4xl text-dark-sage-two"}>{penalty_card?.current_penalty}</p>
                <p className={"text-green-grey font-NunitoSans-SemiBold"}>Kemarin {penalty_card?.past_penalty}</p>
            </div>
        </div>
    )
}

export default PackingTimeStatisticComponent
