import React from "react";
import {Link, useLocation} from "react-router-dom";
import RightChevron from "../images/svg/Group 452.svg"
import AvoskinLogo from "../images/svg/Group 292.svg";

const BreadcrumbComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const breadCrumbText = {
        '': 'Order',
        'home': 'Home',
        'notification': 'Notification',
        'settings': 'Settings',
        'order': 'Order',
        'order-list': 'Order List',
        'order-detail': 'Order Detail',
        'request-awb': 'Request AWB',
        'import-resi': 'Import Resi',
        'manifest': 'Surat Jalan',
        'service': 'Service Performance',
        'product': 'Product Performance',
        'setting-target': 'Setting Target',
        'profile-info': 'Profile Info',
        'uncomplete-orders': 'Tingkat Pesanan Tidak Terselesaikan',
        'delivery-delay': 'Tingkat Keterlambatan Pengiriman Pesanan',
        'packing-time': 'Masa Pengemasan',
        'customer': 'Customer Info'
    }

    const hideSidebar = () => {
        switch (pathRaw[1]) {
            case 'home':
                return true
            case 'order':
                return pathRaw[3] === 'order-detail'
            default:
                return false
        }
    }

    return (
        <div className={"row items-center font-Belleza-Regular text-green-grey space-x-3"}>
            {hideSidebar() && <img src={AvoskinLogo} className={"w-28 pb-3 mr-3"}/>}
            <Link to={`/${pathRaw[1] === 'home' ? '' : pathRaw[1]}`}>
                <p className={"text-32"}>{breadCrumbText[pathRaw[1]]}</p>
            </Link>
            {!!pathRaw[2] && <img src={RightChevron} className={"h-6"}/>}
            <Link to={`/${pathRaw[1]}/${pathRaw[2]}`}>
                <p className={"text-[20px]"}>{breadCrumbText[pathRaw[2]]}</p>
            </Link>
            {pathRaw[3] && <img src={RightChevron} className={"h-6"}/>}
            <p className={"text-[20px]"}>{breadCrumbText[pathRaw[3]]}</p>
        </div>
    )
}

export default BreadcrumbComponent
