import MaximumOrderProcessingComponent from "./maximum-order-processing.component";
import DetailDateComponent from "./detail-date.component";
import DayOffListComponent from "./day-off-list.component";
import ModalComponent from "../../components/modal.component";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSettingDetailApi} from "../../api/setting.api";
import {setSettingDetail} from "../../reducers/setting.reducer";
import DayOffModalComponent from "./day-off-modal.component";

export const SettingsComponent = () => {
    const dispatch = useDispatch()
    const {modalMessage, modalType, sort, reload} = useSelector(state => state.toolsReducer)
    const {currentYear} = useSelector(state => state.settingReducer)

    useEffect(() => {
        let params = {
            filterByYear: currentYear,
            orderBy: 'date',
            orderType: sort
        }
        getSettingDetailApi(params)
            .then(response => {
                dispatch(setSettingDetail(response))
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }, [currentYear, sort, reload])

    return (
        <>
            <div className={"row space-x-8 font-NunitoSans-Regular text-brownish-grey text-sm p-10 box-content-style"}>
                <div className={"col space-y-4 w-[35%]"}>
                    <MaximumOrderProcessingComponent/>
                    <DetailDateComponent/>
                </div>
                <div className={"col w-[65%]"}>
                    <DayOffListComponent/>
                </div>
            </div>
            <ModalComponent message={modalMessage} type={modalType}/>
            <DayOffModalComponent />
        </>
    )
}
