import React, {useEffect, useState} from "react";
import AvoskinLogo from "../images/svg/Group 292.svg"
import OrderLogo from "../images/svg/Group 407.svg"
import OrderLogoGreen from "../images/svg/Group 403.svg"
import ServiceLogo from "../images/svg/Group 408.svg"
import ServiceLogoGreen from "../images/svg/Group 404.svg"
import ProductLogo from "../images/svg/Group 409.svg"
import CustomerLogo from "../images/svg/Group 410.svg"
import ProductLogoGreen from "../images/svg/Group 405.svg"
import CustomerLogoGreen from "../images/svg/Group 406.svg"
import {Link, useLocation} from "react-router-dom";

const SidebarComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const [activeSidebar, setActiveSidebar] = useState('')
    const [activeChild, setActiveChild] = useState('')
    const [activeDropdown, setActiveDropDown] = useState(false)
    const sideBarList = [
        {
            label: 'Order',
            key: 'order',
            activeLogo: OrderLogo,
            logo: OrderLogoGreen,
            child: [
                {
                    key: 'order-list',
                    label: 'Order List'
                }, {
                    key: 'request-awb',
                    label: 'Request AWB'
                }, {
                    key: 'import-resi',
                    label: 'Import Resi'
                }, {
                    key: 'manifest',
                    label: 'Surat Jalan'
                }
            ]
        }, {
            label: 'Service Performance',
            key: 'service',
            activeLogo: ServiceLogo,
            logo: ServiceLogoGreen
        }, {
            label: 'Product Performance',
            key: 'product',
            activeLogo: ProductLogo,
            logo: ProductLogoGreen
        }, {
            label: 'Customer Info',
            key: 'customer',
            activeLogo: CustomerLogo,
            logo: CustomerLogoGreen
        }
    ]

    useEffect(() => {
        setActiveSidebar(pathRaw[1])
        setActiveChild(pathRaw[2])
    }, [])

    const sidebarButtonHandler = (key) => {
        if(key !== 'order'){
            setActiveChild('')
            setActiveDropDown(false)
        } else {
            setActiveDropDown(!activeDropdown)
        }
        setActiveSidebar(key)
    }

    const sidebarChildButtonHandler = (key) => {
        setActiveChild(key)
    }

    return (
        <div className={"fixed h-full py-20 col items-center"}>
            <img src={AvoskinLogo}/>
            <div className={"col mt-10 w-full px-7 space-y-5 font-NunitoSans-Bold text-sm"}>
                {
                    sideBarList.map((item, index) => (
                        <div key={index}>
                            <Link to={`/${item.key}`}
                                  className={`w-52 ${activeSidebar === item.key && 'bg-light-grey-two rounded-10'}`}
                                  onClick={() => sidebarButtonHandler(item.key)}
                            >
                                <div
                                    className={`row items-center space-x-3 h-12 px-4 cursor-pointer ${activeSidebar === item.key ? 'bg-green-grey rounded-10 text-white' : 'text-green-sage'}`}>
                                    <img src={activeSidebar === item.key ? item.activeLogo : item.logo}
                                         className={"w-6"}/>
                                    <p>{item.label}</p>
                                </div>
                            </Link>
                            {
                                activeDropdown && activeSidebar === item.key && !!item.child &&
                                <div className={"col pt-3 bg-light-grey-two"}>
                                    {
                                        item.child.map((i, idx) => (
                                            <Link to={`/${item.key}/${i.key}`} key={idx + index}
                                                  className={"w-full h-12 text-dark-sage-two px-5 cursor-pointer"}
                                                  onClick={() => sidebarChildButtonHandler(i.key)}>
                                                <div
                                                    className={`row h-11 items-center justify-center rounded-lg hover:bg-green-grey-two hover:text-white ${activeChild === i.key && 'border border-dark-sage-four'}`}>
                                                    <p>{i.label}</p>
                                                </div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SidebarComponent
