import ArrowDownSvg from "../../images/svg/arrow-small-down.svg"
import ArrowUpSvg from "../../images/svg/arrow-small-up.svg"
import {useState} from "react";
import {useSelector} from "react-redux";
import {convertDateToCustomFormat} from "../../ui-utils/general-variable";

const TrackingOrderComponent = () => {
    const {orderDetail} = useSelector(state => state.orderReducer)
    const [showListTrackOrder, setShowListTrackOrder] = useState(false)
    const trackingOrderList = orderDetail?.trackings ?? []

    const toggleButtonListTrackOrder = () => {
        setShowListTrackOrder(!showListTrackOrder)
    }

    return (
        <div className={"rounded-md bg-light-sage-two text-brownish-grey text-sm py-5 px-6"}>
            {trackingOrderList.length > 0 ?
                <>
                    <div className={showListTrackOrder ? 'h-fit' : 'max-h-[19rem] overflow-hidden'}>
                        {trackingOrderList.map((trackingOrder, index) => (
                            <div key={index} className={"row items-start space-x-5 mb-2"}>
                                <div className={"col space-y-2 items-center"}>
                                    <div
                                        className={`border-2 p-1 rounded-full ${index === 0 ? 'border-dark-sage-five bg-light-green-two' : 'border-light-sage-two bg-light-sage-two'}`}>
                                        <div
                                            className={`p-[5px] rounded-full ${index === 0 ? 'bg-dark-sage-five' : 'bg-light-grey-three'}`}></div>
                                    </div>
                                    {trackingOrderList.length - 1 !== index && (
                                        <div
                                            className={`w-[3px] h-10 ${index === 0 ? 'bg-grey-green-two' : 'bg-light-grey-three'}`}></div>
                                    )}
                                </div>
                                <div className={`col space-y-1 ${index !== 0 && 'text-greyish'}`}>
                                    <p className={"font-NunitoSans-Bold"}>{trackingOrder.description}</p>
                                    <p className={"max-h-16 overflow-scroll"}>{convertDateToCustomFormat(trackingOrder.timestamp, 'DD/MM/YYYY HH:mm')}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className={"row cursor-pointer items-center space-x-1 justify-end font-NunitoSans-Bold text-dark-sage-six"}
                        onClick={toggleButtonListTrackOrder}>
                        <p>{showListTrackOrder ? 'Sembunyikan' : 'Selengkapnya'}</p>
                        <img src={showListTrackOrder ? ArrowUpSvg : ArrowDownSvg} alt="ArrowRightSvg"
                             className={"w-5 pt-0.5"}/>
                    </div>
                </>
                : <p className={"font-NunitoSans-SemiBold"}>Tracking order not found</p>}
        </div>
    )
}

export default TrackingOrderComponent
