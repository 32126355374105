import React, {useEffect} from "react";
import TableComponent from "../../components/table.component";
import {doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";
import {useDispatch, useSelector} from "react-redux";
import {setProductPerformanceSelectedRowKeys} from "../../reducers/product-performance.reducer";

const ProductListComponent = () => {
    const dispatch = useDispatch()
    const {selectedRowKeys, productList} = useSelector(state => state.productPerformanceReducer)
    const {loading} = useSelector(state => state.toolsReducer)
    const columns = [
        {
            type: 'checkbox',
        }, {
            name: 'Nama Produk',
            dataIndex: 'name',
            classNameHeader: 'text-center px-5',
            render: (name, record) => (
                <div className={"h-20 row items-center space-x-3 pl-5 text-dark-sage-two"}>
                    <img src={record.image_url} className={"w-7"}/>
                    <p>{name}</p>
                </div>
            )
        }, {
            name: 'Produk Terjual',
            dataIndex: 'order_completed',
            classNameHeader: 'text-center',
            className: 'text-brownish-grey text-center'
        }, {
            name: 'Produk Dibayar',
            dataIndex: 'order_processing',
            classNameHeader: 'text-center',
            render: (order_processing) => <p className={"text-brownish-grey text-center"}>{order_processing}</p>
        }, {
            name: 'Produk Siap Dikirim',
            dataIndex: 'order_packing',
            classNameHeader: 'text-center',
            className: 'text-brownish-grey text-center'
        }, {
            name: 'Total Pesanan',
            dataIndex: 'order_all',
            classNameHeader: 'text-center',
            render: (order_all) => <p className={'text-center'}>{order_all}</p>
        }, {
            name: 'Penjualan',
            dataIndex: 'sum_completed_order',
            classNameHeader: 'text-center',
            className: 'text-brownish-grey text-center',
            render: (sum_completed_order) => <p
                className={"text-mid-blue text-center"}>{doConvertNumberToRupiahFormat(sum_completed_order)}</p>
        }, {
            name: 'Sisa Stock',
            dataIndex: 'stock_quantity',
            classNameHeader: 'text-center',
            className: 'text-clay text-center'
        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setProductPerformanceSelectedRowKeys(array))
    }

    return (
        <TableComponent
            columns={columns}
            tableClassName={"font-NunitoSans-SemiBold"}
            headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
            selectedRowKeys={selectedRowKeys}
            onChangeSelectedRowKeys={onChangeSelectedRowKeys}
            dataSource={productList}
            pagination={true}
            loading={loading}
        />
    )
}

export default ProductListComponent
