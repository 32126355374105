import React from "react";

const InputProfileInfoComponent = (props) => {
    const {field, openModal, data} = props;

    return (
        <div className={"col space-y-3"}>
            <label htmlFor={field.key} className={"font-NunitoSans-Bold"}>{field.label} {field.type !== 'radio' ? '*' : ''}</label>
            {field.type !== 'radio' ? (
                <div className={"row space-x-8"}>
                    <input id={field.key} type={field.type}
                           className={`border border-greyish rounded-md py-2 px-3 focus:border focus:border-black focus:outline-offset-2 focus:outline-light-green-seven ${field.key === 'email' ? 'w-4/6' : 'w-full'}`}
                           defaultValue={data[field.key]}
                           required/>
                    {field.key === 'email' ? (
                        <button onClick={openModal}
                                className={"text-green-sage font-NunitoSans-Bold w-2/6 py-2 bg-beige-three border border-green-sage rounded-md hover:bg-green-sage hover:text-white"}>
                            Change Password
                        </button>
                    ) : null}
                </div>
            ) : (
                <div className={"col space-y-2"}>
                    <div className={"row space-x-5"}>
                        {field.listRadio.map((item, index) => (
                            <div className={"row space-x-3"} key={index}>
                                <input id={item.key} className={`accent-green-sage ${item.inputClassName}`}
                                       type="radio" name="gender" value={item.value} defaultChecked={data[field.key] === field.value}/>
                                <label htmlFor={item.key}
                                       className={item.labelClassName}>{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <p className={"font-NunitoSans-SemiBold text-red-600"}>{field.errorText}</p>
        </div>
    )
}

export default InputProfileInfoComponent
