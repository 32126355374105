import React from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import ChartComponent from "../../components/chart.component";
import {useSelector} from "react-redux";

const PackingTimeChartComponent = () => {
    const {chart} = useSelector(state => state.servicePerformanceReducer.packingTimeStatistic)

    return (
        <div className={"border rounded-md p-5 space-y-5"}>
            <div className={"row items-center justify-between"}>
                <div/>
                <p className={"font-Belleza-Regular text-lg"}>{chart?.title}</p>
                <ToolTipComponent/>
            </div>
            <div className={"w-full h-96"}>
                <ChartComponent type={'line'} data={chart?.data} tooltip={"Masa Pengemasan"}/>
            </div>
        </div>
    )
}

export default PackingTimeChartComponent
