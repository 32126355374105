import {getRequest, postRequest} from "./config";

const updateProfileApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`profile/update`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const updatePasswordApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`profile/update`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response))
    })
}

const getProfileDetailApi = () => {
    return new Promise((resolve, reject) => {
        getRequest(`profile/detail`)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

export {
    updateProfileApi,
    updatePasswordApi,
    getProfileDetailApi
}
