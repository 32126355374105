import {loginRequest, logoutAndRefreshRequest, postRequest, publicPostRequest} from "./config";

const loginApi = (body, token) => {
    return new Promise((resolve, reject) => {
        loginRequest(`auth/login/`, body, token)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const verifyLogin = (body, token) => {
    return new Promise((resolve, reject) => {
        postRequest(`auth/verify-login/`, body, token)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

const generatePublicTokenApi = (body) => {
    return new Promise((resolve, reject) => {
        postRequest(`auth/client-token`, body)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const logoutApi = () => {
    return new Promise((resolve, reject) => {
        logoutAndRefreshRequest(`auth/logout`)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const refreshTokenApi = (body) => {
    return new Promise((resolve, reject) => {
        logoutAndRefreshRequest(`auth/refresh-token`, body)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

const forgotPasswordApi = (body, token) => {
    return new Promise((resolve, reject) => {
        publicPostRequest(`auth/reset-password`, body, token)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

export {
    loginApi,
    verifyLogin,
    generatePublicTokenApi,
    logoutApi,
    refreshTokenApi,
    forgotPasswordApi
}
