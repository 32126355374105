import React from "react";
import ExportSvg from "../images/svg/Group 439.svg"

const ExportComponent = ({exportHandler}) => {
    return (
        <button
            className={"row items-center xl:text-11 2xl:text-sm text-dark-grey border h-8 pr-2 rounded border-pinkish-grey shadow hover:border-grey-green hover:bg-light-grey cursor-pointer"}
            onClick={exportHandler}>
            <img src={ExportSvg} className={"w-8 h-8"}/>
            <p>Export</p>
        </button>
    )
}

export default ExportComponent
