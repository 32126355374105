import ArrowDown from "../images/svg/Group 451.svg";
import React, {useState} from "react";

const FilterCalendarComponent = (props) => {
    const {dataList, value, onChange} = props
    const [showList, setShowList] = useState(false)

    const showListHandler = () => {
        setShowList(!showList)
    }

    const onClickHandler = (value) => {
        onChange(value)
        setShowList(false)
    }

    return (
        <div className={"relative w-[8.5rem] h-full text-lg"}>
            <div className={"w-full h-8 text-green-sage border border-green-sage rounded-md overflow-hidden"}>
                <div className={"row justify-between items-center h-full pl-2 cursor-pointer"} onClick={showListHandler}>
                    <p>{value}</p>
                    <div className={"col h-full justify-center p-1 bg-beige-three"}>
                        <img src={ArrowDown} className={"w-5"}/>
                    </div>
                </div>
            </div>
            {showList &&
                <div className={"absolute top-10 bg-white w-full h-36 overflow-scroll px-1 py-2 rounded-md shadow-lg"}>
                    {dataList.map((list, index) => (
                        <div key={index} onClick={() => onClickHandler(list)}
                            className={"cursor-pointer text-center rounded-md px-2 border-none hover:border hover:border-green-sage hover:bg-light-grey-three hover:text-white"}>
                            <p>{list}</p>
                        </div>
                    ))}
                </div>}
        </div>
    )
}

export default FilterCalendarComponent