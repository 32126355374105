import React from "react";
import TableComponent from "../../components/table.component";
import UserSvg from "../../images/svg/Group 413.svg";
import {Link} from "react-router-dom";
import ArrowRightSvg from "../../images/svg/Group 454.svg";
import {useDispatch, useSelector} from "react-redux";
import {setServicePerformanceSelectedRowKeys} from "../../reducers/service-performance.reducer";
import {convertDateToCustomFormat, doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";

const OrderListComponent = () => {
    const dispatch = useDispatch()
    const {selectedRowKeys, delayedDeliveryList} = useSelector(state => state.servicePerformanceReducer)
    const {loading} = useSelector(state => state.toolsReducer)

    const columns = [
        {
            type: 'checkbox',
        }, {
            name: 'Pesanan',
            classNameHeader: 'px-5 text-center',
            render: (record) => (
                <div className={"row items-center space-x-3 h-20 font-NunitoSans-SemiBold"}>
                    <p className={"text-green-grey w-20"}>{record.invoice_number}</p>
                    <img src={UserSvg} className={"w-7"}/>
                    <p className={"text-brownish-grey w-24"}>{record.user_fullname}</p>
                </div>
            )
        }, {
            name: 'Batas Pengiriman',
            dataIndex: 'order_expired_at',
            classNameHeader: 'text-center',
            className: "text-brownish-grey font-NunitoSans-SemiBold",
            render: (order_expired_at) => (
                <p className={"text-center px-5"}>
                    {!!order_expired_at && convertDateToCustomFormat(order_expired_at, "DD MMMM YYYY HH:mm")}
                </p>
            )
        }, {
            name: 'Dikirim Pada',
            dataIndex: 'on_delivery_at',
            classNameHeader: 'text-center',
            className: "text-brownish-grey font-NunitoSans-SemiBold",
            render: (on_delivery_at) => (
                <p className={"text-center px-5"}>
                    {!!on_delivery_at && convertDateToCustomFormat(on_delivery_at, "DD MMMM YYYY HH:mm")}
                </p>
            )
        }, {
            name: 'Jumlah Hari Terlambat',
            dataIndex: 'days_late',
            classNameHeader: 'text-center',
            className: "text-brownish-grey font-NunitoSans-SemiBold text-center",
            render: (days_late) => (
                <p className={"text-center px-5"}>{days_late} Hari</p>
            )
        }, {
            name: 'Waktu Pembayaran',
            dataIndex: 'paid_at',
            classNameHeader: 'text-center',
            className: "text-brownish-grey font-NunitoSans-SemiBold",
            render: (paid_at) => (
                <p className={"text-center px-5"}>
                    {!!paid_at && convertDateToCustomFormat(paid_at, "DD MMMM YYYY HH:mm")}
                </p>
            )
        }, {
            name: 'Total Order',
            dataIndex: 'grand_total',
            classNameHeader: 'text-center',
            className: "text-brownish-grey font-NunitoSans-SemiBold",
            render: (grand_total) => (
                <p className={"text-center px-5"}>{doConvertNumberToRupiahFormat(grand_total)}</p>
            )
        }, {
            name: '',
            dataIndex: 'id',
            render: (id) => (
                <Link to={`/order/order-list/order-detail/${id}`}
                      className={"row items-center space-x-1 font-NunitoSans-Bold px-5"}>
                    <p className={"text-dark-sage-two"}>Selengkapnya</p>
                    <img src={ArrowRightSvg} className={"h-4"}/>
                </Link>
            )
        }]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setServicePerformanceSelectedRowKeys(array))
    }

    return (
        <TableComponent
            columns={columns}
            tableClassName={"font-NunitoSans-Regular"}
            headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
            selectedRowKeys={selectedRowKeys}
            onChangeSelectedRowKeys={onChangeSelectedRowKeys}
            dataSource={delayedDeliveryList}
            pagination={true}
            loading={loading}
        />
    )
}

export default OrderListComponent
