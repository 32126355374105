import React, {useState} from "react";
import {checkFieldValidate, publicKey} from "../../ui-utils/general-variable";
import avoMO from "../../images/png-jpg/avo-mo.png";
import {Link} from "react-router-dom";
import loginBubble from "../../images/svg/login-bubble.svg";
import {forgotPasswordApi, generatePublicTokenApi} from "../../api/auth.api";
import bcrypt from "bcryptjs-react";
import ModalComponent from "../../components/modal.component";
import {setToolsModal} from "../../reducers/tools.reducer";
import {useDispatch} from "react-redux";

export const ForgotPasswordComponent = () => {
    const dispatch = useDispatch();
    const [fieldList, setFieldList] = useState([
        {
            label: 'Email',
            key: 'email',
            type: 'email'
        },
    ])

    const forgotPasswordHandler = (event) => {
        event.preventDefault()
        const fieldItemsCopy = [...fieldList]
        const elements = event.target
        const body = {
            email: null
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements, body)

        if (!isValid) {
            setFieldList(fieldItemsCopy)
        } else {
            let token = bcrypt.hashSync(publicKey, 10);
            let bodyRequest = {
                grant: token
            }
            generatePublicTokenApi(bodyRequest)
                .then(result => {
                    forgotPasswordApi(body, result?.access_token)
                        .then(response => {
                            dispatch(setToolsModal(true));
                            setTimeout(() => {
                                dispatch(setToolsModal(false))
                                window.location.href = '/'
                            }, 1000)
                        })
                        .catch(err => {
                            if (err?.code === '503-0000') {
                                window.location.href = '/under-maintenance'
                            }
                            console.log(err)
                        })
                })

        }
    }

    return (
        <>
            <div className={"col space-y-8 items-center justify-center pb-16 w-full"}>
                <Link to={"/"}>
                    <img src={avoMO} alt="avoMO" className={"w-48"}/>
                </Link>
                <div
                    className={"w-[480px] border rounded-xl border-grey-green px-20 py-32 col items-center text-xs bg-white shadow-xl z-10"}>
                    <p className={"text-green-grey font-Belleza-Regular text-4xl"}>Lupa Password</p>
                    <form onSubmit={forgotPasswordHandler} className={"col items-center w-full"}>
                        <div className={"col items-center space-y-2 w-full mt-20"}>
                            <p className={"text-dark-grey font-NunitoSans-SemiBold"}>{fieldList[0].label}</p>
                            <input
                                id={fieldList[0].key} type={fieldList[0].type}
                                className={"w-full border h-8 rounded-full px-4 border-white-greyish bg-white-two text-center"}
                                required/>
                            <p className={"font-NunitoSans-SemiBold text-red-600"}>{fieldList[0].errorText}</p>
                        </div>
                        <button
                            className={"bg-green-grey text-white font-NunitoSans-Bold w-1/2 h-8 rounded-full mt-10 hover:bg-green-sage"}>Kirim
                            Verifikasi
                        </button>
                    </form>
                </div>
                <img src={loginBubble} alt="loginBubble" className={"w-5/12 absolute left-0 bottom-0"}/>
                <img src={loginBubble} alt="loginBubble" className={"w-5/12 absolute right-0 bottom-0 -scale-x-100"}/>
            </div>
            <ModalComponent message={'Berhasil!'} type={'success'}/>
        </>
    )
}
