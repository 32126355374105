import ToolTipComponent from "../../components/tool-tip.component";
import React from "react";
import {useSelector} from "react-redux";

const PercentageDeliveryDelayOrderCardComponent = () => {
    const {delayedDeliveryStatistic} = useSelector(state => state.servicePerformanceReducer)
    const percentageCard = delayedDeliveryStatistic.percentage_card

    return (
        <div className={"border rounded-md p-5 shadow-lg space-y-3"}>
            <div className={"row items-center justify-between"}>
                <p className={"text-lg font-Belleza-Regular text-brownish-grey"}>Persentase keterlambatan pengiriman order</p>
                <ToolTipComponent/>
            </div>
            <div className={"row items-center space-x-10"}>
                <p className={"font-Belleza-Regular text-4xl text-dark-sage-two"}>{percentageCard.current_percentage} %</p>
                <div className={"px-3 bg-light-grey border border-light-green-seven text-dark-sage-four py-1 rounded"}>
                    <p>{`Target < ${percentageCard.target_percentage} %`}</p>
                </div>
            </div>
            <p className={"text-green-grey font-NunitoSans-SemiBold"}>Kemarin {percentageCard.past_percentage}% </p>
        </div>
    )
}

export default PercentageDeliveryDelayOrderCardComponent