import JntLogo from "../images/png-jpg/jnt.png"
import JneLogo from "../images/png-jpg/jne.png"
import RpxLogo from "../images/png-jpg/rpx.png"
import PosLogo from "../images/png-jpg/pos.png"
import SicepatLogo from "../images/png-jpg/sicepat.png"
import moment from "moment";
import {CANCELLED, COMPLETED, DELIVERY, ON_HOLD, PACKING, PENDING, PROCESSING} from "../models/order.model";
import validate from "./validate";

const authToken = localStorage.getItem('AVOSKIN_OPS_TOKEN');
const refreshToken = localStorage.getItem('AVOSKIN_OPS_REFRESH_TOKEN');
const publicKey = process.env.REACT_APP_DASHBOARD_OPS_PUBLIC_KEY
const googleRecaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
const idLocale = require('moment/locale/id');

const randomNumber = (n) => {
    let add = 1,
        max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

    if (n > max) {
        return randomNumber(max) + randomNumber(n - max);
    }

    max = Math.pow(10, n + add);
    let min = max / 10; // Math.pow(10, n) basically
    let number = Math.floor(Math.random() * (max - min + 1)) + min;

    return ('' + number).substring(add);
};

const doConvertNumberToRupiahFormat = (numberToFormat) => {
    return new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(numberToFormat);
};

const getExpeditionLogo = (expedition) => {
    const expeditionName = expedition.split('-')[0].split(' ').join('').toLowerCase()

    switch (expeditionName) {
        case 'jnt':
            return JntLogo
        case 'jne':
            return JneLogo
        case 'rpx':
            return RpxLogo
        case 'sicepat':
            return SicepatLogo
        case 'pos':
            return PosLogo
    }
}

const formatDateToReadable = (date, format) => {
    return moment.unix(date).format(!!format ? format : "DD-MM-YYYY")
}

const convertDateToCustomFormat = (date, format) => {
    moment.locale('id', idLocale);
    return moment(date).format(format)
};

const isAfterDateTimeNow = (date) => {
    return moment(date).isAfter(moment.utc())
}

const formatDateStartOfUnix = (date) => {
    return moment(date).startOf('day').unix()
}

const formatDateEndOfUnix = (date) => {
    return moment(date).endOf('day').unix()
}

const convertUnixToMoment = (date) => {
    return moment.unix(date)
}

const statusOrderCountDown = (status, record) => {
    let orderInformation = ''
    let orderMessage = ''
    let processingExpiredTime = ''
    let completedTime = ''

    switch (status) {
        case 'processing':
            if (isAfterDateTimeNow(record.expired_process_order)) {
                orderMessage = 'Mohon dikirim sebelum'
                processingExpiredTime = convertDateToCustomFormat(record.expired_process_order, 'DD / MM / YYYY HH:mm')
            } else {
                orderInformation = 'Terlambat'
                orderMessage = '-'
            }
            break;
        case 'packing':
            orderInformation = 'Dikemas'
            orderMessage = '-'
            break;
        case 'cancelled':
            orderInformation = 'Dibatalkan'
            orderMessage = '-'
            break;
        case 'on-delivery':
            orderInformation = 'Dikirim'
            orderMessage = '-'
            break;
        case 'pending-payment':
        case 'on-hold':
            orderInformation = 'Tertahan'
            orderMessage = '-'
            break;
        case 'completed':
            orderInformation = 'Selesai'
            orderMessage = 'Terkirim'
            completedTime = convertDateToCustomFormat(record.completed_at, 'DD / MM / YYYY HH:mm')
            break;
        default:
            return 0
    }

    return {
        orderInformation,
        orderMessage,
        processingExpiredTime,
        completedTime
    }
}

const statusRenderClassName = (status) => {
    let text = ''
    let className = ''
    switch (status) {
        case ON_HOLD:
            className = 'bg-white-three text-dark-grey'
            text = 'On Hold'
            break;
        case PENDING:
            className = 'bg-beige text-caramel'
            text = 'Pending'
            break;
        case PROCESSING:
            className = 'bg-light-sage text-dark-sage-two'
            text = 'Processing'
            break;
        case PACKING:
            className = 'bg-light-sage text-dark-sage-two'
            text = 'Packing'
            break;
        case DELIVERY:
            className = 'bg-light-sage text-dark-sage-two'
            text = 'On Delivery'
            break;
        case COMPLETED:
            className = 'bg-duck-egg-blue text-mid-blue'
            text = 'Completed'
            break;
        case CANCELLED:
            className = 'bg-beige-two text-brownish-red'
            text = 'Cancelled'
            break;
        default:
            return 0
    }

    return {
        text,
        className
    }
}

const checkFieldValidate = (fieldList, elements, modelData, isConfirmPassword = false) => {
    let isValid = true;

    fieldList.forEach((field) => {
        let result = validate(field, elements[field.key].value, isConfirmPassword);
        if (result) {
            isValid = false;
            field.errorText = result
        } else {
            modelData[field.key] = elements[field.key].value
            field.errorText = null
        }
    })

    return isValid
}

const getLocalStorage = (objectKey) => {
    return localStorage.getItem(objectKey);
}

const dateRangeToday = [moment().unix(), moment().unix()]

const generateExportFileName = (filename) => {
  return `${filename}-${moment().unix()}.xlsx`
}

const isUnauthorized = (code) => {
    return (code === '401-A006' || code === '401-A007' || code === '401-A003')
}

const isStaticPage = (pathName) => {
    const listStaticPage = [
        '/order/import-resi',
    ]
    return listStaticPage.includes(pathName)
}

export {
    authToken,
    googleRecaptchaSiteKey,
    doConvertNumberToRupiahFormat,
    getExpeditionLogo,
    formatDateToReadable,
    convertDateToCustomFormat,
    formatDateStartOfUnix,
    formatDateEndOfUnix,
    convertUnixToMoment,
    statusOrderCountDown,
    statusRenderClassName,
    checkFieldValidate,
    getLocalStorage,
    isAfterDateTimeNow,
    dateRangeToday,
    publicKey,
    refreshToken,
    generateExportFileName,
    isUnauthorized,
    isStaticPage
}
