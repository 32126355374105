import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSettingModalDetail} from "../../reducers/setting.reducer";
import {formatDateToReadable} from "../../ui-utils/general-variable";
import {addHolidayApi, deleteHolidayApi, updateHolidayApi} from "../../api/setting.api";
import {setToolsReload} from "../../reducers/tools.reducer";

const DayOffModalComponent = () => {
    const dispatch = useDispatch()
    const {datePicked, modalDetail} = useSelector(state => state.settingReducer)
    const {reload} = useSelector(state => state.toolsReducer)
    const {visibility, type, holidayDetail} = modalDetail
    const [note, setNote] = useState('')

    useEffect(() => {
        setNote(holidayDetail?.note ?? '')
    }, [holidayDetail])

    const buttonSubmitType = () => {
        switch (type) {
            case 'add':
                return 'Submit'
            case 'edit':
                return 'Ubah'
            case 'delete':
                return 'Hapus'
        }
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        if (type === 'delete') {
            submitHandler()
        } else {
            submitHandler()
        }
    }

    const submitHandler = () => {
        switch (type) {
            case 'add':
                addHoliday()
                break;
            case 'edit':
                updateHoliday()
                break;
            case 'delete':
                deleteHoliday()
                break;
        }
    }

    const addHoliday = () => {
        let requestBody = {
            note,
            date: formatDateToReadable(datePicked, 'YYYY-MM-DD')
        }

        addHolidayApi(requestBody)
            .then(response => {
                dispatch(setToolsReload(!reload))
                closeModal()
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    console.log(err)
                    closeModal()
                }
            })
    }

    const updateHoliday = () => {
        let requestBody = {
            note
        }

        updateHolidayApi(holidayDetail?.id, requestBody)
            .then(response => {
                dispatch(setToolsReload(!reload))
                closeModal()
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    console.log(err)
                    closeModal()
                }
            })
    }

    const deleteHoliday = () => {
        deleteHolidayApi(holidayDetail?.id)
            .then(response => {
                dispatch(setToolsReload(!reload))
                closeModal()
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    console.log(err)
                    closeModal()
                }
            })
    }


    const closeModal = () => {
        dispatch(setSettingModalDetail({visibility: false}))
    }

    return (
        <div
            className={`col items-center fixed w-screen h-screen bg-dark-grey-three/[.65] left-0 z-20 ${type === 'add' ? '-top-4' : 'top-0'} ${visibility ? 'visible' : 'invisible'}`}>
            <div className={"col bg-white h-fit rounded-lg px-5 pt-2 mt-16 w-1/2"}>
                <div className={"row justify-end"}>
                    <button type="button" onClick={closeModal}
                            className={"text-2xl font-bold hover:text-black"}>
                        x
                    </button>
                </div>
                <form onSubmit={onSubmitHandler}>
                    <div className={"col space-y-5 px-8 py-5"}>
                        {type === 'delete' ?
                            <div className={"col space-y-5 px-8 py-5"}>
                                <p className={"font-Belleza-Regular text-2xl"}>Delete Stop Order Processing</p>
                                <p className={"font-NunitoSans-SemiBold text-base"}>Apakah Anda yakin ingin menghapus
                                    jadwal stop order processing ini ?</p>
                            </div> :
                            <div className={"col space-y-5"}>
                                <label className={"font-Belleza-Regular text-2xl"}>{type === 'add' ? 'Add' : 'Update'} Stop Order Processing</label>
                                <textarea cols="30" rows="10"
                                          name={'note'}
                                          className={"border border-white-five py-2.5 px-3 focus:border-black focus:outline-offset-2 focus:outline-light-green-seven"}
                                          value={note}
                                          onChange={(e) => setNote(e.target.value)}
                                          required
                                ></textarea>
                            </div>
                        }
                        <div className={"row space-x-8 justify-end"}>
                            {type === 'delete' &&
                                <button type={"button"} onClick={closeModal}
                                        className={"font-NunitoSans-SemiBold rounded-md w-fit py-2.5 px-16 border bg-white border-brownish-grey text-brownish-grey hover:bg-dark-sage-two hover:text-white"}>
                                    Batalkan
                                </button>
                            }
                            <button type={"submit"}
                                    className={`font-NunitoSans-SemiBold rounded-md w-fit py-2.5 px-16 border 
                                    ${type === 'delete' ? 'bg-very-light-pink border-brownish-red text-brownish-red hover:bg-brownish-red hover:text-white' :
                                        'bg-dark-sage-four border-dark-sage-two text-white hover:bg-dark-sage-two'}`}>
                                {buttonSubmitType()}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DayOffModalComponent
