import React, {useState} from "react";
import SearchComponent from "../../components/search.component";
import SelectComponent from "../../components/select.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsCurrentPage,
    setToolsModal,
    setToolsModalMessage,
    setToolsModalType,
    setToolsModalVisibility,
    setToolsOrderBy,
    setToolsSort,
    setToolsStartDateEndDate
} from "../../reducers/tools.reducer";
import FilterExpeditionComponent from "../../components/filter-expedition.component";
import PageLimitComponent from "../../components/page-limit.component";
import ExportComponent from "../../components/export.component";
import ClearComponent from "../../components/clear.component";
import ReqAwbSvg from "../../images/svg/Group 432.svg"
import PrintLabelSvg from "../../images/svg/Group 431.svg"
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {exportOrderApi, generateAwbApi, moveToPrintLabelListApi, previewPrintLabelListApi} from "../../api/order.api";
import {
    setRequestAwbInitialState,
    setRequestAwbPreviewPrintLabelList,
    setRequestAwbReload
} from "../../reducers/request-awb.reducer";
import {formatDateToReadable, generateExportFileName} from "../../ui-utils/general-variable";

const FilterComponent = () => {
    const dispatch = useDispatch()
    const [disabledButton, setDisabledButton] = useState(false)
    const {activeTab, selectedRowKeys, reload} = useSelector(state => state.requestAwbReducer)
    const {
        searchKey,
        sort,
        orderBy,
        expedition,
        dateRange
    } = useSelector(state => state.toolsReducer)
    let requestBody = {order_ids: selectedRowKeys}
    const sortByName = [
        {
            label: 'Nama User A-Z',
            key: 'customer-name_asc'
        }, {
            label: 'Nama User Z-A',
            key: 'customer-name_desc'
        }
    ]

    const onChangeSelectByName = (sortItem) => {
        let text = sortItem.split('_')
        dispatch(setToolsOrderBy(text[0]))
        dispatch(setToolsSort(text[1]))
    }

    const requestAwbButtonHandler = () => {
        setDisabledButton(true)
        generateAwbApi(requestBody)
            .then(response => {
                sendAlert('success', 'awb')
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    console.log('Error generateAwbApi', err)
                    sendAlert('error', 'awb')
                }
            })
    }

    const sendAlert = (type, scope) => {
        if (type === 'success') {
            dispatch(setToolsModalType('success'))
            dispatch(setToolsModalMessage(scope === 'awb' ? 'Request AWB Berhasil' : 'Order Berhasil dipindah ke Print Label'))
        } else {
            dispatch(setToolsModalType('error'))
            dispatch(setToolsModalMessage(scope === 'awb' ? 'Terdapat order yang gagal melakukan request AWB, silahkan cek tab error' : 'Order Gagal dipindah ke Print Label'))
        }
        dispatch(setRequestAwbInitialState())
        dispatch(setRequestAwbReload(!reload))
        dispatch(setToolsModal(true))
        setDisabledButton(false)
    }

    const moveToPrintLabel = () => {
        setDisabledButton(true)
        moveToPrintLabelListApi(requestBody)
            .then(response => {
                setDisabledButton(false)
                sendAlert('success')
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                } else {
                    sendAlert('error')
                    setDisabledButton(false)
                    console.log('Error moveToPrintLabelListApi', err)
                }
            })
    }

    const getValueFromRangePickerSingle = (d) => {
        dispatch(setToolsStartDateEndDate(d))
        dispatch(setToolsCurrentPage(1))
    }

    const showErrorModal = (message) => {
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType("error"))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            dispatch(setToolsModal(false))
        }, 1000)
    }

    const generateLabel = () => {
        const body = {
            order_id: selectedRowKeys
        }
        previewPrintLabelListApi(body).then(response => {
            dispatch(setRequestAwbPreviewPrintLabelList(response))
            dispatch(setToolsModalVisibility(true))
        }).catch(err => {
            if (err?.data?.code === '503-0000') {
                localStorage.clear()
                window.location.href = '/under-maintenance'
            } else {
                console.log(err)
                const result = err?.data
                showErrorModal(result?.message || "Terjadi Kesalahan")
            }
        })
    }

    const constructRightButton = () => {
        switch (activeTab) {
            case 'request-awb':
                return (
                    <button
                        onClick={requestAwbButtonHandler}
                        disabled={selectedRowKeys.length === 0 || disabledButton}
                        className={"row items-center bg-green-grey h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-dark-sage-two disabled:bg-greyish"}>
                        <img src={ReqAwbSvg} className={"w-9"}/>
                        <p>Request AWB</p>
                    </button>
                )
            case 'print-label':
                return (
                    <button
                        onClick={generateLabel}
                        disabled={selectedRowKeys.length === 0}
                        className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                        <img src={PrintLabelSvg} className={"w-9"}/>
                        <p>Print Label</p>
                    </button>
                )
            case 'error':
                return (
                    <div className={"row items-center space-x-3"}>
                        <button
                            onClick={moveToPrintLabel}
                            disabled={selectedRowKeys.length === 0}
                            className={"row items-center bg-mid-blue h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-light-navy disabled:bg-greyish"}>
                            <img src={PrintLabelSvg} className={"w-9"}/>
                            <p>Pindah ke Label</p>
                        </button>
                        <button
                            onClick={requestAwbButtonHandler}
                            disabled={selectedRowKeys.length === 0 || disabledButton}
                            className={"row items-center bg-green-grey h-8 text-white pr-3 border rounded font-NunitoSans-Bold hover:bg-dark-sage-two disabled:bg-greyish"}>
                            <img src={ReqAwbSvg} className={"w-9"}/>
                            <p>Request AWB</p>
                        </button>
                    </div>
                )
        }
    }

    const exportHandler = () => {
        let requestBody = {
            status: activeTab,
            "order_by": !!orderBy ? orderBy : 'id',
            "order_type": sort,
            "start_date": dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            "end_date": dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            "filter_by_expedition": expedition?.key || null,
            search: searchKey,
            order_ids: selectedRowKeys
        }

        exportOrderApi(requestBody)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${generateExportFileName(`Request AWB`)}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }

    return (
        <div className={"row items-center justify-between px-5"}>
            <div className={"row items-center space-x-5"}>
                <SearchComponent/>
                <div className={"row items-center space-x-2"}>
                    <SelectComponent dataList={sortByName} onChange={onChangeSelectByName}/>
                    <FilterExpeditionComponent slug={'request-awb'}/>
                    <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
                </div>
                <div className={"row items-center space-x-2"}>
                    <PageLimitComponent/>
                    <ExportComponent exportHandler={exportHandler}/>
                    <ClearComponent slug={'request-awb'}/>
                </div>
            </div>
            {constructRightButton()}
        </div>
    )
}

export default FilterComponent
