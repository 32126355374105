import React, {useEffect, useState} from "react";
import ModalCardComponent from "../../components/modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setManifestReload,
    setManifestScanBarcodeModalVisibility,
    setManifestSelectedRowKeys,
    setManifestSelectedRows
} from "../../reducers/manifest.reducer";
import ScanBarcodeSvg from "../../images/svg/Group 421.svg"
import {moveToPrintManifestApi} from "../../api/order.api";
import {setToolsModal, setToolsModalMessage, setToolsModalType} from "../../reducers/tools.reducer";
import CloseIcon from "../../images/svg/x.svg";

const ScanBarcodeModal = () => {
    const dispatch = useDispatch()
    const {scanBarcodeModalVisibility, reload} = useSelector(state => state.manifestReducer)
    const [orderId, setOrderId] = useState('')

    useEffect(() => {
        return () => {
            setOrderId('')
        }
    }, [])

    const closeModal = () => {
        setOrderId('')
        dispatch(setManifestScanBarcodeModalVisibility(false))
    }

    const onChangeHandler = (value) => {
        if (value.length > 3) {
            setOrderId(value)
            moveToPrintManifestHandler(value)
        }
    }

    const moveToPrintManifestHandler = (value) => {
        moveToPrintManifestApi({order_ids: [value]})
            .then(response => {
                dispatch(setToolsModalType('success'))
                dispatch(setToolsModalMessage(`Order berhasil dipindah ke print surat jalan`))
                dispatch(setManifestSelectedRowKeys([]))
                dispatch(setManifestSelectedRows([]))
                dispatch(setManifestReload(!reload))
                dispatch(setToolsModal(true))
                setOrderId('')

                setTimeout(() => {
                    dispatch(setToolsModal(false))
                }, 500)
            })
            .catch(err => {
                if (err?.data?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                stopProcessHandler(err?.data?.message)
                setOrderId('')
            })
    }

    const stopProcessHandler = (msg) => {
        dispatch(setToolsModalType('error'))
        dispatch(setToolsModalMessage(msg ? msg : `Order gagal dipindah ke print surat jalan`))
        closeModal()
        dispatch(setToolsModal(true))
    }

    return (
        <ModalCardComponent
            visibility={scanBarcodeModalVisibility}
            content={
                <div className={"col bg-white rounded-md relative min-h-[500px] w-2/5 overflow-y-scroll"}>
                    <img src={CloseIcon} alt="closeButton"
                         className={`w-5 absolute right-4 top-2 cursor-pointer`}
                         onClick={closeModal}/>
                    <div className={"col items-center text-center space-y-8 p-5"}>
                        <img src={ScanBarcodeSvg} className={"scale-75"}/>
                        <p className={"text-base text-grey-green italic w-4/5"}>Arahkan laser scanner pada
                            barcode yang tersedia pada kode pesanan</p>
                        <div className={"text-brownish-grey"}>
                            <p className={"font-NunitoSans-Bold text-base"}>Kode Pesanan</p>
                            <p className={"italic"}>Arahkan kursor pada kolom di bawah</p>
                        </div>
                        <div
                            className={"border border-green-grey bg-light-grey rounded-full h-8 w-3/5 row items-center px-5 space-x-1 text-brownish-grey"}>
                            <p>AVS -</p>
                            <input onChange={(e) => onChangeHandler(e.target.value)}
                                   id={'scanBarcode'}
                                   value={orderId}
                                   className={"outline-none h-7 flex-1 bg-light-grey autofocus"}/>
                        </div>
                    </div>
                    <div className={"w-full row gap-14 items-center justify-center h-20 bg-light-grey-six"}>
                        <button onClick={closeModal}
                                className={"font-NunitoSans-Bold rounded-lg text-white bg-light-navy border border-light-navy px-10 h-8 rounded-sm"}>Selesai
                        </button>
                    </div>
                </div>
            }
        />
    )
}

export default ScanBarcodeModal
