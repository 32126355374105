import React from "react";
import GenderChartComponent from "./gender-chart.component";
import BuyerTypeChartComponent from "./buyer-type-chart.component";
import BuyerAgeChartComponent from "./buyer-age-chart.component";

const CustomerInfoStatisticComponent = () => {
    return (
        <div className={"grid grid-cols-4 gap-2"}>
            <GenderChartComponent />
            <BuyerTypeChartComponent />
            <BuyerAgeChartComponent />
        </div>
    )
}

export default CustomerInfoStatisticComponent
