import React from "react";
import {Popover} from '@headlessui/react'

const ToolTipComponent = ({text, right}) => {
    return (
        <Popover className="relative">
            <Popover.Button className={"outline-none"}>
                <div
                    className={"text-dark-sage-two w-4 h-4 bg-light-grey-three border border-green-grey rounded-full row items-center justify-center font-NunitoSans-Regular text-xs"}>?
                </div>
            </Popover.Button>
            <Popover.Panel className={`absolute z-10 ${right && 'right-0'}`}>
                <div className="border border-dark-sage-two rounded-lg p-1 bg-white mt-1 text-11 w-56">
                    <p className={"text-dark-sage-two whitespace-pre-line"}>{text}</p>
                </div>
            </Popover.Panel>
        </Popover>

    )
}

export default ToolTipComponent
