import React from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart, ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

const ChartComponent = (props) => {
    const {type, data, tooltip, width, color} = props

    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white border text-11 p-2 row items-center rounded-md shadow-md space-x-1">
                    <div className={"bg-green-grey w-2 h-2 rounded-full"}/>
                    <p>{`${tooltip} : ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };

    const lineChart = () => {
        return (
            <ResponsiveContainer>
                <LineChart
                    height={300}
                    data={data}
                    margin={{
                        top: 10,
                        right: 20,
                        left: 20,
                        bottom: 10
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="key"/>
                    <YAxis yAxisId="left"/>
                    <YAxis yAxisId="right" orientation="right"/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend/>
                    <Line
                        yAxisId="left"
                        dataKey="value"
                        stroke="#748d6f"
                        strokeWidth={1}
                        activeDot={{r: 8}}
                    />
                </LineChart>
            </ResponsiveContainer>
        )
    }

    const barChart = () => {
        return (
            <ResponsiveContainer>
                <BarChart width={width} height={300} data={data}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="key"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="value" fill="#748d6f"/>
                </BarChart>
            </ResponsiveContainer>
        )
    }

    const pieChart = () => {
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };

        return (
            <ResponsiveContainer>
                <PieChart width={width} height={200}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={color[index % color.length]}/>
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        )
    }

    switch (type) {
        case 'line':
            return lineChart()
        case 'bar':
            return barChart()
        case 'pie':
            return pieChart()
    }

}

export default ChartComponent
