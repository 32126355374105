import React, {useState} from "react";
import {Listbox, Transition} from '@headlessui/react'
import NotificationSvg from "../images/svg/Group 412.svg"
import UserProfileSvg from "../images/svg/Group 413.svg"
import BreadcrumbComponent from "./breadcrumb.component";
import NotificationOnHeaderComponent from "./notification-on-header.component";
import {Link} from "react-router-dom";
import {logoutApi} from "../api/auth.api";
import {useSelector} from "react-redux";

const HeaderComponent = () => {
    const {userDetail} = useSelector(state => state.userReducer)
    const [showAdminMenu, setShowAdminMenu] = useState(false)
    const [showNotification, setShowNotification] = useState(false)

    const adminMenuList = [
        {
            label: 'Profile Info',
            key: 'profile-info'
        }, {
            label: 'Settings',
            key: 'settings'
        }
    ]

    const adminButtonHandler = () => {
        setShowAdminMenu(!showAdminMenu)
    }

    const notificationButtonHandler = () => {
        setShowNotification(!showNotification)
    }

    const LogoutHandler = () => {
        logoutApi()
            .then(r => {
                localStorage.clear()
                window.location.reload()
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }

    return (
        <div className={"h-24 w-full row items-center justify-between sticky"}>
            <BreadcrumbComponent/>
            <div className={""}>
                <div className={"row items-center space-x-7"}>
                    <div className={"w-9 relative"}>
                        <div className={"cursor-pointer"} onClick={notificationButtonHandler}>
                            <img src={NotificationSvg} className={"w-9"}/>
                            <div
                                className={"absolute bg-red-lips px-1  col items-center justify-center top-0 ml-5 text-white rounded-full text-9"}>260
                            </div>
                        </div>
                        <div className={`absolute top-14 -right-2 ${showNotification ? 'visible' : 'invisible'}`}>
                            <NotificationOnHeaderComponent notificationButtonHandler={notificationButtonHandler}/>
                        </div>
                    </div>
                    <Listbox>
                        <div className={"relative"}>
                            <Listbox.Button onClick={adminButtonHandler}>
                                <div
                                    className={`border rounded border-light-grey-three row items-center space-x-3 p-2 cursor-pointer text-dark-sage-three hover:bg-green-grey hover:text-white ${showAdminMenu && 'bg-green-grey'}`}>
                                    <img src={userDetail?.image_url ?? UserProfileSvg} className={"w-8 rounded-full"}/>
                                    <div className={'w-40'}>
                                        <p className={"font-NunitoSans-Bold text-sm text-start"}>{userDetail?.full_name}</p>
                                        <p className={"font-NunitoSans-Regular text-11 text-start"}>Admin</p>
                                    </div>
                                </div>
                            </Listbox.Button>

                            <Transition
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div
                                    className={"bg-white absolute mt-3 shadow-lg rounded-md w-full col py-2 border border-light-grey-three space-y-2"}>
                                    {
                                        adminMenuList.map((item, index) => (
                                            <Link to={`home/${item.key}`} key={index}
                                                  className={"hover:bg-light-grey-two cursor-pointer row justify-center items-center h-10 text-dark-sage-four font-NunitoSans-SemiBold text-sm"}>{item.label}</Link>
                                        ))
                                    }
                                    <div className={"row items-center justify-center"}>
                                        <button
                                            onClick={LogoutHandler}
                                            className={"bg-dark-sage-two text-white text-sm font-NunitoSans-SemiBold px-10 rounded-full h-10"}>Log
                                            Out
                                        </button>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
            </div>
        </div>
    )
}

export default HeaderComponent
