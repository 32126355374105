export const statisticListModel = [
    {
        current_count: 0,
        past_count: 0,
        title: 'Order',
        trend: 'equal',
        trend_percentage: 0
    }, {
        current_count: 0,
        past_count: 0,
        title: 'Penjualan Produk',
        trend: 'equal',
        trend_percentage: 0
    }, {
        current_count: 0,
        past_count: 0,
        title: 'Produk Terjual',
        trend: 'equal',
        trend_percentage: 0
    }
]

export const chartModel = {
    title: 'Tren Order',
    data: []
}