import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notificationList: [
            {
                body: "Order AVS-17325 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "05 / 09 / 2022 17:46",
                id: 17439,
                is_read: false,
                order_id: 17325,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17326 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "06 / 09 / 2022 17:46",
                id: 17440,
                is_read: false,
                order_id: 17326,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17327 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "07 / 09 / 2022 17:46",
                id: 17441,
                is_read: false,
                order_id: 17327,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17328 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "08 / 09 / 2022 17:46",
                id: 17442,
                is_read: false,
                order_id: 17328,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17329 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "09 / 09 / 2022 17:46",
                id: 17443,
                is_read: false,
                order_id: 17329,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17330 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "10 / 09 / 2022 17:46",
                id: 17444,
                is_read: false,
                order_id: 17330,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17331 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "11 / 09 / 2022 17:46",
                id: 17445,
                is_read: false,
                order_id: 17331,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17332 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "12 / 09 / 2022 17:46",
                id: 17446,
                is_read: false,
                order_id: 17332,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17333 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "13 / 09 / 2022 17:46",
                id: 17447,
                is_read: false,
                order_id: 17334,
                title: "Pengingat Proses Order"
            },
            {
                body: "Order AVS-17335 akan segera terlambat di proses, segera proses orderan sebelum tanggal 11 / 08 / 2022 18:09",
                date: "14 / 09 / 2022 17:46",
                id: 17448,
                is_read: false,
                order_id: 17335,
                title: "Pengingat Proses Order"
            },
        ],
    },
    reducers: {
        setNotificationList: (state, action) => {
            state.notificationList = action.payload
        }
    },
})

export const { setNotificationList } = notificationSlice.actions
const notificationReducer = notificationSlice.reducer

export default notificationReducer
