import {createSlice} from '@reduxjs/toolkit'
import {statisticListModel} from '../models/product-performance.model'
import {chartModel} from '../models/product-performance.model'

export const productPerformanceSlice = createSlice({
    name: 'product-performance',
    initialState: {
        selectedRowKeys: [],
        loading: false,
        productList: [],
        categoryList: [],
        categorySelected: {},
        activeCard: 'order',
        statisticList: [...statisticListModel],
        chart: {...chartModel}
    },
    reducers: {
        setProductPerformanceSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload
        },
        setProductPerformanceLoading: (state, action) => {
            state.loading = action.payload
        },
        setProductPerformanceProductList: (state, action) => {
            state.productList = action.payload
        },
        setProductPerformanceCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setProductPerformanceCategorySelected: (state, action) => {
            state.categorySelected = action.payload
        },
        setProductPerformanceActiveCard: (state, action) => {
            state.activeCard = action.payload
        },
        setProductPerformanceStatisticList: (state, action) => {
            state.statisticList = action.payload
        },
        setProductPerformanceChart: (state, action) => {
            state.chart = action.payload
        },
        setProductPerformanceInitialState: (state, action) => {
            state.selectedRowKeys = []
            state.categorySelected = {}
        },
    }
})

export const {
    setProductPerformanceSelectedRowKeys,
    setProductPerformanceProductList,
    setProductPerformanceCategoryList,
    setProductPerformanceCategorySelected,
    setProductPerformanceActiveCard,
    setProductPerformanceStatisticList,
    setProductPerformanceChart,
    setProductPerformanceInitialState
} = productPerformanceSlice.actions
const productPerformanceReducer = productPerformanceSlice.reducer

export default productPerformanceReducer
