import {createSlice} from '@reduxjs/toolkit'
import {formatDateStartOfUnix} from "../ui-utils/general-variable";

export const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        settingDetail: {
            holidays_list: [],
            max_order_processing: 0,
        },
        currentYear: '',
        modalDetail: {
            visibility: false,
            type: 'add',
            holidayDetail: {}
        },
        datePicked: formatDateStartOfUnix()
    },
    reducers: {
        setSettingDetail: (state, action) => {
            state.settingDetail = action.payload
        },
        setSettingCurrentYear: (state, action) => {
            state.currentYear = action.payload
        },
        setSettingModalDetail: (state, action) => {
            state.modalDetail = {...state.modalDetail, ...action.payload}
        },
        setSettingDatePicked: (state, action) => {
            state.datePicked = action.payload
        },
    },
})

export const {
    setSettingDetail,
    setSettingCurrentYear,
    setSettingModalDetail,
    setSettingDatePicked
} = settingSlice.actions
const settingReducer = settingSlice.reducer

export default settingReducer
