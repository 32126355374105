import {createSlice} from '@reduxjs/toolkit'

export const customerInfoSlice = createSlice({
    name: 'customer-info',
    initialState: {
        customerList: [],
        genderDataChart: {
            title: '',
            data: [],
            detail: {}
        },
        buyerTypeDataChart: {
            title: '',
            data: [],
            detail: {}
        },
        buyerAgeDataChart: {
            title: '',
            data: [],
            detail: {}
        }
    },
    reducers: {
        setCustomerList: (state, action) => {
            state.customerList = action.payload
        },
        setCustomerGenderDataChart: (state, action) => {
            state.genderDataChart = action.payload
        },
        setCustomerBuyerTypeDataChart: (state, action) => {
            state.buyerTypeDataChart = action.payload
        },
        setCustomerBuyerAgeDataChart: (state, action) => {
            state.buyerAgeDataChart = action.payload
        }
    },
})

export const {
    setCustomerList,
    setCustomerGenderDataChart,
    setCustomerBuyerTypeDataChart,
    setCustomerBuyerAgeDataChart
} = customerInfoSlice.actions
const customerInfoReducer = customerInfoSlice.reducer

export default customerInfoReducer
