import React from "react";
import CopySvg from "../images/svg/Group 434.svg";
import {setToolsModal, setToolsModalMessage, setToolsModalType} from "../reducers/tools.reducer";
import {useDispatch} from "react-redux";

const CopyToClipboardComponent = (props) => {
    const {text, message = 'Text berhasil di copy'} = props
    const dispatch = useDispatch()

    const copyResi = async () => {
        await navigator.clipboard.writeText(text)
        dispatch(setToolsModalMessage(message))
        dispatch(setToolsModalType("success"))
        dispatch(setToolsModal(true))

        setTimeout(() => {
            dispatch(setToolsModal(false))
        }, 1000)
    }

    return (
        <button className={"row items-center"} onClick={copyResi}>
            <img src={CopySvg} alt="CopySvg" className={"w-7"}/>
            <p className={"text-green-grey font-NunitoSans-Bold"}>Copy Resi</p>
        </button>
    )
}

export default CopyToClipboardComponent
