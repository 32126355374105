import React from "react";

const InputComponent = ({ field }) => {
    return (
        <div className={"col items-center space-y-2"}>
            <label htmlFor={field.key} className={"text-dark-grey"}>{field.label}</label>
            <input
                id={field.key} type={field.type} className={"w-full border h-8 px-4 rounded-full border-white-greyish bg-white-two text-center"} required/>
            <p className={"font-NunitoSans-SemiBold text-red-600"}>{field.errorText}</p>
        </div>
    )
}

export default InputComponent