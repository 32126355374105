import React from "react";

const ModalCardComponent = (props) => {
    const {content, visibility, onClose} = props

    return (
        <div
            className={` ${visibility ? 'block' : 'hidden'} fixed flex left-0 top-0 w-full h-full justify-center items-center block z-20`}>
            <div onClick={onClose} className={"absolute bg-dark-grey-three/[.65] inset-0"}/>
            {content}
        </div>
    )
}

export default ModalCardComponent
