import React from "react";
import {useSelector} from "react-redux";

const OrderDetailCustomerComponent = () => {
    const {orderDetail} = useSelector(state => state.orderReducer)
    const detailCustomer = [
        {
            label: 'No. Pesanan',
            value: orderDetail.invoice_number
        },
        {
            label: 'Alamat Pengiriman',
            value: orderDetail.order.full_address
        },
        {
            label: 'Nomor Telp.',
            value: orderDetail.order.customer_phone
        },
        {
            label: 'Catatan dari Customer',
            value: orderDetail.order.notes
        }
    ]

    return (
        <div className={"col w-3/12 space-y-6 px-2"}>
            {detailCustomer.map((detailCustomer, index) => (
                <div className={"col space-y-2"} key={index}>
                    <p className={"font-NunitoSans-Bold"}>{detailCustomer.label}</p>
                    <p>{detailCustomer.value}</p>
                </div>
            ))}
        </div>
    )
}

export default OrderDetailCustomerComponent