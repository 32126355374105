import React, {useEffect} from "react";
import ExportComponent from "../../components/export.component";
import PackingTimeStatisticComponent from "./packing-time-statistic.component";
import PackingTimeChartComponent from "./packing-time-chart.component";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {useDispatch, useSelector} from "react-redux";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";
import {dateRangeToday, formatDateToReadable, generateExportFileName} from "../../ui-utils/general-variable";
import {exportPackingTimeApi, getPackingTimeStatisticApi} from "../../api/service-performance.api";
import {setServicePerformancePackingTime} from "../../reducers/service-performance.reducer";

export const PackingTimeComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)

    const getValueFromRangePickerSingle = (d) => {
        dispatch(setToolsStartDateEndDate(d))
    }

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            let params = {
                startDate: formatDateToReadable(dateRange[0], 'YYYY-MM-DD'),
                endDate: formatDateToReadable(dateRange[1], 'YYYY-MM-DD')
            }
            getPackingTimeStatisticApi(params)
                .then(result => {
                    dispatch(setServicePerformancePackingTime(result))
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    console.log(err)
                })
        }
    }, [dateRange])

    const exportHandler = () => {
        let requestBody = {
            "start_date": formatDateToReadable(dateRange[0], 'YYYY-MM-DD'),
            "end_date": formatDateToReadable(dateRange[1], 'YYYY-MM-DD'),
        }

        exportPackingTimeApi(requestBody)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${generateExportFileName('Masa Pengemasan')}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
    }

    return (
        <div className={"space-y-5 box-content-style p-5"}>
            <div className={"row items-center justify-between"}>
                <div/>
                <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
                <ExportComponent exportHandler={exportHandler}/>
            </div>
            <PackingTimeStatisticComponent/>
            <PackingTimeChartComponent />
        </div>
    )
}
