const validate = (field, inputValue, confirmPasswordIsValid) => {
    const REGEX_EMAIL = /^\S+@\S+\.\S+$/

    switch (field.key){
        case 'email':
            return !REGEX_EMAIL.test(inputValue) ? `${field.label} is not valid` : false
        case 'current_password':
        case 'password':
            return inputValue.length < 6 ? 'Password must be 8 characters long' : false
        case 'password_confirmation':
            return !confirmPasswordIsValid ? `New Password not matched` : false
        case 'orderNotComplete':
        case 'orderDelayed':
        case 'packagingTime':
        case 'scanBarcode':
            return Number(inputValue) <= 0 ? `${field.label} must be greater than 0` : false
        case 'days':
        case 'hours':
        case 'number':
            return Number(inputValue) < 0
    }
}

export default validate
