import React, {Fragment} from "react";
import {Menu, Transition} from '@headlessui/react'
import SortSvg from "../images/svg/Group 436.svg"
import FilterSvg from "../images/svg/Group 437.svg";
import {useSelector} from "react-redux";

const SelectComponent = (props) => {
    const {type = 'select', dataList, onChange} = props;
    const {orderBy, sort} = useSelector(state => state.toolsReducer);
    const {categorySelected} = useSelector(state => state.productPerformanceReducer)
    const filterName = `${orderBy}_${sort}`

    return (
        <Menu as="div" className="relative inline-block h-8">
            <Menu.Button
                className="inline-flex w-full justify-center rounded-md border border-pinkish-grey shadow hover:border-grey-green hover:bg-light-grey px-1">
                <img src={type === 'filter' ? FilterSvg : SortSvg} className={"w-6 2xl:w-8 h-8"}/>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute -left-24 mt-2 w-56 origin-top-right divide-y text-sm divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <p className={"text-center text-dark-grey font-NunitoSans-SemiBold py-1"}>{type === 'filter' ? 'Filter' : 'Urutkan'}</p>
                    <div className={"px-2 max-h-48 overflow-scroll"}>
                        <p className={"text-center text-sm font-NunitoSans-Regular text-brownish-grey pt-2"}>{(type === 'filter' && !categorySelected) && 'Lompat Ke Tahun'}</p>
                        {
                            dataList.map((item, index) => (
                                <Menu.Item key={index}>
                                    <button
                                        onClick={() => onChange(item.key || item)}
                                        className={`flex w-full items-center p-2 mb-1 text-sm text-dark-grey font-NunitoSans-Regular rounded-md hover:bg-light-grey-three
                                         ${(item.key === filterName || (type === 'filter' && item.name === categorySelected.name)) && 'border border-dark-grey-four'}
                                         ${type === 'filter' && 'justify-center'}`}
                                    >{item.label || item.name}
                                    </button>
                                </Menu.Item>
                            ))
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default SelectComponent
