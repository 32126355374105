import {useSelector} from "react-redux";
import {doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";

const ProductListComponent = () => {
    const {orderDetail} = useSelector(state => state.orderReducer)
    const dataProduct = orderDetail.transaction.products

    return (
        <>
            <div className={"row flex-wrap justify-between w-full"}>
                <p className={"text-base font-NunitoSans-Bold"}>Info Pembayaran</p>
                <div className={"font-NunitoSans-Bold text-green-grey bg-beige-three px-5 py-1.5 rounded-full"}>
                    Riwayat Pembayaran
                </div>
            </div>
            {dataProduct.map((item, index) => (
                <div key={index} className={"row justify-between w-full"}>
                    <div className={"row items-center w-1/2"}>
                        <img src={item.image} alt={item.name} className={"mr-10 ml-2 w-10 h-10"}/>
                        <p>{item.name}</p>
                    </div>
                    <div className={"row justify-end items-center justify-between space-x-8 w-1/2 px-10"}>
                        <div className={"row w-1/2 items-center"}>
                            <div className={"space-y-1 text-green-sage font-bold"}>
                                {item.sale_price > 0 && <p>@{doConvertNumberToRupiahFormat(item.sale_price)}</p>}
                                <p className={`${item.sale_price > 0 && 'line-through text-light-green-five'}`}>@{doConvertNumberToRupiahFormat(item.price)}</p>
                            </div>
                            <p className={"font-NunitoSans-Bold pl-4"}>{item.quantity}x</p>
                        </div>
                        <div className={"space-y-1 text-green-sage font-bold"}>
                            {item.sale_price > 0 && <p>{doConvertNumberToRupiahFormat(item.sale_total)}</p>}
                            <p className={`${item.sale_price > 0 && 'line-through text-light-green-five'}`}>{doConvertNumberToRupiahFormat(item.total)}</p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ProductListComponent
