import React, {useEffect, useState} from "react";
import moment from "moment";

const CountdownComponent = ({expired}) => {
    const [timeLeft, setTimeLeft] = useState()

    useEffect(() => {
        const countDown = setInterval(() => {
            getCountDown()
        }, 1000);

        return () => clearInterval(countDown);
    }, []);

    const getCountDown = () => {
        const a = moment(expired).unix()
        const b = moment().unix()
        const diffTime = a - b;
        let duration = moment.duration(diffTime * 1000, 'milliseconds');
        setTimeLeft(duration)
    }

    return (
        <div className={'row font-NunitoSans-Bold text-brownish-red items-center space-x-1'}>
            <p>{timeLeft?.days()}d<span className={'px-1'}>:</span></p>
            <p>{timeLeft?.hours()}h<span className={'px-1'}>:</span></p>
            <p>{timeLeft?.minutes()}m<span className={'px-1'}>:</span></p>
            <p>{timeLeft?.seconds()}s</p>
        </div>
    )
}

export default CountdownComponent
