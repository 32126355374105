import React from "react";
import {useDispatch, useSelector} from "react-redux";
import UserSvg from "../../images/svg/Group 413.svg";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    getExpeditionLogo,
    isAfterDateTimeNow,
    statusOrderCountDown
} from "../../ui-utils/general-variable";
import ClockSvg from "../../images/svg/Group 444.svg";
import TableComponent from "../../components/table.component";
import TotalDataComponent from "../../components/total-data.component";
import {setRequestAwbSelectedRowKeys, setRequestAwbSelectedRows} from "../../reducers/request-awb.reducer";
import CountdownComponent from "../../components/countdown.component";
import CopyToClipboardComponent from "../../components/copyToClipboard.component";
import {PROCESSING} from "../../models/order.model";

const OrderListComponent = () => {
    const dispatch = useDispatch()
    const {selectedRowKeys, selectedRows, orderList, activeTab} = useSelector(state => state.requestAwbReducer)
    const {loading} = useSelector(state => state.toolsReducer)

    const columns = [
        {
            type: 'checkbox',
        }, {
            name: 'Produk',
            classNameHeader: 'pl-3',
            colSpan: 2,
            render: (record) => (
                <div
                    className={`font-NunitoSans-Regular space-y-2 border-r border-gray-100 px-3 ${activeTab === 'error' ? 'mt-5' : 'my-5'}`}>
                    <div className={"row items-center space-x-2"}>
                        <div className={"w-10 row justify-center"}>
                            <img src={UserSvg} className={"w-7"}/>
                        </div>
                        <p className={"text-green-grey font-NunitoSans-SemiBold w-20"}>{record?.order_code}</p>
                        <p className={"text-dark-grey font-NunitoSans-Bold w-24 truncate capitalize"}>{record.customer_name}</p>
                    </div>
                    <div className={"row items-center space-x-2 flex-1"}>
                        <img src={record.image_url || null} className={"w-10 h-10 object-contain"}/>
                        <div
                            className={"flex-1 row items-center space-x-3 justify-between font-NunitoSans-SemiBold text-dark-grey"}>
                            <p className={"capitalize xl:w-28 2xl:w-40"}>{record.product_name}</p>
                            <p className={"font-NunitoSans-Bold"}>x{record.order_quantity}</p>
                        </div>
                    </div>
                    <p className={"text-green-grey ml-12"}>{record.product_count > 1 ? `dan ${record.product_count - 1} produk lainnya` : ''} </p>
                </div>
            )
        }, {
            name: 'Jumlah',
            classNameHeader: 'pl-3',
            render: (record) =>
                <div className={"pl-3 space-y-1"}>
                    <p className={"font-NunitoSans-Bold text-green-grey"}>{doConvertNumberToRupiahFormat(record.grand_total)}</p>
                    <p className={"text-gray-400"}>{record.payment_service}</p>
                </div>
        }, {
            name: 'Ekspedisi',
            classNameHeader: 'pl-3 xl:w-60 2xl:w-80',
            render: (record) =>
                <div className={"row items-center xl:space-x-2 2xl:space-x-5 px-3 xl:w-60 2xl:w-80"}>
                    <div className={"w-20 col items-center"}>
                        <img src={getExpeditionLogo(record.shipping_service)} alt={record.shipping_service} className={"w-16"}/>
                    </div>
                    <div className={"text-brownish-grey flex-1"}>
                        <p>{record.shipping_origin_destination}</p>
                        <p>{record.shipping_service}</p>
                        <p className={"text-greyish"}>{doConvertNumberToRupiahFormat(record.shipping_price)}</p>
                    </div>
                </div>
        }, {
            name: 'Resi',
            dataIndex: 'awb',
            classNameHeader: 'text-center',
            render: (awb) =>
                <div className={"col items-center space-y-2"}>
                    <p className={"text-center text-brownish-grey"}>{awb || 'Belum Tersedia'}</p>
                    {!!awb && <CopyToClipboardComponent text={awb} message={"Resi berhasil di copy"}/>}
                </div>
        }, {
            name: 'Perlu Dikirim',
            dataIndex: 'expired_process_order',
            className: 'text-center',
            classNameHeader: 'pl-5',
            render: (expired_process_order, record) => (
                <div className={"space-y-1 px-5"}>
                    {
                        isAfterDateTimeNow(expired_process_order) ?
                            <CountdownComponent expired={expired_process_order}/> :
                            <p className={"font-NunitoSans-Bold text-brownish-red"}>{statusOrderCountDown(PROCESSING, record).orderInformation}</p>
                    }
                    <div className={"text-dark-grey"}>
                        <p>{statusOrderCountDown(PROCESSING, record).orderMessage}</p>
                        <p className={"font-NunitoSans-Bold"}>{statusOrderCountDown(PROCESSING, record).processingExpiredTime}</p>
                    </div>
                </div>
            )
        }, {
            name: '',
            dataIndex: 'created_at',
            render: (created_at) => (
                <div className={"h-full"}>
                    <div className={"row items-center space-x-2 justify-end"}>
                        <img src={ClockSvg} className={"w-5"}/>
                        <p className={"text-brownish-grey"}>{convertDateToCustomFormat(created_at, 'DD / MM / YYYY HH:mm')}</p>
                    </div>
                </div>
            )
        }
    ]

    const onChangeSelectedRowKeys = (rowKeys, rows) => {
        dispatch(setRequestAwbSelectedRowKeys(rowKeys))
        dispatch(setRequestAwbSelectedRows(rows))
    }

    return (
        <div>
            <TotalDataComponent/>
            <TableComponent
                columns={columns}
                tableClassName={"font-NunitoSans-Regular"}
                headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
                selectedRowKeys={selectedRowKeys}
                selectedRows={selectedRows}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={orderList}
                pagination={true}
                loading={loading}
            />
            <div className={"w-full row justify-center"}>
                <TotalDataComponent type={"show-background"}/>
            </div>
        </div>
    )
}

export default OrderListComponent
