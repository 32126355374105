import React, {useEffect, useState} from "react";
import {convertDateToCustomFormat, formatDateStartOfUnix, formatDateToReadable} from "../../ui-utils/general-variable";
import CalendarDateSinglePickerComponent from "../../components/calendar-date-single-picker.component";
import {getHolidayNoteByDateApi} from "../../api/setting.api";
import {useDispatch, useSelector} from "react-redux";
import {setSettingDatePicked, setSettingModalDetail} from "../../reducers/setting.reducer";

const DetailDateComponent = () => {
    const dispatch = useDispatch()
    const {datePicked} = useSelector(state => state.settingReducer)
    const [note, setNote] = useState(null)

    useEffect(() => {
        getNoteByDate()
    }, [])

    const openModal = () => {
        dispatch(setSettingModalDetail({visibility: true, type: 'add', holidayDetail: {}}))
    }

    const getValueFromCalendarDateBirth = (value) => {
        dispatch(setSettingDatePicked(formatDateStartOfUnix(value)))
        getNoteByDate(value)
    }

    const getNoteByDate = (value) => {
        let params = {
            date: convertDateToCustomFormat(value, 'YYYY-MM-DD')
        }
        getHolidayNoteByDateApi(params)
            .then(result => {
                setNote(result)
            })
            .catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
            })
    }

    return (
        <>
            <div className={"col space-y-4"}>
                <CalendarDateSinglePickerComponent slug={"settings"} onChange={getValueFromCalendarDateBirth}
                                                   listDateNote={[1607446800, 1607965200]}/>
                <div className={"col space-y-6 border border-white-four font-NunitoSans-Bold p-6 rounded-md"}>
                    <div className={"row justify-between items-center"}>
                        <p className={"text-grey-green text-27"}>{formatDateToReadable(datePicked, "D MMM YYYY")}</p>
                        {
                            !note &&
                            <button
                                className={"px-2.5 pb-0.5 bg-grey-green border border-green-grey hover:bg-green-grey rounded-full"}
                                onClick={openModal}>
                                <p className={"text-white text-2xl font-bold"}>+</p>
                            </button>
                        }
                    </div>
                    <div className={"col space-y-4"}>
                        <p className={"text-2xl"}>Notes:</p>
                        <p className={"font-NunitoSans-Regular text-base"}>{note ?? 'Belum ada catatan di tanggal ini'}</p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DetailDateComponent
