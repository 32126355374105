import moment from "moment";
import prevIcon from "../images/svg/chevron-left.svg";
import nextIcon from "../images/svg/chevron-right.svg";
import {useEffect, useState} from "react";
import FilterCalendarComponent from "./filter-calendar.component";
import {convertDateToCustomFormat} from "../ui-utils/general-variable";

const CalendarDateSinglePickerComponent = (props) => {
    moment.updateLocale('id', {
        week: {
            dow: 1, // Monday is the first day of the week.
        }
    })
    const {currentDate, onChange, listDateNote = [], slug} = props
    const [listDate, setListDate] = useState([])
    const [singleDate, setSingleDate] = useState(moment())
    const startDay = moment(singleDate).startOf("month").startOf("week")
    const endDay = moment(singleDate).endOf("month").endOf("week")
    const listDay = moment.weekdaysShort(true)
    const listMonth = moment.months()
    const startYear = slug === 'settings' ? 2014 : 1970

    useEffect(() => {
        setSingleDate(moment(currentDate))
    }, [currentDate])
    const listYear = () => {
        const getYears = []
        const endYear = moment().year()

        for (let i = endYear; i >= startYear; i--) {
            getYears.push(i)
        }

        return getYears
    }

    const isToday = (date) => {
        return date.isSame(moment(), "day")
    }

    const isNotValidDate = (date) => {
        const dayAfterToday = date.isAfter(moment(), slug === 'settings' ? "year" : "day")
        const dayBeforeStartYear = date.isBefore(moment([startYear]).startOf('year'))
        return dayAfterToday || dayBeforeStartYear
    }

    const isCurrentDate = (date) => {
        return singleDate.isSame(date, "day")
    }

    const selectedDateWithNote = (date) => {
        const isWeekend = moment(date).day() === 0 || moment(date).day() === 6
        if (isCurrentDate(date)) {
            return 'bg-white'
        } else if (listDateNote.includes(date.unix())) {
            return 'text-red-600'
        } else if (isWeekend) {
            return 'bg-red-100'
        }
    }

    const weekDayStyle = (day) => {
        if (day === 'Sab' || day === 'Min') {
            return 'bg-red-100'
        }
    }

    const existToPick = (date) => {
        if (isNotValidDate(date)) {
            return 'text-greyish cursor-not-allowed'
        } else {
            const isInMonthDate = singleDate.isSame(date, "month")
            return !isInMonthDate && 'text-greyish'
        }
    }

    const currentMonth = () => {
        return singleDate.format("MMMM")
    }

    const currentYear = () => {
        return singleDate.format("YYYY")
    }

    const prevMonth = () => {
        return singleDate.clone().subtract(1, "month")
    }

    const nextMonth = () => {
        return singleDate.clone().add(1, "month")
    }

    const onChangeMonth = (value) => {
        const pickMonth = singleDate.clone().month(value)
        setDate(pickMonth)
    }

    const onChangeYear = (value) => {
        const pickYear = singleDate.clone().year(Number(value))
        setDate(pickYear)
    }

    const setDate = (date) => {
        if (isNotValidDate(date)) {
            alert(`Can't pick this date`)
        } else {
            setSingleDate(date)
            onChange(date)
        }
    }

    useEffect(() => {
        const day = startDay.clone().subtract(1, "day")
        const initialListDate = []

        while (day.isBefore(endDay, "day")) {
            initialListDate.push(day.add(1, "day").clone())
        }

        setListDate(initialListDate)
    }, [singleDate])

    return (
        <div
            className={"grid grid-cols-7 space-y-4 font-NunitoSans-Regular text-brownish-grey text-lg p-6 border border-greyish rounded-md"}>
            <div className={"col-span-full mb-3"}>
                <div className={"row items-center justify-between font-NunitoSans-SemiBold"}>
                    <div className={"grow text-center cursor-pointer"} onClick={() => setDate(prevMonth())}>
                        <img src={prevIcon} alt="prevIcon"
                             className={"w-5 h-8 inline bg-beige-three border border-green-sage rounded-md"}/>
                    </div>
                    <div className={"row space-x-4"}>
                        <FilterCalendarComponent dataList={listMonth} value={currentMonth()} onChange={onChangeMonth}/>
                        <FilterCalendarComponent dataList={listYear()} value={currentYear()} onChange={onChangeYear}/>
                    </div>
                    <div className={"grow text-center cursor-pointer"} onClick={() => setDate(nextMonth())}>
                        <img src={nextIcon} alt="nextIcon"
                             className={"w-5 h-8 inline bg-beige-three border border-green-sage rounded-md"}/>
                    </div>
                </div>
            </div>
            {listDay.map((day, index) => (
                <div key={index} className={slug === 'settings' ? weekDayStyle(day) : ''}>
                    <p className={"font-NunitoSans-Bold text-center"}>{day}</p>
                </div>
            ))}
            {listDate.map((date, index) => (
                <button type={"button"} key={index} onClick={() => setDate(date)}
                        className={`row justify-center items-center ${slug === 'settings' ? selectedDateWithNote(date) : ''}`}>
                    <p className={`${isToday(date) ? 'font-extrabold' : ''} ${existToPick(date)} ${isCurrentDate(date) && 'bg-dark-green text-white'} 
                    ${date.format("D").length === 2 ? 'py-3 px-4' : 'py-[11px] px-5'} rounded-full hover:bg-zinc-200`}>{moment(date).format("D")}</p>
                </button>
            ))}
        </div>
    )
}

export default CalendarDateSinglePickerComponent
