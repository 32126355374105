import {createSlice} from '@reduxjs/toolkit'

export const orderSlice = createSlice({
    name: 'order',
    initialState: {
        selectedRowKeys: [],
        activeTab: 'all',
        orderList: [],
        orderDetail: {},
        orderDetailLogs: [],
        previewPrintLabel: []
    },
    reducers: {
        setOrderSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload
        },
        setOrderActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setOrderDetail: (state, action) => {
            state.orderDetail = action.payload
        },
        setOrderDetailLogs: (state, action) => {
            state.orderDetailLogs = action.payload
        },
        setOrderPreviewPrintLabel: (state, action) => {
            state.previewPrintLabel = action.payload
        },
        setOrderToolsInitialState: (state, action) => {
            state.selectedRowKeys = []
        }
    },
})

export const {
    setOrderSelectedRowKeys,
    setOrderList,
    setOrderDetail,
    setOrderDetailLogs,
    setOrderActiveTab,
    setOrderPreviewPrintLabel,
    setOrderToolsInitialState
} = orderSlice.actions
const orderReducer = orderSlice.reducer

export default orderReducer
