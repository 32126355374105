import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setRequestAwbActiveTab,
    setRequestAwbSelectedRowKeys,
    setRequestAwbSelectedRows
} from "../../reducers/request-awb.reducer";
import {setToolsCurrentPage, setToolsOrderBy, setToolsSearchKey, setToolsSort} from "../../reducers/tools.reducer";

const TabComponent = () => {
    const dispatch = useDispatch()
    const {activeTab} = useSelector(state => state.requestAwbReducer)
    const tabList = [
        {
            label: 'Request AWB',
            key: 'request-awb'
        }, {
            label: 'Print Label',
            key: 'print-label'
        }, {
            label: 'Error',
            key: 'error'
        }
    ]

    const onChangeTab = (key) => {
        dispatch(setRequestAwbActiveTab(key))
        dispatch(setToolsCurrentPage(1))
        dispatch(setToolsSearchKey(''))
        dispatch(setToolsSort('desc'))
        dispatch(setToolsOrderBy('id'))
        dispatch(setRequestAwbSelectedRowKeys([]))
        dispatch(setRequestAwbSelectedRows([]))
    }

    return (
        <div className={"w-full"}>
            <div
                className={"row items-center space-x-10 text-dark-grey font-NunitoSans-Regular px-7 border-b border-light-grey-four"}>
                {
                    tabList.map((item, index) => (
                        <div key={index} onClick={() => onChangeTab(item.key)}
                             className={`pb-4 ${activeTab === item.key && 'border-b-2  border-b-green-grey'} cursor-pointer`}>
                            <p className={`${activeTab === item.key && 'text-green-grey font-NunitoSans-Bold'}`}>{item.label}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TabComponent