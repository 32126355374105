import {exportRequest, getRequest, postRequest} from "./config";

const getOrderListApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`order/list`, params)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const changeOrderStateApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/update/${id}`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const changeBulkOrderStateApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/bulk-update`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const getOrderDetailApi = (orderId) => {
    return new Promise((resolve, reject) => {
        getRequest(`order/detail/${orderId}`)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const getOrderDetailLogApi = (orderId) => {
    return new Promise((resolve, reject) => {
        getRequest(`order/detail-log/${orderId}`)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const generateAwbApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/generate-awb`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const moveToPrintLabelListApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/print-label/move`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const moveToPrintManifestApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/scan`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response))
    })
}

const moveToDeliveryApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/print-manifest`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const importResiApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/import-awb`, requestBody, 'form')
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const printLabelBulkApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/print-label`, requestBody)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response?.data))
    })
}

const previewPrintLabelListApi = (body) => {
    return new Promise((resolve, reject) => {
        postRequest(`order/print-label/preview`, body)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

const exportOrderApi = (body) => {
    return new Promise((resolve, reject) => {
        exportRequest('order/export',body)
            .then(response => resolve(response))
            .catch(err => reject(err.response?.data))
    })
}

export {
    getOrderListApi,
    getOrderDetailApi,
    getOrderDetailLogApi,
    changeOrderStateApi,
    generateAwbApi,
    moveToPrintLabelListApi,
    moveToDeliveryApi,
    importResiApi,
    moveToPrintManifestApi,
    previewPrintLabelListApi,
    changeBulkOrderStateApi,
    printLabelBulkApi,
    exportOrderApi
}
