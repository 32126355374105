import {exportRequest, getRequest, postRequest} from "./config";

const getServicePerformanceListApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`service-performance`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const getIncompleteOrderListApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`service-performance/incomplete-orders/list`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const getDelayedDeliveryListApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`service-performance/delayed-delivery/list`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const getIncompleteStatisticApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`service-performance/incomplete-orders/statistic`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const getPackingTimeStatisticApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`service-performance/packing-time/statistic`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

const getDelayedDeliveryStatisticApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`service-performance/delayed-delivery/statistic`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const exportUnCompleteOrderApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        exportRequest(`service-performance/incomplete-orders/export`, requestBody)
            .then(response => resolve(response))
            .catch(err => reject(err.response?.data))
    })
}

const exportDeliveryDelayApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        exportRequest(`service-performance/delayed-delivery/export`, requestBody)
            .then(response => resolve(response))
            .catch(err => reject(err.response?.data))
    })
}

const exportPackingTimeApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        exportRequest(`service-performance/packing-time/export`, requestBody)
            .then(response => resolve(response))
            .catch(err => reject(err.response?.data))
    })
}

const settingTargetApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        postRequest(`service-performance/set-target`, requestBody)
            .then(response => resolve(response))
            .catch(err => reject(err.response?.data))
    })
}

const getTargetApi = () => {
    return new Promise((resolve, reject) => {
        getRequest(`service-performance/target`)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

export {
    getServicePerformanceListApi,
    getIncompleteOrderListApi,
    getIncompleteStatisticApi,
    getPackingTimeStatisticApi,
    getDelayedDeliveryListApi,
    getDelayedDeliveryStatisticApi,
    exportUnCompleteOrderApi,
    exportDeliveryDelayApi,
    exportPackingTimeApi,
    settingTargetApi,
    getTargetApi
}
