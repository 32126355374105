import React, {Fragment, useEffect, useState} from "react";
import {
    convertDateToCustomFormat,
    convertUnixToMoment,
    formatDateEndOfUnix,
    formatDateStartOfUnix,
    formatDateToReadable
} from "../ui-utils/general-variable";
import CalendarSvg from "../images/svg/Group 438.svg";
import CalendarWhiteSvg from "../images/svg/Group 428.svg";
import ArrowLeft from "../images/svg/Group 196.svg";
import moment from "moment/moment";
import ArrowRight from "../images/svg/Group 195.svg";
import {useSelector} from "react-redux";
import {Menu, Transition} from "@headlessui/react";

const idLocale = require('moment/locale/id');

const DateRangePickerComponent = ({slug, onChange}) => {
    // X is month before
    // Y is this month

    const {dateRange} = useSelector(state => state.toolsReducer)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const dateMoment = moment()
    const [y, setY] = useState(dateMoment)
    const [x, setX] = useState(moment().subtract(1, 'months'))
    const [startDateSelected, setStartDateSelected] = useState(null)
    const [endDateSelected, setEndDateSelected] = useState(null)
    const [dateArrayX, setDateArrayX] = useState([])
    const [dateArrayY, setDateArrayY] = useState([])
    const [dateBeforeArrayX, setDateBeforeArrayX] = useState([])
    const [dateBeforeArrayY, setDateBeforeArrayY] = useState([])
    const [dateAfterArrayX, setDateAfterArrayX] = useState([])
    const [dateAfterArrayY, setDateAfterArrayY] = useState([])

    useEffect(() => {
        if (!!dateRange[0]) {
            setStartDateSelected(dateRange[0])
            setEndDateSelected(dateRange[1])
        }
    }, [dateRange])

    // const activeButtonHandler = () => {
    //     setShowDatePicker(!showDatePicker)
    // }

    useEffect(() => {
        constructDateArrayX()
        constructDateArrayY()
    }, [x, y])

    const constructDateArrayX = () => {
        moment.locale('id', idLocale);
        let arrayDateX = []
        let monthBeforeArrayX = []
        let monthAfterArrayX = []
        let yearX = moment(x).get('year')
        let monthX = moment(x).get('month')
        let dateEndX = 40 - (new Date(yearX, monthX, 40).getDate())
        let dateStartX = new Date(yearX, monthX).getDay()

        //generate array for before this month
        let dateBeforeEnd = 40 - (new Date(constructYearNew(yearX, monthX - 1), constructMonth(monthX - 1), 40).getDate())
        for (let i = dateBeforeEnd - (dateStartX - 1); i <= dateBeforeEnd; i++) {
            let c = moment(String(`${constructYearNew(yearX, monthX - 1)}-${constructMonth(monthX)}-${i}`)).startOf('days')
            monthBeforeArrayX.push(c)
        }

        //generate array for this month
        for (let i = 1; i <= dateEndX; i++) {
            let c = moment(String(`${yearX}-${monthX + 1}-${i}`))
            arrayDateX.push(c)
        }

        //generate array for after this month
        let totalDate = monthBeforeArrayX.length + arrayDateX.length
        let dateLeft = 42 - totalDate
        for (let i = 1; i <= dateLeft; i++) {
            let c = moment(String(`${constructYear(yearX, monthX + 1, 'plus')}-${constructMonth(monthX + 2)}-${i}`))
            monthAfterArrayX.push(c)
        }

        setDateBeforeArrayX(monthBeforeArrayX)
        setDateArrayX(arrayDateX)
        setDateAfterArrayX(monthAfterArrayX)
    }

    const constructDateArrayY = () => {
        let arrayDateY = []
        let monthBeforeArrayY = []
        let monthAfterArrayY = []
        let yearY = moment(y).get('year')
        let monthY = moment(y).get('month')
        let dateEndY = 40 - (new Date(yearY, monthY, 40).getDate())
        let dateStartY = new Date(yearY, monthY).getDay()

        //generate array for before this month
        let dateBeforeEnd = 40 - (new Date(constructYearNew(yearY, monthY - 1), constructMonth(monthY - 1), 40).getDate())
        for (let i = dateBeforeEnd - (dateStartY - 1); i <= dateBeforeEnd; i++) {
            let c = moment(String(`${constructYearNew(yearY, monthY - 1)}-${constructMonth(monthY)}-${i}`)).startOf('days')
            monthBeforeArrayY.push(c)
        }

        //generate array for this month
        for (let i = 1; i <= dateEndY; i++) {
            let c = moment(String(`${yearY}-${monthY + 1}-${i}`))
            arrayDateY.push(c)
        }

        //generate array for after this month
        let totalDate = monthBeforeArrayY.length + arrayDateY.length
        let dateLeft = 42 - totalDate
        for (let i = 1; i <= dateLeft; i++) {
            let c = moment(String(`${constructYear(yearY, monthY + 1, 'plus')}-${constructMonth(monthY + 2)}-${i}`))
            monthAfterArrayY.push(c)
        }

        setDateArrayY(arrayDateY)
        setDateBeforeArrayY(monthBeforeArrayY)
        setDateAfterArrayY(monthAfterArrayY)
    }

    const constructMonth = (m) => {
        if (m === 13) {
            return 1
        } else if (m === 0) {
            return 12
        } else {
            return m
        }
    }

    const constructYearNew = (y, m) => {
        if (m === 13) {
            return y + 1
        } else if (m === -1) {
            return y - 1
        } else {
            return y
        }
    }

    const constructYear = (y, m, type) => {
        if (type === 'minus') {
            if (m === 13) {
                return y - 1
            } else {
                return y
            }
        } else {
            if (m === 12) {
                return y + 1
            } else {
                return y
            }
        }
    }

    const clearButtonHandler = () => {
        const a = moment()
        const b = moment(a).subtract(1, 'months')
        onChange([null, null])
        setStartDateSelected(null)
        setEndDateSelected(null)
        setX(b)
        setY(a)
        setShowDatePicker(false)
    }

    const decreaseIncreaseMonth = (slug) => {
        let todayX = null;
        let todayY = null;
        if (slug === 'before') {
            todayX = moment(x).subtract(1, 'months')
            todayY = moment(y).subtract(1, 'months')
        } else {
            todayX = moment(x).add(1, 'months')
            todayY = moment(y).add(1, 'months')
        }

        setY(todayY)
        setX(todayX)
    }

    const dateButtonHandler = (item, slug) => {
        if (!!startDateSelected && !endDateSelected) {
            setEndDateSelected(formatDateEndOfUnix(item))
            onChange([startDateSelected, formatDateEndOfUnix(item)])
            setShowDatePicker(false)
        } else if (!!startDateSelected && !!endDateSelected) {
            setStartDateSelected(formatDateStartOfUnix(item))
            // decreaseIncreaseMonth(slug)
            setEndDateSelected(null)
        } else {
            setStartDateSelected(formatDateStartOfUnix(item))
            // decreaseIncreaseMonth(slug)
        }
    }

    const isDisabled = (item) => {
        if (moment(item).isAfter()) {
            return true
        } else if ((!!startDateSelected && !endDateSelected) && moment(item).isBefore(convertUnixToMoment(startDateSelected))) {
            return true
        } else {
            return false
        }
    }

    const renderButtonItem = (item, index, slug) => {
        if (slug === 'main') {
            return (
                <button key={index} onClick={() => dateButtonHandler(item, slug)}
                        disabled={isDisabled(item)}
                        className={`row justify-center items-center h-8 disabled:text-gray-400 disabled:bg-gray-100 disabled:rounded-none disabled:cursor-not-allowed hover:text-white hover:bg-dark-sage-two hover:rounded-full ${moment(item).isSame(dateMoment, 'day') && 'border border-dark-sage-two rounded-full'} ${(moment(item).isSame(convertUnixToMoment(endDateSelected), 'day') || moment(item).isSame(convertUnixToMoment(startDateSelected), 'day')) && 'bg-dark-sage-two text-white rounded-full'} ${moment(item).isBetween(convertUnixToMoment(startDateSelected), convertUnixToMoment(endDateSelected), 'day') && 'bg-light-green'}`}>
                    <p className={"text-xs font-Poppins-Medium"}>{moment(item).date()}</p>
                </button>
            )
        } else {
            return (
                <button key={index}
                        disabled={isDisabled(item)}
                        onClick={() => dateButtonHandler(item, slug)}
                        className={`row justify-center items-center h-8 disabled:text-gray-400 disabled:bg-gray-100 disabled:rounded-none disabled:cursor-not-allowed hover:text-white hover:bg-dark-sage-two hover:rounded-full text-gray-300`}>
                    <p className={"text-xs font-Poppins-Medium"}>{moment(item).date()}</p>
                </button>
            )
        }
    }

    const isSetButtonStyle = () => {
        switch (slug) {
            case 'service-performance':
            case 'uncomplete-order':
            case 'delivery-delay':
            case 'packing-time':
            case 'product-performance':
                return true
            default:
                return false
        }
    }

    return (
        <Menu as="div" className="relative inline-block h-8">
            <Menu.Button
                className={`row items-center rounded ${isSetButtonStyle() ? 'bg-green-grey text-white space-x-3 pr-3 font-NunitoSans-Bold' :
                    'border px-1 border-pinkish-grey shadow-md whitespace-nowrap overflow-hidden'}`}>
                <img src={isSetButtonStyle() ? CalendarWhiteSvg : CalendarSvg} className={`${isSetButtonStyle() ? 'w-10' : 'w-8'}`}/>
                <div className={`row ${isSetButtonStyle() ? 'text-white' : 'text-xs text-warm-grey'}`}>{
                    !!dateRange[0] ?
                        <p>{formatDateToReadable(dateRange[0], 'DD MMM YY')} - {formatDateToReadable(dateRange[1], 'DD MMM YY')}</p> :
                        <p>Tanggal Mulai - Tanggal Akhir</p>
                }
                </div>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="row justify-center absolute z-10 mt-3 w-[500px]">
                    <div className={"bg-white rounded-b px-5 py-2 space-y-3 shadow-xl"}>
                        <div className={"row space-x-10"}>
                            <div>
                                <div className={"row items-center justify-between"}>
                                    <img src={ArrowLeft} className={"cursor-pointer"}
                                         onClick={() => decreaseIncreaseMonth('before')}/>
                                    <p className={"font-NunitoSans-Bold text-green-grey text-sm"}>{convertDateToCustomFormat(x, 'MMMM YYYY')}</p>
                                    <div/>
                                </div>
                                <div className={"grid grid-cols-7"}>
                                    {
                                        ['M', 'S', 'S', 'R', 'K', 'J', 'S'].map((item, index) => (
                                            <div key={index} className={"row justify-center items-center h-8 w-8"}>
                                                <p className={"text-sm font-NunitoSans-Bold text-brownish-grey"}>{item}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={"grid grid-cols-7 gap-y-1"}>
                                    {dateBeforeArrayX.map((item, index) => renderButtonItem(item, index, 'before'))}
                                    {dateArrayX.map((item, index) => renderButtonItem(item, index, 'main'))}
                                    {dateAfterArrayX.map((item, index) => renderButtonItem(item, index, 'after'))}
                                </div>
                            </div>
                            <div>
                                <div className={"row items-center justify-between"}>
                                    <div/>
                                    <p className={"font-NunitoSans-Bold text-green-grey text-sm"}>{convertDateToCustomFormat(y, 'MMMM YYYY')}</p>
                                    {
                                        !moment(y).isSame(moment(), 'month') ?
                                            <img src={ArrowRight} className={"cursor-pointer"}
                                                 onClick={() => decreaseIncreaseMonth('after')}/> :
                                            <div/>
                                    }
                                </div>
                                <div className={"grid grid-cols-7"}>
                                    {
                                        ['M', 'S', 'S', 'R', 'K', 'J', 'S'].map((item, index) => (
                                            <div key={index} className={"row justify-center items-center h-8 w-8"}>
                                                <p className={"text-sm font-NunitoSans-Bold text-brownish-grey"}>{item}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={"grid grid-cols-7 gap-y-1 text-brownish-grey"}>
                                    {dateBeforeArrayY.map((item, index) => renderButtonItem(item, index, 'before'))}
                                    {dateArrayY.map((item, index) => renderButtonItem(item, index, 'main'))}
                                    {dateAfterArrayY.map((item, index) => renderButtonItem(item, index, 'after'))}
                                </div>
                            </div>
                        </div>
                        <div className={"row space-x-4 items-center justify-end mt-3"} onClick={clearButtonHandler}>
                            <p className={"text-xs"}>
                                {`${formatDateToReadable(!!dateRange[0] ? dateRange[0] : moment().unix(), 'DD/MM/YYYY')} 
                                - ${formatDateToReadable(!!dateRange[0] ? dateRange[1] : moment().unix(), 'DD/MM/YYYY')}`}
                            </p>
                            <button>Clear</button>
                        </div>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default DateRangePickerComponent
