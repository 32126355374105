import {exportRequest, getRequest} from "./config";

const getProductListApi = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`product-performance/list`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const getCategoryList = () => {
    return new Promise((resolve, reject) => {
        getRequest(`product-performance/category-list`)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const getStatisticList = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`product-performance/statistic`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}

const getChart = (params) => {
    return new Promise((resolve, reject) => {
        getRequest(`product-performance/chart`, params)
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response?.data))
    })
}


const exportProductPerformanceApi = (body) => {
    return new Promise((resolve, reject) => {
        exportRequest('product-performance/list/export',body)
            .then(response => resolve(response))
            .catch(err => reject(err.response?.data))
    })
}

export {
    getProductListApi,
    getCategoryList,
    getStatisticList,
    getChart,
    exportProductPerformanceApi
}
