import React from "react";
import TableComponent from "../../components/table.component";
import {useDispatch, useSelector} from "react-redux";
import {setManifestSelectedRowKeys, setManifestSelectedRows} from "../../reducers/manifest.reducer";
import UserSvg from "../../images/svg/Group 413.svg";
import TotalDataComponent from "../../components/total-data.component";
import LoadingSvg from "../../images/svg/loading-trans.svg";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    getExpeditionLogo
} from "../../ui-utils/general-variable";

const OrderListComponent = () => {
    const dispatch = useDispatch()
    const {selectedRowKeys, orderList, selectedRows} = useSelector(state => state.manifestReducer)
    const {loading} = useSelector(state => state.toolsReducer)
    const columns = [
        {
            type: 'checkbox',
        }, {
            name: 'Kode Pesanan',
            dataIndex: 'order_code',
            className: 'text-green-grey font-NunitoSans-SemiBold',
            render: (order_code) => <p>{order_code}</p>
        }, {
            name: 'Tanggal Pesanan',
            dataIndex: 'created_at',
            className: 'text-brownish-grey',
            render: (created_at) => <p>{convertDateToCustomFormat(created_at, 'DD MMMM YYYY')}</p>
        }, {
            name: 'Pelanggan',
            dataIndex: 'customer_name',
            render: (customer_name) =>
                <div className={"row items-center space-x-5"}>
                    <img src={UserSvg} className={"w-7"}/>
                    <p className={"text-brownish-grey"}>{customer_name}</p>
                </div>
        }, {
            name: 'Ekspedisi',
            render: (record) =>
                <div className={"row items-center space-x-2"}>
                    <img src={getExpeditionLogo(record.shipping_service)} className={"w-10"}/>
                    <p className={"text-brownish-grey w-32"}>{record.shipping_origin_destination}</p>
                </div>
        }, {
            name: 'Tipe Ekspedisi',
            render: (record) =>
                <div>
                    <p className={"text-brownish-grey"}>{record.shipping_service}</p>
                    <p className={"text-greyish"}>({doConvertNumberToRupiahFormat(record.shipping_price)})</p>
                </div>
        }, {
            name: 'Kode Ekspedisi',
            dataIndex: 'awb',
            render: (awb) => <p className={"text-brownish-grey"}>{awb || 'Belum Tersedia'}</p>
        }
    ]

    const onChangeSelectedRowKeys = (rowKeys, rows) => {
        dispatch(setManifestSelectedRowKeys(rowKeys))
        dispatch(setManifestSelectedRows(rows))
    }

    return (
        <>
            {loading ?
                <div className={"row justify-center mt-5 h-52"}>
                    <img src={LoadingSvg} className={"w-12"}/>
                </div> :
                <div>
                    <TotalDataComponent/>
                    <TableComponent
                        columns={columns}
                        tableClassName={"font-NunitoSans-Regular"}
                        headerClassName={"bg-light-grey-five font-NunitoSans-Bold bg-light-grey-five text-left text-dark-sage-two h-8"}
                        selectedRowKeys={selectedRowKeys}
                        selectedRows={selectedRows}
                        onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                        dataSource={orderList}
                        pagination={true}
                    />
                    <div className={"w-full row justify-center"}>
                        <TotalDataComponent type={"show-background"}/>
                    </div>
                </div>
            }
        </>
    )
}

export default OrderListComponent
