import React, {useEffect} from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import ChartComponent from "../../components/chart.component";
import {useDispatch, useSelector} from "react-redux";
import {dateRangeToday, formatDateToReadable} from "../../ui-utils/general-variable";
import {getCustomerInfoStatisticApi} from "../../api/customer-info.api";
import {setCustomerBuyerTypeDataChart} from "../../reducers/customer-info.reducer";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";

const BuyerTypeChartComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {buyerTypeDataChart} = useSelector(state => state.customerInfoReducer)
    const buyerType = [
        {
            label: 'Pembeli Baru',
            key: 'new_user',
            value: 10,
            dotClassName: 'bg-light-sage-three border-sage',
            textColor: 'text-moss-green'
        }, {
            label: 'Pembeli Reguler',
            key: 'regular_user',
            value: 10,
            dotClassName: 'bg-very-light-pink border-pinkish-tan',
            textColor: 'text-clay-two'
        }, {
            label: 'Pembeli Setia',
            key: 'loyal_user',
            value: 10,
            dotClassName: 'bg-pale border-camel',
            textColor: 'text-dull-orange'
        }
    ]

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            let params = {
                scope: 'buyer-type',
                startDate: formatDateToReadable(dateRange[0], 'YYYY-MM-DD'),
                endDate: formatDateToReadable(dateRange[1], 'YYYY-MM-DD')
            }
            getCustomerInfoStatisticApi(params)
                .then(result => {
                    dispatch(setCustomerBuyerTypeDataChart(result))
                })
                .catch(err => {
                    if (err?.code === '503-0000') {
                        localStorage.clear()
                        window.location.href = '/under-maintenance'
                    }
                    console.log(err)
                })
        }
    }, [dateRange])

    return (
        <div className={"border rounded-md p-2"}>
            <div className={"row justify-end"}>
                <ToolTipComponent/>
            </div>
            <p className={"font-Belleza-Regular text-center text-brownish-grey text-base"}>{buyerTypeDataChart.title}</p>
            <p className={"text-dark-sage-six text-center font-Belleza-Regular"}>{buyerTypeDataChart.detail.total_user} pembeli</p>
            <div className={"row justify-center w-full h-72"}>
                <ChartComponent type={'pie'} data={buyerTypeDataChart.data} width={200} color={['#5c9031', '#b95656', '#d8ae40']}/>
            </div>
            <div className={"col space-y-2"}>
                {
                    buyerType.map((item, index) => (
                        <div className={`row items-center justify-between ${item.textColor}`} key={index}>
                            <div className={"row items-center space-x-2"}>
                                <div className={`h-2 w-2 rounded-full ${item.dotClassName} border`}/>
                                <p>{item.label}</p>
                            </div>
                            <p>({buyerTypeDataChart.detail[item.key]} User)</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default BuyerTypeChartComponent
