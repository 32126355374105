import React, {useEffect} from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import ArrowUp from "../../images/svg/ico-arrow-up.svg"
import ArrowDown from "../../images/svg/ico-arrow-down.svg"
import {useDispatch, useSelector} from "react-redux";
// <<<<<<< HEAD
// import {setProductPerformanceActiveCard} from "../../reducers/product-performance.reducer";
// import {doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";
//
// const ProductPerformanceStatisticComponent = () => {
//     const dispatch = useDispatch()
//     const {statisticList, activeCard} = useSelector(state => state.productPerformanceReducer)
//
//     const getDetailCard = (title) => {
//         let scope = ''
//         let textToolTip = ''
//
//         switch (title) {
//             case 'Order':
//                 scope = 'order'
//                 textToolTip = 'Data total Order diambil dari pesanan yang sudah dibayarkan oleh customer pada periode tersebut. Persentase kenaikan pesanan di ambil dari jumlah pesanan pada periode tersebut dibagi jumlah pesanan pada periode sebelumnya dikali 100%'
//                 break
//             case 'Penjualan Produk':
//                 scope = 'product'
//                 textToolTip = 'Data total penjualan produk (Rp.). diambil dari jumlah keseluruhan produk yang terjual (Rp.) pada periode tersebut. Persentase penjualan pesanan di ambil dari total penjualan pada periode tersebut dibagi jumlah penjualan pada periode sebelumnya dikali 100%'
//                 break
//             case 'Produk Terjual':
//                 scope = 'sold'
//                 textToolTip = 'Data total produk terjual diambil dari jumlah keseluruhan produk yang terjual pada periode tersebut. Persentase kenaik produk terjual di ambil dari jumlah produk terjual pada periode tersebut dibagi jumlah produk terjual pada periode sebelumnya dikali 100%'
//                 break
//         }
//
//         return {
//             scope,
//             textToolTip
//         }
//     }
//
//     const setActiveCard = (cardScope) => {
//         dispatch(setProductPerformanceActiveCard(cardScope))
//     }
// =======
import {
    setProductPerformanceActiveCard,
    setProductPerformanceStatisticList
} from "../../reducers/product-performance.reducer";
import {
    dateRangeToday,
    doConvertNumberToRupiahFormat,
    formatDateToReadable
} from "../../ui-utils/general-variable";
import {getStatisticList} from "../../api/product-performance.api";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";

const ProductPerformanceStatisticComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {statisticList, activeCard} = useSelector(state => state.productPerformanceReducer)

    const getScopeCard = (title) => {
        switch (title){
            case 'Order':
                return 'order'
            case 'Penjualan Produk':
                return 'product'
            case 'Produk Terjual':
                return 'sold'
        }
    }

    const setActiveCard = (cardItem) => {
        dispatch(setProductPerformanceActiveCard(cardItem))
    }

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            const params = {
                startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
                endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
            }
            getStatisticList(params).then(response => {
                dispatch(setProductPerformanceStatisticList(response))
            }).catch(err => {
                if (err?.code === '503-0000') {
                    localStorage.clear()
                    window.location.href = '/under-maintenance'
                }
                console.log(err)
            })
        }
    }, [dateRange])
// >>>>>>> staging-api-v2

    return (
        <div className={"grid grid-cols-3 gap-5"}>
            {
                statisticList.map((item, index) => (
// <<<<<<< HEAD
//                     <div key={index}
//                         className={`border p-5 space-y-2 rounded cursor-pointer hover:border-green-grey hover:bg-gradient-to-b from-light-white to-eggshell
//                         ${activeCard === getDetailCard(item.label).scope && 'border-green-grey bg-gradient-to-b from-light-white to-eggshell border-b-4 border-dark-sage-two'}`}
//                         onClick={() => setActiveCard(getDetailCard(item.label).scope)}>
//                         <div className={"row items-center justify-between text-brownish-grey"}>
//                             <p className={"text-xl font-Belleza-Regular"}>{item.label}</p>
//                             <ToolTipComponent right={true} text={getDetailCard(item.label).textToolTip}/>
//                         </div>
//                         <div
//                             className={"row items-center justify-between text-3xl font-Belleza-Regular text-green-grey"}>
//                             <p>{getDetailCard(item.label).scope === 'product' ? doConvertNumberToRupiahFormat(item.totalNow) : item.totalNow}</p>
// =======
                    <div key={index} className={`border p-5 space-y-2 rounded cursor-pointer hover:border-green-grey hover:bg-gradient-to-b from-light-white to-eggshell 
                    ${activeCard === getScopeCard(item.title) && 'border-green-grey bg-gradient-to-b from-light-white to-eggshell border-b-4 border-dark-sage-two'}`}
                    onClick={() => setActiveCard(getScopeCard(item.title))}>
                        <div className={"row items-center justify-between text-brownish-grey"}>
                            <p className={"text-xl font-Belleza-Regular"}>{item.title}</p>
                            <ToolTipComponent/>
                        </div>
                        <div
                            className={"row items-center justify-between text-3xl font-Belleza-Regular text-green-grey"}>
                            <p>{getScopeCard(item.title) === 'product' ? doConvertNumberToRupiahFormat(item.current_count) : item.current_count}</p>
{/*>>>>>>> staging-api-v2*/}
                            <div className={"row items-center space-x-2"}>
                                <img src={item.current_count < item.past_count ? ArrowDown : ArrowUp}/>
                                <p>{item.trend_percentage}%</p>
                            </div>
                        </div>
                        <div className={"row items-center space-x-3 text-sm"}>
                            <p className={"text-brownish-grey"}>Periode sebelumnya</p>
{/*<<<<<<< HEAD*/}
{/*                            <p className={"text-mid-blue"}>{getDetailCard(item.label).scope === 'product' ? doConvertNumberToRupiahFormat(item.totalBefore) : item.totalBefore}</p>*/}
{/*=======*/}
                            <p className={"text-mid-blue"}>{getScopeCard(item.title) === 'product' ? doConvertNumberToRupiahFormat(item.past_count) : item.past_count}</p>
{/*>>>>>>> staging-api-v2*/}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ProductPerformanceStatisticComponent
