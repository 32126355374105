import React, {useEffect} from "react";
import ToolTipComponent from "../../components/tool-tip.component";
import ChartComponent from "../../components/chart.component";
import {useDispatch, useSelector} from "react-redux";
import {dateRangeToday, formatDateToReadable, isUnauthorized} from "../../ui-utils/general-variable";
import {getChart} from "../../api/product-performance.api";
import {setProductPerformanceChart} from "../../reducers/product-performance.reducer";
import {setRefreshAlert, setToolsStartDateEndDate} from "../../reducers/tools.reducer";

const ProductPerformanceChartComponent = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {chart, activeCard} = useSelector(state => state.productPerformanceReducer)

    useEffect(() => {
        if (!dateRange[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        } else {
            const params = {
                scope: activeCard,
                startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
                endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
            }
            getChart(params).then(response => {
                dispatch(setProductPerformanceChart(response))
            }).catch(err => {
                if (!!isUnauthorized(err?.code)) {
                    dispatch(setRefreshAlert(true))
                }
            })
        }
    }, [dateRange, activeCard])

    return (
        <div className={"border rounded-md p-5"}>
            <div className={"row items-center justify-between mb-5"}>
                <div/>
                <p className={"font-Belleza-Regular text-lg"}>{chart.title}</p>
                <ToolTipComponent/>
            </div>
            <div className={"w-full h-96"}>
                <ChartComponent type={'line'} data={chart.data} tooltip={"Performa Produk"} width={1000}/>
            </div>
        </div>
    )
}

export default ProductPerformanceChartComponent
